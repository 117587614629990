@import "../../../default.scss";
.catalog-filter {
  .catalog-filter {
    &__wrap {
      width: 250px;
      max-width: 250px;
      background: #fff;
    }
    &__header {
      display: none;
      width: 250px;
      min-height: 52px;
      background-color: $clr_white;
      padding: 10px;
      position: relative;
      border-bottom: 1px solid #e9e9e9;
      .filter_m_toggle {
        padding: 0;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: $clr_primary;
        i {
          font-size: 15px;
          margin-right: 2px;
          margin-left: -7px;
          display: block;
          transform: rotate(90deg)
        }
      }
      p {
        margin: 0;
      }
    }
    &__footer {
      display: none;
      background-color: $clr_white;
      width: 250px;
      //min-height: 95px;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 10px 10px 15px;
      border-top: 1px solid #e9e9e9;
      p {
        font-size: 12px;
        margin-bottom: 5px;
      }
      .filter-set {
        flex-grow: 1;
        max-width: 70%;
        min-width: 70%;
        margin: auto;
      }
      &.top-button {
        padding-top: 15px;
        border-bottom: 1px solid #e9e9e9;
        @media screen and (max-width : 991px) {
          display: none !important;
        }
      }
      .filter-selection__btn {
        font-size: 13px;
        padding: 5px 15px;
        margin: 0 .5rem;
        border: 1px solid transparentize($clr_red, .3);
        border-radius: 100px;
        color: $clr_black;
        background: transparent;
        transition: $transition;
        display: none;
        &:hover {
          background: transparentize($clr_red, 0.5);
        }
      }
    }
    &__overlay {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparentize($clr_blue, .4);
      transition: 0.3s;
    }
  }
  @media screen and (max-width : 991px) {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1005;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: .4s;
    .catalog-filter {
      &__wrap {
        transform: translateX(-100%);
        transition: 0.4s;
        height: 100vh;
        width: 270px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
      &__promo {
        &.scrolled {
          @media screen and (max-width: 990px) {
            overflow-y: auto;
            height: inherit;
          }
        }
      }
      &__inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;
        overflow-y: scroll;
        order: 1;
        padding-bottom: 110px;
        box-sizing: border-box;
      }
      &__footer {
        flex-direction: row;
        border-top: none;
        border-bottom: 1px solid #e9e9e9;
        padding: 10px;
        .filter-selection__btn {
          display: block;
          width: 80px;
          padding: 5px 12px;
          margin-right: 15px;
          margin-left: 0;
        }
        p {
          display: none;
        }
        .filter-set {
          min-width: auto;
        }
      }
      &__header {
        display: flex;
        .filter-selection__label {
          font-size: 12px;
          margin-bottom: 0;
        }
      }
    }
    &.show {
      opacity: 1;
      visibility: visible;
      .catalog-filter__wrap {
        transform: translateX(0);
        position: fixed;
        top: 0;
        left: 0;
      }
      .catalog-filter__overlay {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .filter {
    &-block {
      padding: 0 8px;
      border-right: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      animation: .7s both FadeInBlock;
      &.collapsed {
        .filter-block__inner {
          max-height: 0;
          padding-bottom: 0px;
        }
        .filter-block__title i {
          transform: rotate(180deg);
        }
      }
      &__title {
        padding: 10px 0;
        display: flex;
        align-items: center;
        color: $clr_black;
        text-decoration: none;
        font-weight: 500;
        width: 100%;
        outline: none;
        box-shadow: none;
        h4 {
          font-size: 1rem;
          margin-bottom: 0;
        }
        &:hover {
          color: $clr_primary;
        }
        i {
          font-size: 12px;
          color: $clr_black;
          margin-right: 7px;
          display: inline-block;
          font-size: 12px;
          padding-bottom: 0px;
          transition: .5s;
          &:before {
            margin: 0;
            transition: $transition;
            transform: rotate(180deg);
          }
        }
      }
      &__inner {
        padding-left: 20px;
        padding-bottom: 10px;
        transition: .5s;
        overflow: hidden;
        max-height: 999px;
        .checkbox-filter {
          height: 100%;
          overflow-y: auto;
          max-height: 340px;
          &__item {
            position: relative;
          }
        }
      }
      &.open {
        .filter-block__title {
          i:before {
            transform: rotate(0deg);
          }
        }
      }
      &:first-child {
        border-top: 1px solid #e9e9e9;
      }
    }
  }
}
input[type="checkbox"] {
  opacity: 0;
  transform: translateX(-5000px);
  position: absolute;
  top: 0;
  &+label {
    position: relative;
    cursor: pointer;
    padding: 4px 0;
    box-sizing: content-box;
    margin: 0;
    transition: all 200ms ease;
    display: flex;
    align-items: center;
    font-size: 13px;
    // height: 20px; 
    align-items: flex-start;
    line-height: 1;
    margin: 6px 0;
    &:before {
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      min-width: 16px;
      height: 16px;
      border-radius: 2px;
      border: 1px solid transparentize($clr_blue, .8);
      font-family: "fontello";
      padding-top: 1px;
      margin-bottom: 1px;
      text-indent: 1px;
      color: $clr_white;
      font-size: 10px;
      transition: all .2s ease;
      margin-right: 10px;
    }
  }
  &:not(:disabled):not(:checked)+label {
    &:hover {
      //color: $clr_primary;      
      &:before {
        border-color: transparentize($clr_blue, .4);
      }
    }
  }
  &:focus+label:before {}
  &:disabled+label {
    color: $clr_gray;
    cursor: default;
    &:hover {
      color: $clr_gray;
    }
    &:before {
      border-color: lighten($clr_gray, 25%);
      background: lighten($clr_gray, 30%);
    }
  }
  &:checked+label {
    &::before {
      background: $clr_primary;
      border-color: $clr_primary;
      content: "\e804";
    }
  }
}
input[type="radio"] {
  opacity: 0;
  transform: translateX(-1000px);
  position: absolute;
  &+label {
    position: relative;
    cursor: pointer;
    padding: 4px 0;
    box-sizing: content-box;
    margin: 0;
    transition: all 200ms ease;
    display: flex;
    align-items: center;
    font-size: 13px;
    height: 20px;
    &:before {
      content: "";
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 16px;
      border: 1px solid transparentize($clr_blue, .8);
      font-family: "fontello";
      padding-top: 1px;
      margin-bottom: 1px;
      text-indent: 1px;
      color: $clr_white;
      font-size: 10px;
      transition: all .2s ease;
      margin-right: 10px;
    }
  }
  &:not(:disabled):not(:checked)+label {
    &:hover {
      //color: $clr_primary;      
      &:before {
        border-color: transparentize($clr_blue, .4);
      }
    }
  }
  &:focus+label:before {}
  &:disabled+label {
    color: $clr_gray;
    cursor: default;
    &:hover {
      color: $clr_gray;
    }
    &:before {
      border-color: lighten($clr_gray, 25%);
      background: lighten($clr_gray, 30%);
    }
  }
  &:checked+label {
    &::before {
      border-width: 4px;
      border-color: $clr_primary;
    }
  }
}
input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
select,
textarea {
  &:hover {
    border-color: transparentize($clr_blueLighten, .5);
  }
  &:focus {
    border-color: $clr_blueLighten;
  }
}
select {
  margin-right: 10px;
  min-width: 80px;
  &.month {
    min-width: 100px;
  }
  &:focus {
    border-color: $clr_blueLighten !important;
  }
}
label.req:after {
  content: '*';
  color: $clr_red;
  margin: 3px;
}
.price-range {
  padding-right: 5px;
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      font-size: 14px;
    }
    label {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.5);
      margin: 0 3px;
    }
    input {
      width: 55px;
      padding: 4px 3px;
      box-sizing: border-box;
      font-size: 13px;
      border-radius: 4px;
      border: 1px solid transparentize($clr_blue, .7);
      text-align: center;
    }
    input[type=number],
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      background: none;
    }
    .i-rub:after {
      content: '\e80e';
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      z-index: -1 !important;
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
    }
    button.btn {
      padding: 3px 10px;
      border: 1px solid $clr_primary;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
.rc-slider {
  box-sizing: border-box;
  width: 94%;
  margin: 1.5rem auto 1rem;
  &-rail {
    height: 3px;
  }
  &-track {
    height: 3px;
    background: transparentize($clr_primary, .3);
  }
  &-handle {
    border: solid 2px $clr_primary;
    background: $clr_primary;
    &:hover,
    &:focus {
      border-color: $clr_primary;
    }
    &:active {
      border-color: $clr_primary;
      box-shadow: 0 0 5px $clr_primary;
      cursor: grabbing;
    }
    &-dragging {
      &.rc-slider-handle-dragging.rc-slider-handle-dragging {
        border-color: $clr_primary;
        box-shadow: 0 0 0 5px transparentize($clr_primary, 0.5);
      }
    }
  }
}
