@import "../../../default.scss";

.products-dropdown{
    display: flex;
    flex-direction: column;
    &__wrap{
        display: flex;
        flex-wrap: wrap;
    }
    &__item{
        width: 16.6667%;
        @media screen and (max-width : 1199px){
            width: 20%;
        }
        @media screen and (max-width : 899px){
            width: 25%;
        }
        @media screen and (max-width : 709px){
            width: 33.333%;
        }
        @media screen and (max-width : 550px){
            width: 50%;
        }
    }
    &__btn{
        text-align: center;
        border: 1px solid hsla(0,0%,60%,.3);
        // border-top: 0;
        // height: 50px;
        // height: 41px;
        &>div{  
            width: 100%;          
            background: rgba($clr_blue, 0.1);
            border-radius: 0px 0px 8px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            text-align: center;
            background: #eee;
            padding: 15px;
            box-sizing: border-box;
            // width: 200px;
            // height: 50px;
            // height: 41px;
            font-weight: 500;
            transition: .3s;
            font-size: 14px;
            // margin-top: 10px;
            border-radius: 8px;
            border-radius: 4px;
            cursor: pointer;
            // border: 1px solid transparent;
            // border-color: $clr_primary;
            color: $clr_primary;
            background: transparent;
            i{
                margin-left: 8px;
                margin-right: 0;
                font-size: 13px;
                display: inline-block;
            }
            &.open{
                display: none;
                i{
                    transform: rotate(180deg) translateY(1px);
                }
            }
            &:hover{
                border-color: $clr_blueLighten;
                color: $clr_blueLighten;
                background: transparentize($clr_blueLighten, 0.98);
            }

        }
        &.to-category{
            width: 100%;
            height: auto;
            // margin: 10px 0 0;
            text-decoration: none;
        }
    }
}