@import "../../../default.scss";

.page-contacts-cards {
    .contacts__card {
        margin: 15px 0;
        border: 2px solid #6d769e1c;

        .carousel-control-next,
        .carousel-control-prev {
            opacity: 0.7;
            transition: 250ms ease;

            &:hover {
                opacity: 1;
                background: #ffffff21;
            }
        }

        .link-name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 16px;
            transition: 250ms ease;

            border-bottom: 1px solid #6d769e1c;

            &:hover {
                text-decoration: none;
                background: #6d769e1c;
            }

            .name {
                font-size: 1.25rem;
                font-weight: 500;
                color: #212529;
            }

            .more {

                display: flex;
                align-items: center;

                // color: #1b34c2;

                .arrow {
                    display: block;
                    width: 0;
                    height: 0;
                    border: 0 solid transparent;
                    border-bottom-width: 5px;
                    border-top-width: 5px;
                    border-left: 5px solid;

                    margin-left: 4px;
                    transform: translateY(1px);

                }
            }
        }


        &-content {
            padding: 20px 16px 0 16px;

            .address {
                font-weight: 700;
                margin-bottom: 20px;

            }

            .contact__worktime {
                .worktime-header {
                    display: none;
                }

                .worktime-content {
                    .left-side p {
                        margin-bottom: 0;
                    }

                    p {
                        white-space: nowrap;
                        font-weight: 500;
                    }
                }
            }

            .card_info {
                padding: 0;
                margin-bottom: 20px;

                li {
                    list-style: none;
                    margin-bottom: 8px;

                    a {
                        font-size: 1.15rem;
                        color: #212529;
                        text-decoration: none;
                        transition: 250ms ease;

                        svg {
                            width: 1em;
                            height: 1em;
                            margin-right: 0.2em;
                            margin-left: 0.2em;
                            transition: 250ms ease;
                        }

                        &::before {
                            color: $clr_orange;
                            transition: 250ms ease;
                        }

                        &:hover {

                            &::before,
                            svg {
                                transform: perspective(10px) translateZ(1px);
                            }

                            color: $clr_orange;
                        }
                    }
                }
            }
        }

        &-slider {
            width: 100%;

            img {
                width: 100%;
                // height: 300px;
            }
        }

    }
}

.page-contact {
    .contact {

        @media screen and (max-width : 767px) {
            .nav-tabs {
                .nav-link {
                    padding: 10px 6px 2px;
                    font-size: 14px;
                }
            }
        }

        address {

            color: $clr_orange;
            font-weight: 500;
        }

        &__wrap {}

        &__title {
            h1 {
                color: $clr_blue;
                font-size: 24px;
            }

            p {
                color: $clr_orange;
                font-weight: 500;
            }
        }

        &__main {
            display: flex;
            padding: 2rem 0;

            .row {
                width: 100%;

                @media screen and (max-width : 767px) {
                    padding: 0;
                    margin: 0;

                    [class^='col-'] {
                        padding: 0;
                    }
                }
            }
        }

        &__worktime {
            p {
                margin: 0;
            }

            .worktime {
                &-header {
                    background: $clr_blue;
                    color: $clr_white;
                    text-align: center;
                    padding: 1.5rem 1rem;
                    padding-right: 1.5rem;
                    text-transform: uppercase;

                    i {
                        margin-right: .7rem;
                    }

                    h3 {
                        font-size: 21px;
                        margin: 0;
                    }
                }

                &-content {
                    color: $clr_white;
                    font-size: 16px;
                    font-weight: 500;

                    .weekdays {
                        display: flex;

                        .left-side {
                            background: darken($clr_blueLighten, 8%);
                            width: 40%;
                            padding: .5rem 0;
                            text-align: center;
                        }

                        .right-side {
                            background: $clr_blueLighten;
                            flex-grow: 1;
                            padding: .5rem 0;
                            text-align: center;
                        }
                    }

                    .weekends {
                        display: flex;

                        .left-side {
                            background: darken($clr_orange, 8%);
                            width: 40%;
                            padding: .5rem 0;
                            text-align: center;
                        }

                        .right-side {
                            background: $clr_orange;
                            flex-grow: 1;
                            padding: .5rem 0;
                            text-align: center;
                        }
                    }
                }
            }
        }

        &-place {
            padding-top: 1rem;

            &__item {
                display: flex;
                border-bottom: 1px solid transparentize($clr_blue, .85);
                padding: 10px 10px 10px 0;
                align-items: center;
                font-size: 14px;
                font-weight: 500;

                i {
                    padding: 0 1rem;
                    font-size: 35px;
                }

                svg {
                    // padding: 0 1rem;
                    margin: 0 1rem;
                    width: calc(35px + 1rem);
                    height: calc(35px + 1rem);
                }

                p {
                    margin-bottom: 0.2em;
                    font-weight: 600;
                }

                span {
                    line-height: 10px;
                }
            }
        }

        &__numbers {
            padding: 1rem 0;


            .numbers {
                h5 {
                    color: $clr_blueLighten;

                    i:before {
                        color: $clr_blueLighten;
                        font-size: 20px;
                        margin-right: 10px;
                        margin-left: 0;
                    }

                    svg {
                        margin-right: 10px;
                        width: 20px;
                        height: 20px;
                    }
                }

                ul {
                    list-style: none;
                    padding: 0;
                }

                &__link {
                    text-decoration: none;
                    color: $clr_black;
                    font-weight: 500;
                    padding: 5px 0;
                    display: block;

                    svg {
                        width: 16px;
                        height: 16px;
                        margin-left: 0.2em;
                        margin-right: 10px;
                    }

                    .label {
                        opacity: .6;
                        margin-right: 5px;
                    }

                    &:before {
                        color: $clr_orange;
                        font-size: 16px;
                        margin-right: 10px;
                    }

                    &:hover {
                        color: $clr_orange;
                    }
                }
            }
        }

        &__gallery {

            margin-top: 50px;

            &-slider {

                // width: 100%;
                img {
                    // height: 400px;

                    width: 100%;
                }
            }

            h4 {
                font-size: 21px;
                margin-bottom: 1rem;
            }

            .react-photo-gallery--gallery {
                &>div>img {
                    width: 130px;
                    height: 130px;

                    @media screen and (max-width : 1350px) {
                        width: calc(12.5% - 10px);
                        height: 130px;
                    }

                    @media screen and (max-width : 1200px) {
                        width: calc(16.666% - 10px);
                        height: 130px;
                    }

                    @media screen and (max-width : 768px) {
                        width: calc(20% - 10px);
                        height: 100px;
                    }

                    @media screen and (max-width : 500px) {
                        width: calc(33.3333% - 10px);
                        height: 100px;
                    }

                    object-fit: cover;
                    margin: 5px !important;
                    transition: .3s;

                    &:hover {
                        box-shadow: 0 3px 10px rgba($clr_blue, .5);
                    }
                }
            }
        }
    }
}

@media screen and (max-width : 992px) {
    .page-contact .contact {
        margin-top: 30px;
    }
}