.block-slider {
  margin: 1rem 0;
}

.block-slider .slick-slider {
  margin-right: -8px;
}


.glide__arrows {
  display: flex;
  align-items: center;
  width: 100%;
}

.glide__arrows .products-slider__title {
  padding: 0 !important;
  margin: 0 !important;
  flex-grow: 1;
}

.glide__arrow {
  border: 1px solid rgba(153, 153, 153, 0.3);
  background-color: #fff;
  padding: 0.25rem 0.67rem;
  color: #212529;
}

.glide__arrow--disabled {
  pointer-events: none !important;
  color: #21252965;
}

.block {
  border: 1px solid rgba(153, 153, 153, 0.3);
  position: relative;
  height: 400px;
  display: grid;
}

.block>* {
  border: 1px solid rgba(153, 153, 153, 0.3);
}

.block.type-1 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.block.type-1>*:nth-child(1) {
  grid-column: 1 / 3;
}

.block.type-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.block.type-2>*:nth-child(1) {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.block.type-2>*:nth-child(2) {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.block.type-2>*:nth-child(3) {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}

.block.type-3 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.block.type-3>*:nth-child(3) {
  grid-column: 1 / 3;
}

.block.type-4 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.block.type-4>*:nth-child(2) {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.block.type-4>*:nth-child(3) {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.block.type-4>*:nth-child(1) {
  grid-row: 1 / 3;
  grid-column: 1 / 2;
}

.block.type-5 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

.block.type-5>*:nth-child(1) {
  grid-row: 1 / 5;
  grid-column: 1 / 2;
}

.block.type-5>*:nth-child(2) {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}

.block.type-5>*:nth-child(3) {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
}

.block.type-5>*:nth-child(4) {
  grid-row: 4 / 5;
  grid-column: 2 / 3;
}

.block.type-6 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

.block.type-6>*:nth-child(1) {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.block.type-6>*:nth-child(2) {
  grid-row: 2 / 3;
  grid-column: 1 / 2;
}

.block.type-6>*:nth-child(3) {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}

.block.type-6>*:nth-child(4) {
  grid-row: 3 / 5;
  grid-column: 1 / 3;
}

.block.type-7 {
  z-index: 1000;
  overflow: hidden;
}

.small-block {
  position: relative;
  color: #212529 !important;
  text-decoration: none;
}

.small-block img.small-block__bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
  z-index: 1;
}

.small-block img.small-block__gif {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
  z-index: 2;
}

.small-block__overlay {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: flex-start;
}

.small-block:hover h3 {
  text-decoration: none;
}

.small-block h3 {
  transition: 200ms ease color;
}

.small-block:hover h3,
.video-block-name:hover h3 {
  color: #F26522;
}

.video-block-name {
  position: absolute !important;
  top: 0;
  left: 0;
  color: inherit;
  text-decoration: none;
  font-size: 12px !important;
}

.video-block-name h3 {
  font-size: 12px !important;
  margin: 0;
}

.video-block-name:hover {
  text-decoration: none;
  color: inherit;
}

.small-block__category,
.video-block-name {
  padding: 0.333rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 12px;
  position: relative;
  z-index: 2;
  margin: 0;
}

.small-block__category.with-bg,
.video-block-name.with-bg {
  background: rgba(255, 255, 255, 0.67);
  backdrop-filter: blur(10px);
}

.small-block__badge {
  height: 22px;
  width: 100%;
  position: relative;
  padding: 0 0.333rem;
}

.small-block__badge img {
  height: 100%;
  object-fit: contain !important;
  object-position: top left;
}

.small-block__animation {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.small-block__animation.fg {
  z-index: 10;
}

.block7-animation {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}


/* это не срабатывает, нужно сделать чтоб срабатывало  */
.block7-animation .error {
  display: none !important;
}

.block7-animation dotlottie-player {
  width: 100%;
  height: 100%;
}

.block7-animation.fg {
  z-index: 10;
}

.block7-container {
  display: flex;
  flex-direction: column;
  height: 400px;
  border: none !important;
  color: #212529;
}

.block7-flex {
  margin-top: auto;
  display: flex;
  /* gap: 12px; */
  justify-content: space-between;
  align-items: flex-end;
  /* padding-left: 12px;
  padding-right: 12px; */
}

.block7-flex>div {
  width: 50%;
}

.block7-content {
  display: flex;
  flex-grow: 1;
  /* display: grid; */
  /* height: 100%; */
  /* grid-template-columns: repeat(2, 1fr); */
  /* grid-template-columns: 4fr;
  grid-template-rows: 4fr 4fr; */
}

.block7-content .left {
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px;
  height: 100%;
}

.block7-content .left .badge {
  background-color: #ff524d;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px 10px;
  letter-spacing: 1px;
}

.block7-content .left img.product-img {
  width: 100%;
  max-height: 250px;
  object-fit: cover;
  flex-grow: 1;
}

.block7-flex h6,
.block7-content .left h6 {
  font-size: 10px;
  width: 100%;
  text-align: center;
  margin-bottom: 4px;
}

.block7-flex .timer-container {
  padding: 12px;
  padding-right: 16px;
}

.block7-content .timer,
.block7-flex .timer {
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: center;
}

.block7-flex .timer-block.colon {
  display: none;
}

.block7-content .timer-block,
.block7-flex .timer-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(153, 153, 153, 0.3);
  padding: 4px;
  width: 40px;
  border-radius: 2px;
  flex-grow: .8;
}

.block7-flex .timer-num,
.block7-content .left .timer-num {
  font-size: 16px;
}

.block7-flex .timer-text,
.block7-content .left .timer-text {
  font-size: 10px;
}

.block7-content .right {
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 10px 10px 10px 0;
}

.block7-flex h3,
.block7-content .right h3 {
  font-size: 20px;
}

.type-7 a:hover {
  text-decoration: none;
}

.type-7 a:hover .right h3 {
  color: #F26522;
}

.block7-content .motive-text,
.small-block-8 .motive-text {
  color: #ff524d;
  font-size: 14px;
  font-weight: 500;
}

.small-block-8 .motive-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.block7-content .right .attrs {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.block7-content .right .attrs-item {
  display: flex;
  gap: 6px;
  align-items: baseline;
}

.block7-content .right .attrs-item>div:nth-child(1) {
  /* width: 50%; */
}

.block7-content .right .attrs-item>div:nth-child(2) {
  font-size: 11px;
}

.block7-flex .price-container {
  padding-right: 16px;
  padding-bottom: 12px;
}

/*  */

.price-container .price__old {
  height: 22px;
  margin-top: -2px;
  position: relative;
  display: inline-block;
}

.price-container .price__old .price {
  display: none;
  color: #999 !important;
  font-size: 16px !important;
  position: relative;
  z-index: -1;
}

.price-container .price__old .price:before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: -3px;
  transform: translateY(-10%);
  background: #b30000;
  z-index: 1;
}

.block7-content .right .price,
.block7-flex .price {
  font-size: 24px;
  margin-top: auto;
  text-align: left;
  width: 100%;
  color: #bb2360;
  font-weight: 700;
  margin-bottom: 0.85rem;
}


/* .block7-flex .products-price {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;




}

.block7-flex .products-price.product-sale .products-price__old .price {
  display: inline;
  z-index: 1;
}

.block7-flex .products-price__old {
  height: 22px;
  margin-top: -2px;
  position: relative;
  display: inline-block;  
}

.block7-flex .products-price__old .price {
  display: none;
  color: #999;
  font-size: 14px;
  position: relative;
  z-index: -1;    
}

.block7-flex .products-price__old .price:after {
  font-size: 13px;
}

.block7-flex .products-price__old .price:before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: -3px;
  transform: translateY(-10%);
  background: #b30000;
  z-index: 1;
}

.block7-flex .products-price .price {
  font-size: 24px;
  line-height: 18px;

}

.block7-flex .products-price .price:after {
  font-size: 17px;
  transform: translateY(-1px);
  margin-left: .1em;
  margin-right: .1em;
} */

/*  */
/* .block7-flex .price {
  display: flex;
  flex-direction: column;
}

.block7-flex .price__old {
}
 

.block7-flex .price__old:before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: -3px;
  transform: translateY(-10%);
  background: #b30000;
  z-index: 1;
}

*/

.block7-content .right>.price span::after,
.block7-flex .price {
  margin-left: 0;
}

/* ????? */

.block7-content .left .btn,
.block7-flex .btn {
  width: 100%;
  background-color: #bb2360;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  position: relative;
  z-index: 100;
}

.block7-flex .btn {
  height: 50px;
  line-height: 2;
}

.block .video {
  width: 100%;
  height: 100%;
  max-height: 400px;
  /* height: 350px; */
  background-color: #000;
}

.block .video video {
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 400px;
  object-fit: contain;
}

.grid-8 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.grid-8 .carousel {
  grid-row: 1 / 3;
  height: 100% !important;
  padding-bottom: 36px !important;
}

.grid-8 .carousel-inner {
  height: 100%;
}

.grid-8 .carousel-inner .small-block {
  height: 100%;
}

.type-8 a {
  text-decoration: none;
}

.small-block-8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 10px 10px 10px;
}

.small-block-8 .small-block-8-container {
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.small-block-8 h3 {
  font-size: 14px;
  margin-bottom: 0;
  max-height: 2.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-block-8 img {
  max-height: 200px;
}

.small-block-8 .price {
  font-size: 22px;
  color: #bb2360;
  font-weight: 700;
}

.small-block-8 .price span::after {
  margin-left: 0;
}

.small-block-8 .products-price__old .price {
  z-index: 1;
  color: #999;
  font-size: 14px;
  position: relative;
}

.small-block-8 .products-price__old .price:after {
  font-size: 13px;
}

.small-block-8 .products-price__old .price:before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: -3px;
  transform: translateY(-10%);
  background: #b30000;
  z-index: 1;
}

.small-block-8 .btn {
  width: 100%;
  background-color: #bb2360;
  color: #fff;
  position: relative;
  z-index: 100;
}

.small-block-8 .small-block__overlay {
  position: absolute !important;
  top: 0;
}

@media (min-width: 1400px) {
  .block7-flex .timer-container {
    width: 50%;
  }

  .block7-flex .price-container {
    width: 50%;
  }

  .block7-content .left {
    width: 50%;
  }

  .block7-content .right {
    width: 50%;
  }

}

@media (max-width: 1400px) {

  .block7-flex h3,
  .block7-content .right h3 {
    font-size: 18px;
  }
}


@media (max-width: 992px) {
  .block7-content .left img.product-img {
    max-height: 200px;
  }
}

@media (max-width: 576px) {
  .grid-8 .carousel-inner .small-block {
    justify-content: space-between;
  }

  .block,
  .block7-container {
    height: 350px;
  }

  .block7-container {
    justify-content: space-between;
  }

  .block img,
  .block #sliderMain .carousel-item img {
    max-height: 150px;
  }

  .block img {

    max-height: 100%;
  }


  .block7-content .left {
    left: 0;
    top: 0;
    width: 40%;
  }

  .block7-content .right {
    width: 60%;
  }

  .block7-flex {
    margin-top: 0;
    flex-direction: column;
  }

  .block7-flex .timer-container {
    width: 100%;
    padding: 12px 16px 4px 16px;
  }

  .block7-flex .price-container {
    width: 100%;
    padding: 0 16px 12px 16px;
  }

  .block7-flex .timer-num,
  .block7-content .left .timer-num {
    font-size: 14px;
    font-weight: 500;
  }

  .block7-flex .timer-text,
  .block7-content .left .timer-text {
    font-size: 10px;
  }


  .block7-content .right>.price,
  .block7-flex .price {
    margin-top: 0;
  }

}

.block-slider .slick-slide {
  padding: 0 8px 0 0;
}

.block-badges .products-labels {
  top: 25px;
  left: 5px;
}