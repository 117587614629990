.ta {
  &c {
    text-align: center;
  }
  &l {
    text-align: left;
  }
  &r {
    text-align: right;
  }
}
.tt {
  &u {
    text-transform: uppercase;
  }
  &c {
    text-transform: capitalize;
  }
  &l {
    text-transform: lowercase;
  }
  &i {
    text-transform: initial;
  }
}
.fw {
  &l, &300 {
    font-weight: 300;
  }
  &r, &400 {
    font-weight: 400;
  }
  &s, &500 {
    font-weight: 500;
  }
  &b, &700 {
    font-weight: 700;
  }
}
.nowrap,
.nw,
.amount,
.price,
.price * {
  white-space: nowrap;
}
.ell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
