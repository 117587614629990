@import '../../default';

.header-top-menu {

    position: relative;
    z-index: 102;
    // top: 0;
    // left: 0;
    // right: 0;
    // backdrop-filter: blur(40px);
    /* transition: 2000ms ease backdrop-filter; */
    // background: #ffffffab;
    // background: linear-gradient(160deg, #0a1a5c 10%, #0f247a 100%);


    display: flex;
    align-items: center;
    height: 40px;
    padding-top: 9px;

    &__icon {
        width: 20px;
        height: 20px;
        margin: 0 2px;
    }

    &__container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__contact {
        display: flex;
        align-items: center;
        gap: 2rem;
        color: #eaedf2;
        // padding-left: 312px;

        .contact-popup {
            position: relative;

            &:hover {
                .contact-popup__wrap {
                    opacity: 1;
                    z-index: 1;
                    visibility: visible;
                    transform: translateY(0);
                    transform: translateY(-5px);
                    transform: translateY(-15px);
                }
            }

            &__btn {
                display: flex;
                align-items: center;
                font-weight: 500;
                // color: #eaedf2;
                color: #C6CDE7;
                font-size: 16px;
                // font-weight: 700;
                cursor: pointer;

                &:hover {
                    svg.arrow-icon {
                        transform: rotate(180deg);
                    }
                }

                svg {
                    fill: #8892bb;
                    width: 10px;
                    height: 8px;
                    margin-left: 5px;
                    // fill: #f4f5fb;
                    transition: .2s;
                }
            }

            &__wrap {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                margin-top: 15px;
                top: 100%;
                left: 0;

                transition: .3s;
                padding-top: 10px;
            }

            &__inner {
                padding: 1rem;
                background: $clr_white;
                border-radius: 5px;
                white-space: nowrap;
                box-shadow: 5px 2px 15px transparentize($clr_blue, .8);

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 3px;
                    left: 26px;
                    width: 0;
                    height: 0;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-bottom: 8px solid white;
                }

                color: #000;

                ul {
                    margin: 0;
                    padding: 0;
                }


            }
        }

        .header-menu {
            &__contact {
                // color: #000;

                &>a {
                    padding-top: 0;
                    padding-bottom: 0;
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .callback-modal {
                    text-transform: uppercase;

                    &:hover {
                        text-decoration: underline;
                    }
                }

            }

        }

        .worktime {
            &__wrap {
                font-size: 14px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;

                svg {
                    width: 1.1em;
                    height: 1.1em;
                }

                i {
                    font-size: 1.1rem;
                    width: auto;
                    height: auto;
                }

                span {
                    margin: 0 5px;
                }
            }

            &-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 12px;

                .weekdays,
                .weekends {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    p {
                        margin-bottom: 0;
                    }
                }

                .weekdays {
                    margin-bottom: 8px;
                }
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
        gap: .75rem;

        .menu-item {
            position: relative;

            &:hover {
                .menu-item__wrap {
                    opacity: 1;
                    z-index: 1;
                    visibility: visible;
                    transform: translateY(0);
                    transform: translateY(-5px);
                    transform: translateY(-15px);
                }

                .menu-item__btn svg.arrow-icon {
                    transform: rotate(180deg);
                }
            }

            .stock-link {
                display: flex;
                align-items: center;
                padding: 4px;

                .menu-item__btn {

                    color: #ff3889;
                }


                .arrow-icon {
                    width: 10px;
                    height: 8px;
                    margin-left: 5px;
                    fill: #ff3889;
                    transition: .2s;
                }

                .header-top-menu__icon {
                    width: 16px !important;
                    height: 16px !important;

                    path,
                    svg {
                        fill: #ff3889;
                    }

                    margin-right: 5px;
                }
            }

            &__btn {
                display: flex;
                align-items: center;
                margin: 0 8px;

                font-weight: 500;
                color: #eaedf2;
                cursor: pointer;

                &:hover {}



                svg {
                    width: 10px;
                    height: 8px;
                    margin-left: 5px;
                    fill: #8892bb;
                    transition: .2s;
                }
            }

            &__wrap {
                opacity: 0;
                visibility: hidden;
                transform: translateY(5px);
                position: absolute;
                z-index: 100;
                top: 100%;
                right: 0;
                transition: .3s;
                padding-top: 1.4rem;
                min-width: 100%;
            }

            &__inner {
                padding: 1rem;
                background: $clr_white;
                border-radius: 5px;
                white-space: nowrap;
                box-shadow: 5px 2px 15px transparentize($clr_blue, .8);

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 15px;
                    right: 6px;
                    width: 0;
                    height: 0;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid white;
                }

                a {
                    padding: 5px 0;
                    color: #0f257c;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    transition: 200ms ease;

                    font {
                        transition: 200ms ease;
                    }

                    &:hover {
                        color: $clr_orange;

                        font {

                            transition: 200ms ease;
                            color: $clr_orange;
                        }
                    }

                    p {
                        margin: 0;
                    }

                    img.header-top-menu__icon {
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }
                }

            }
        }
    }
}

.header-top-menu-mobile {
    &__content {
        display: flex;
        flex-direction: column;

        .home-link {
            font-weight: 500;

            a {
                padding: 0.6rem 1.5rem;
                color: #0f257c;
                display: flex;
                align-items: center;
            }

            svg {
                path {
                    color: #0f257c;
                }

                display: block;
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }

        .menu-item {
            font-size: 14px;
            border-top: 1px solid #e9e9e9;
            padding: 0.4rem 1.5rem;
            color: #000;

            img.header-top-menu__icon:not(.stock-icon) {
                display: none;
            }

            .stock-link .header-top-menu__icon {
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 8px;
                margin-left: 0;
            }

            &__btn {
                display: flex;
                align-items: center;
                justify-content: space-between;

                font-weight: 500;
                font-size: 0.9em;
                padding: 12px 0;
                color: #959191;

                &:hover {}

                .stock-link {
                    display: flex;
                    align-items: center;
                    padding: 0;
                    color: #bf2362;
                    img.header-top-menu__icon {
                        transform: translateY(1px);
                    }
                }

                img.header-top-menu__icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                    margin-left: 0;
                }

                svg {
                    width: 10px;
                    height: 8px;
                    margin-left: 5px;
                    // fill: #f4f5fb;
                    transition: .2s;
                }
            }

            &__images {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                padding-top: 6px;

                &.big-images {
                    padding: 12px 0;

                    img {
                        height: 60px;
                        margin-right: 16px;
                        margin-bottom: 8px;
                    }
                }

                a {
                    text-decoration: none;
                }

                img {
                    height: 40px;
                    margin-right: 12px;
                    margin-bottom: 6px;
                }
            }

            &__inner {

                display: flex;
                flex-direction: column;

                a {
                    padding: 10px 0;
                    color: rgb(25, 25, 25);
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    transition: 200ms ease;

                    &:active {
                        color: $clr_orange;

                        font {
                            color: $clr_orange;
                        }
                    }

                    img.header-top-menu__icon {
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                        margin-left: 0;
                    }

                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .header-top-menu {
        &__container {
            justify-content: space-between;
        }

        &__contact {
            // display: none;
            padding-left: 0;
        }
    }
}

@media screen and (max-width: 992px) {
    .header-top-menu.desktop {
        display: none;
    }
}