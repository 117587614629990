@import '../../../default';

.page-checkout {
    h3 {
        margin: 30px 0;
    }

    .checkout {
        &-form {
            position: sticky;
            top: 30px;

            &__step {
                display: flex;
                margin-bottom: 15px;

                &:last-child {
                    .checkout-form__step__number:after {
                        //display: none;
                    }
                }

                &__number {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        min-width: 30px;
                        height: 30px;
                        min-height: 30px;
                        border-radius: 30px;
                        background-color: $clr_primary;
                        margin: 0 15px 15px 0;
                        font-weight: bold;
                        font-size: 17px;
                        line-height: 1px;
                        color: #ffffff;
                    }

                    &:after {
                        content: "";
                        display: block;
                        height: 100%;
                        margin: 0 15px 0 0;
                        border-right: 1px solid rgba(10, 26, 92, 0.1);
                    }
                }

                &__wrap {
                    width: 100%;

                    h5 {
                        margin: 4px 0 20px;
                    }

                    .form-check {
                        padding: 0;

                        label {
                            font-weight: 500;
                        }
                    }

                    input[type="radio"]+label {
                        font-size: 15px;
                    }

                    input,
                    select,
                    textarea {
                        font-size: 14px;

                        &:hover {
                            border-color: transparentize($clr_blueLighten, .5);
                        }

                        &:focus {
                            border-color: $clr_blueLighten;
                        }
                    }
                }
            }
        }

        &-login {
            display: flex;
            flex-wrap: wrap;

            //margin-left: -30px;
            .form-group {
                width: 55%;
                display: flex;
                align-items: center;  
                label { 
                    min-width: 85px;
                }
                &:nth-child(odd) {
                    width: 45%;

                    label {
                        margin: 0;
                    }
                }

                label {
                    margin: 0;
                    // min-width: 65px;
                    margin-bottom: 0;
                    white-space: nowrap;
                    font-weight: 500;

                    &:after {
                        content: '*';
                        color: $clr_red;
                        margin: 3px;
                    }

                    &.logEmail:after {
                        content: ''
                    }
                }

                input {
                    &:hover {
                        border-color: transparentize($clr_blueLighten, .5);
                    }

                    &:focus {
                        border-color: $clr_blueLighten;
                    }
                }
            }
        }

        &-delivery {
            &__item {
                &.open {
                    .checkout-delivery__dropdown {
                        padding: 10px 0;
                        max-height: 800px;
                        opacity: 1;
                    }
                }
            }

            &__dropdown {
                overflow: hidden;
                transition: $transition;
                padding: 0 0;
                max-height: 0;
                opacity: 0;

                &__wrap {}

                .row-city {
                    display: flex;
                    align-items: center;

                    .form-group {
                        margin: 0;
                    }

                    .select_info {
                        font-size: 14px;
                        margin: 0 20px;
                        color: rgba(0, 0, 0, 0.5);

                        p {
                            margin: 0;
                        }
                    }
                }

                .row-address {
                    display: flex;
                    margin: -7px -5px;
                    width: 100%;

                    input {
                        margin: 7px 5px;
                    }

                    .address {
                        &_city {
                            width: 128px;
                            min-width: 128px;
                        }

                        &_str {
                            flex-grow: 1;
                        }

                        &_dom {
                            width: 70px;
                            min-width: 70px;
                        }

                        &_kv {
                            width: 65px;
                            min-width: 65px;
                        }
                    }

                    .css-2b097c-container {
                        width: inherit;
                        height: 36px;
                        margin: 7px 5px;

                        .css-1pahdxg-control {
                            outline: none;
                            box-shadow: none;
                            height: 36px;
                            max-height: 36px;
                            overflow: hidden;
                        }

                        .css-1hb7zxy-IndicatorsContainer {
                            height: 34px;
                        }

                        input {
                            margin: 0;
                        }
                    }
                }
            }
        }

        &-payment {
            display: flex;
            flex-wrap: wrap;

            &__item {
                margin-right: 1.5rem;
            }
        }

        &-total {
            border-left: 1px solid rgba(10, 26, 92, 0.1);

            &__wrap {
                padding-left: 20px;
            }

            h5 {
                margin: 10px 0 0;
                padding-bottom: 15px;
                border-bottom: 1px solid rgba(10, 26, 92, 0.1);
            }

            &__item {
                display: flex;
                padding: 12px 0;
                position: relative;

                &:after {
                    content: "";
                    width: 50%;
                    display: block;
                    border-bottom: 1px solid rgba(10, 26, 92, 0.1);
                    margin: auto;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }

                &:last-child {
                    &:after {
                        border: none;
                    }
                }

                .products {
                    &-image {
                        width: 40px;
                        height: 40px;

                        &__link {
                            display: block;
                            width: 40px;
                            height: 40px;

                            img {
                                height: 40px;
                                min-height: 40px;
                            }
                        }
                    }

                    &-info {
                        width: calc(100% - 40px);
                        min-width: calc(100% - 40px);
                        padding-left: 10px;
                        box-sizing: border-box;
                    }

                    &-title {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        height: auto;
                    }

                    &-sum {
                        display: flex;
                        align-items: flex-end;
                        width: 100%;
                        padding-top: 4px;
                        justify-content: space-between;

                        p {
                            width: 33.333%;
                            margin: 0;
                            font-size: 13px;
                            opacity: .8;

                            &:after {
                                font-size: .8em;
                                margin: 0;
                            }
                        }

                        .price-total {
                            text-align: right;
                            opacity: 1;
                            font-weight: 500;
                            font-size: 15px;
                        }
                    }
                }
            }

            &__info {
                padding: 30px 0 20px;
                border-top: 1px solid rgba(10, 26, 92, 0.1);
                border-bottom: 1px solid rgba(10, 26, 92, 0.1);

                .info {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: 5px 0;

                    p {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.5);
                    }

                    span {
                        font-weight: 500;
                        font-size: 15px;
                        color: #000;
                    }
                }
            }

            &__footer {
                padding: 20px 0;
                display: flex;
                align-items: center;
                flex-direction: column;

                .info {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: 5px 0;
                    width: 100%;

                    p {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.5);
                    }

                    span {
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 5px;
                        color: #000;

                        &:after {
                            font-size: .8em;
                            margin: 0;
                        }
                    }
                }

                .btn-primary {
                    width: 100%;
                    margin: 10px 0 5px;
                    padding: 12px;
                    font-weight: 500;
                }

                .btn-link {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 18px;
                    color: #3A50AE;
                    padding: 10px 14px 14px;
                    display: inline-block;

                    &:hover {
                        color: $clr_orange
                    }
                }

                .check-accept-order {
                    font-size: 13px;
                    line-height: 130%;
                    text-align: center;
                    padding: 10px 0;
                    display: flex;
                    flex-direction: column;

                    button {
                        display: inline;
                        padding: 0;
                        font-size: 13px;
                        line-height: 130%;

                        &:hover {
                            color: $clr_orange
                        }
                    }

                    input[type="checkbox"]+label {
                        align-items: center;
                        width: auto;
                        margin: auto;
                        background: #f5f5f5;
                        padding: 20px;
                        user-select: none;

                        p {
                            text-align: left;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .req.city {
        margin-top: 15px;
    }

    .subtitle {
        font-size: 13px;
        color: #a1a1a1;
    }
}

.cart-empty {
    text-align: center;
}

@media screen and (max-width : 991px) {
    .page-checkout {
        .checkout {
            &-total {
                border: none;

                &__wrap {
                    padding: 0;
                }

                &__item {
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width : 576px) {
    .page-checkout {
        .checkout {
            &-login {
                .form-group {
                    width: 100% !important;

                    label {
                        margin: 0 20px 0 0 !important;
                    }
                }
            }

            &-delivery {
                &__dropdown {
                    .row-city {
                        flex-direction: column;
                        align-items: flex-start;

                        .select_info {
                            margin: 10px 3px 0;
                            font-size: 13px;
                        }
                    }

                    .row-address {
                        flex-wrap: wrap;

                        input {
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }
}

.modal-checkout {
    &__complete {
        .text {
            text-align: center;

            span {
                font-weight: 600;
                font-size: 15px;
                line-height: 130%;
                text-align: center;
                color: rgba(0, 0, 0, 0.5);

                &.tip {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 130%;
                    text-align: center;
                    color: rgba(7, 18, 60, 0.62);
                    max-width: 430px;
                    display: block;
                    margin: auto;
                }
            }
        }

        .modal-footer {
            justify-content: space-around;
        }

        .btn {
            padding: 12px 25px;
            font-weight: 500;

            &-light {
                display: flex;
                align-items: center;
                color: $clr_red;
                border: 2px solid;
                font-weight: 500;
                box-sizing: border-box;

                &:hover,
                &:focus {
                    background-color: #ffeeef;
                }

                .i-pdf {
                    display: block;
                    width: 15px;
                    height: 20px;
                    background: url("/img/i-pdf.svg") center center no-repeat;
                    background-size: contain;
                    margin-left: 10px;
                }
            }
        }
    }
}

.pdf-view {
    width: 80%;
    margin: 0 auto;
    padding-top: 50px;

    header {
        padding-bottom: 1rem;
        border-bottom: 1px solid $clr_blue;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
            padding: 0;
            border: none;
            font-size: 1.25rem;
            font-weight: 500;
        }

        img {
            width: 240px;
        }
    }

    .customer-info {
        b {}
    }

    h4 {
        padding: 15px 10px;
        font-size: 1.3rem;
    }

    .customer-items {
        .cart-items__wrap {
            padding: 0;

            .cart-item {
                padding: 10px 0;
            }
        }

        .products-info__top {
            align-items: center;

            .products-title {
                margin: 0;
            }

            .sub-price {
                font-weight: 500;
            }

            .tot-price {
                font-size: 18px;
                font-weight: 500;
            }
        }

        .cart-footer {
            padding: 10px 0;
            border: none;

            &__right {
                .cart-total {
                    margin-right: 0;

                    p {
                        color: $clr_dark;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .cart-table {
        width: 100%;

        .hb {
            height: 50px;
            background: #eee;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            flex-grow: 1;
        }

        .col {
            &-num {
                width: 40px;
            }
        }

        .col {
            &-qty {
                text-align: center;
            }
        }

        &__header {
            width: 100%;

            th {
                text-align: inherit;
                height: 40px;
                background: #eee;
                border-right: 2px solid #fff;
                padding: 10px;
                white-space: nowrap;
            }
        }

        &__body {
            //display: flex;
            width: 100%;
            counter-reset: count;

            td {
                text-align: inherit;
                height: 40px;
                border-bottom: 1px solid #eee;
                padding: 10px;
                font-size: 13px;
                font-weight: 500;
            }

            .col-num {
                width: 40px;
                text-align: center;

                &:before {
                    counter-increment: count;
                    content: counter(count);
                }
            }
        }
    }
}

.alert {
    p {
        margin-bottom: .5rem;
    }
}

.btn-link.simple {
    color: #3A50AE;
    font-weight: 500;

    &:hover {
        color: $clr_orange
    }
}