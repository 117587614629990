@import "../../scss/default.scss";

.buildedPage {

    br {
        // 
        display: none;
    }

    &__skeleton {
        &-menu {
            border-right-color: #9ba2c21c !important;
            min-height: calc(100vh - 300px);

            hr {

                border-color: #9ba2c21c;
            }

            div,
            p,
            span {
                display: block;
                background: #9ba2c21c;
                border-radius: 6px;
                margin: 12px;
            }

            @keyframes blink-animation {
                to {
                    opacity: 0.5;
                }
            }

            div {
                display: flex;
                align-items: center;
                height: 30px;
                margin-left: 45px;

                &::before {
                    content: '';
                    display: block;
                    width: 35px;
                    height: 35px;
                    border-radius: 100%;
                    margin-right: 4px;
                    transform: translateX(-45px);
                    background: #6d769e1c;
                }
            }

            p {
                position: relative;
                height: 35px;

                &::after {
                    content: "";
                    font-family: 'fontello';
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    color: #fdfeff97;
                }
            }

            span {
                height: 25px;
                opacity: 0.7;
                margin-left: 24px;
            }
        }
    }

    h1 {
        margin-bottom: 50px;
    }

    &-container {
        display: flex;
    }

    &-sidemenu {

        min-width: 320px;
        margin-right: 30px;
        margin-bottom: 50px;
        border-right: 2px solid #eff0f4;

        .icon-is-arrow {
            opacity: 0.3;
            // margin-left: auto;
            // margin-right: -4px;
            margin: 0 0 0 auto !important;
            padding: 0 !important;
            transform: rotate(-90deg) translateY(4px);
        }

        .active {
            background: #6d769e1c;


            .arrow-icon {
                transform: rotate(0deg);
            }
        }


        &__item {
            user-select: none;
            padding: 10px 0px 10px 0;
            border-bottom: 2px solid #eff0f468;

            &:last-child {
                border-bottom: 0;
            }

            a {
                display: flex;
                align-items: center;
                width: 100%;
                color: #1d1e23;
                // color: inherit;
                text-decoration: none;
            }

            .arrow-icon {
                margin: 4px;
                margin-left: auto;
                padding: 4px;
                border-radius: 100%;
                cursor: pointer;
                opacity: 0.3;
                transition: 250ms ease-in;

                &:hover {
                    background: #fff;
                    // background: rgba(0, 0, 0, 0.022);
                    opacity: 1;
                }
            }

            &-parent {
                position: relative;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 1.25rem;
                // margin-bottom: 12px;

                padding: 6px 10px;
                border-radius: 2px;


                .sidemenu__icon {

                    width: 1.2rem;
                    height: 1.2rem;
                    margin-right: 8px;
                }
            }

            &-chidren {

                .collapse:not(.show) {

                    border: 1px solid rgb(252, 246, 246);
                }

                // padding-left: 20px;
                // padding-left: 28px;
                margin-bottom: 4px;

                .buildedPage-sidemenu__item-chidren-title {
                    a {
                        padding-left: 30px;

                    }

                    &+div {
                        .buildedPage-sidemenu__item-chidren-title {
                            a {
                                padding-left: 50px;
                            }

                            &+div {
                                .buildedPage-sidemenu__item-chidren-title {
                                    a {
                                        padding-left: 80px;
                                    }

                                    &+div {
                                        .buildedPage-sidemenu__item-chidren-title {
                                            a {
                                                padding-left: 100px;
                                            }
                                        }

                                        &+div {
                                            .buildedPage-sidemenu__item-chidren-title {
                                                padding-left: 140px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &-title {
                    margin-bottom: 4px;
                    padding-left: 18px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 1.1rem;
                    transition: 250ms ease-in;
                    border-radius: 6px 0 0 6px;

                    a {
                        padding: 5px 10px;
                        border-radius: 2px;
                    }

                    .sidemenu__icon {
                        width: 1rem;
                        height: 1rem;
                        margin-right: 6px;
                    }
                }
            }

        }
    }

    &-content {
        flex-grow: 1;
        padding-bottom: 50px;
    }

    &-type_1 {

        margin-top: 50px;
        margin-bottom: 50px;

        .position-bottom {

            .title,
            .text {
                // margin: 20px 0;
            }
        }

        .once-image img {
            width: 100%;
        }

        .image-container {

            display: flex;
            flex-wrap: wrap;

            &_item {
                &.with-padding {
                    padding: 12px;
                }

                img {
                    box-shadow: 0 0 10px 15px #f1eeee4d;
                    width: 100%;
                    border-radius: 4px;

                    transition: 300ms ease;
                    cursor: zoom-in;

                    &:hover {
                        box-shadow: 0 0 30px 8px #00000012;
                        transform: perspective(30px) translateZ(1px);
                    }
                }
            }
        }

        &__carousel {
            padding-bottom: 50px;
            // max-width: 1270px;
            width: 100%;
            margin: 30px auto;

            img {
                width: 100%;
            }
        }
    }

    &-type_2 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    &-type_3 {
        margin-top: 50px;
        margin-bottom: 50px;

        img {
            margin-bottom: 30px;
            width: 100%;
        }

        .text {
            padding: 8px 0;
        }
    }

    &-type_4 {
        margin-top: 50px;
        margin-bottom: 20px;

        >.text {
            margin: 20px 0;
        }

        &__row {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;

            .card-item {

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;

                padding: 0 15px 55px;

                min-width: 150px;


                a {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-end;
                    color: #212529;
                    text-decoration: none;
                    transition: 200ms ease-in;

                    &:hover {
                        color: $clr_orange;

                        font {
                            color: $clr_orange;
                        }

                        img {
                            transform: perspective(40px) translateZ(1px);
                        }
                    }
                }

                img {
                    // height: 200px;
                    max-width: 65%;
                    object-fit: contain;
                    transition: 300ms ease;
                }

                .title {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 1.2;
                    margin: 16px 0 10px 0;
                    max-height: 54px;
                    overflow: hidden;
                    text-align: center;

                    * {
                        font-size: inherit !important;
                    }
                }

                .text {
                    font-size: 16px;
                    line-height: 1.5;
                    text-align: center;
                    height: 70px;
                    overflow: hidden;

                    * {
                        font-size: inherit !important;
                    }
                }
            }
        }
    }

    &-type_5 {

        // margin-top: 30px;
        // margin-bottom: 30px;

        .title-block {
            margin: 20px 0;
            // margin-bottom: 20px;
        }

        .text-block {
            margin: 15px 0;
            // margin-bottom: 30px;
        }

        &__row {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;

            &.perRow-3 {
                margin-left: -15px;
                margin-right: -15px;

                .card-item {
                    width: calc((100% / 3));

                    padding: 0 15px;
                    // padding-bottom: 30px;
                    margin-bottom: 30px;


                    .card-item-content {
                        height: 300px;

                        .title {
                            min-height: 58px;
                            max-height: 58px;
                            // min-height: 62px;
                            // max-height: 62px;

                        }

                        .text {
                            // max-height: 125px;
                        }
                    }

                    img {
                        height: 300px;
                    }

                }
            }

            &.perRow-2 {
                margin-left: -15px;
                margin-right: -15px;

                .card-item {
                    width: calc(100% / 2);

                    padding: 0 15px;
                    // padding-bottom: 30px;
                    margin-bottom: 30px;

                    .card-item-content {
                        height: 300px;

                        .title {
                            min-height: 58px;
                            max-height: 58px;
                            // min-height: 62px;
                            // max-height: 62px;

                        }

                        .text {
                            // max-height: 125px;
                        }
                    }

                    img {
                        height: 300px;
                    }
                }
            }

            &.perRow-1 {
                .card-item {

                    width: 100%;

                    margin: 0 0 30px 0;

                    img {
                        order: 2;
                        width: 50%;
                    }

                    .card-item-inner {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .card-item-content {
                        width: 50%;
                        padding-left: 30px;
                        padding-right: 30px;
                        height: 300px;

                        .title {
                            min-height: 58px;
                            max-height: 58px;
                            // min-height: 62px;
                            // max-height: 62px;

                        }

                        .text {
                            // max-height: 125px;
                        }
                    }

                    img {
                        height: 300px;
                        object-position: right;

                        &.isFluidImage {
                            object-position: center;
                        }
                    }
                }
            }
        }

        a {
            color: inherit;
            text-decoration: none;
        }

        .card-item-inner {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            border: 1px solid #9999994d;
            transition: 250ms ease-in-out;
            overflow: hidden;

            text-decoration: none;

            &:hover {
                box-shadow: 0 1px 13px rgba(10, 26, 92, .21);

                img {
                    transform: perspective(40px) translateZ(1px);
                }
            }
        }

        .card-item {


            // &:last-child {
            //     padding-bottom: 0 !important;
            //     margin-bottom: 0 !important;
            // }

            // min-width: 250px;


            img {
                // height: 300px;
                width: 100%;
                // max-width: 65%;
                object-fit: contain;
                transition: 250ms ease-in-out;

                &.isFluidImage {
                    object-fit: cover;
                }
            }

            .card-item-content {
                min-width: 100%;
                overflow: hidden;
                // height: 300px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 20px 16px;
                background: #fff;
                position: relative;
                z-index: 1;
            }

            // padding: 0 15px 30px;
            .title {
                font-size: 24px;
                font-weight: 500;
                line-height: 1.2;
                margin-bottom: 10px;
                // min-height: 62px;
                // max-height: 62px;
                overflow: hidden;

                * {
                    margin-bottom: 0 !important;
                    font-size: inherit !important;
                }
            }

            .text {
                position: relative;
                font-size: 15px;
                line-height: 1.5;
                margin-bottom: 8px;
                // flex-grow: 1;
                // max-height: 125px;
                overflow: hidden;

                * {
                    font-size: inherit !important;
                }

                // &::before {
                //     content: '';
                //     position: absolute;
                //     bottom: -8px;
                //     left: 0;
                //     right: 0;
                //     height: 1em;
                //     background: linear-gradient(to top, rgb(255, 255, 255), #fbfbfbd6);
                // }
            }

            .btn-link {

                position: relative;
                margin-top: auto;
                padding: 12px 22px;
                // background: transparent !important;
                // // color: rgb(1, 1, 212);
                border-radius: 4px;
                text-decoration: none;
                transition: 200ms ease;

                div {
                    position: relative;
                    z-index: 2;
                    transition: 250ms ease-in;
                }


                &::after {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 0px;
                    bottom: 0px;
                    right: 0px;
                    left: 0px;
                    background: #fff;
                    border-radius: 3px;
                    transition: 250ms ease-in;
                }

                &:active {
                    filter: brightness(1.2);

                    div {
                        color: inherit !important;
                    }

                    &::after {
                        opacity: 0;
                    }

                    // background: inherit !important;
                }
            }
        }
    }

    &-type_6 {
        margin-top: 50px;
        margin-bottom: 50px;

        >.text {
            margin: 20px 0;
        }

        &_row {
            display: flex;
            flex-wrap: wrap;
            margin-left: -6px;
            margin-right: -6px;

            .type_6-col {
                margin-bottom: 12px;
                padding-right: 6px;
                padding-left: 6px;
            }

            // если 1 ссылка
            .block-link {
                display: block;
                color: inherit;
                margin-bottom: 12px;
                padding-right: 6px;
                padding-left: 6px;

                &:hover {
                    text-decoration: none;

                    .buildedPage-type_6_row__item {
                        color: inherit;
                        background: #f0f4ff;
                        border: 1px solid #dfe5f7;
                    }
                }

                &.card-item:hover {
                    background: #fff;
                }

                //     &:not(.card-item):hover {
                //         color: inherit;
                //         text-decoration: none;
                //         background: #f0f4ff;
                //         border: 1px solid #dfe5f7;
                //     }
            }

            &__item {
                height: 100%;
                padding: 12px;
                border: 1px solid #e8e8e8;
                border-radius: 4px;

                // min-width: 300px;
                transition: 250ms ease-in;
                background: rgb(254, 254, 255);

                &.card-item {
                    background: #f0f4ff;
                    border: 1px solid #dfe5f7;

                    // .item-link {
                    //     font-size: 14px;
                    //     line-height: 1.5;
                    //     color: inherit;
                    // } 

                }


                .item-title {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }





                // если список ссылок 
                a {
                    text-decoration: none;

                    .item-link {
                        font-size: 14px;
                        line-height: 1.5;
                        color: #007bbf;
                        transition: 250ms ease;

                        &:hover {
                            color: $clr_orange;

                            font {
                                color: $clr_orange;
                            }
                        }
                    }
                }

            }
        }
    }

    &-type_7 {
        margin-top: 50px;
        margin-bottom: 50px;

        >.text {
            margin: 20px 0;
        }

        .accordion,
        .tabs {
            margin-top: 30px;
        }

        .accordion {
            .card-header {
                padding: 0;
                background: rgb(254, 254, 255);
                cursor: pointer;
                transition: 250ms ease;

                &:hover {
                    background: #fafbff;

                    .accordion_btn {
                        .arrow-icon {
                            opacity: 1;
                        }

                        color: #212529 !important;

                        font,
                        span,
                        p {
                            color: #212529 !important;
                        }
                    }
                }

            }

            .accordion_btn {
                padding: 20px 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                transition: 250ms ease;

                &.active {
                    color: #1d1e23;

                    .arrow-icon {
                        opacity: 1;
                    }

                    * {
                        color: #1d1e23;
                    }
                }

                font {
                    transition: 250ms ease;
                }

                .arrow-icon {
                    transition: 250ms ease;
                    transform: rotate(180deg);
                    opacity: 0.5;
                }

                &.rotated {
                    .arrow-icon {
                        transform: rotate(0deg);
                    }
                }
            }

        }

        &__tabs {
            border: 1px solid #e8e8e8;
            border-radius: 4px;
            padding: 12px 18px;

            .nav-tabs {
                position: relative;
                // border-bottom: 0;
                // margin-bottom: 0;

                a {
                    .tab-title {
                        color: #6c757d;
                        transition: 250ms ease;
                    }

                    &.active {
                        background: transparent;
                        color: #212529;

                        * {
                            color: #212529;
                        }
                    }

                    &:hover {
                        .tab-title {
                            color: #212529;
                            transition: 250ms ease;

                            * {
                                transition: 250ms ease;
                                color: #212529;
                            }
                        }
                    }
                }
            }

            .tab-content {
                position: relative;
                // border-top: 2px solid rgba(10, 26, 92, 0.15);
                // padding-top: 20px;

                // &::after {
                //     content: '';
                //     position: absolute;
                //     top: -44px;
                //     left: 0;
                //     right: 0;
                //     height: 2px;
                //     background: rgba(10, 26, 92, 0.15);
                // }
            }

            .nav {
                // flex-wrap: nowrap;
                // overflow-x: auto;
                // padding-bottom: 15px;
                // scrollbar-color: #bf236160 rgba(221, 216, 216, 0.515);
                // scrollbar-width: thin;
            }

            a.nav-item {
                // white-space: nowrap;
                border-bottom-width: 2px;
            }
        }


        .tab-title {

            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-bottom: 0;
            }
        }




        .card,
        .card-header {
            border-color: #e8e8e8;
        }
    }


    &-type_8 {
        &-form {
            background: #DCE1E5;
            // box-shadow: 0 5px 9px 0px #0000005e;
            padding: 22px 16px;
            border-radius: .25rem;

            .title {
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 12px;

                img {
                    width: 35px;
                    height: 35px;
                    margin-right: 5px;
                }
            }

            button:disabled {
                opacity: 0.4;
            }

            .btn-download {
                a {
                    display: inline-flex;
                    align-items: flex-start;
                    color: inherit;
                }

                &:hover {
                    a {

                        text-decoration: none;
                    }

                    color: #bf2462;
                }
            }

            .btn-add-file {
                label {
                    display: flex;
                    align-items: center;
                } 
                &:hover { 
                    color: #bf2462;
                }
            }

            .btn-download,
            .btn-add-file {
                display: inline-flex;
                align-items: flex-start;
                font-size: 14px;
                font-weight: 500;

                border: none;
                background: inherit;

                margin-bottom: 16px;

                cursor: pointer;
                transition: 250ms ease;

                label {
                    cursor: pointer;
                }

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    width: 1px;
                    height: 1px;

                }
            }

            .btn-submit {
                padding: 12px 20px;
                width: 100%;
                font-size: 1.2rem;
                transition: 250ms ease;

                &:hover {
                    filter: brightness(1.2)
                }
            }

            input,
            textarea {
                border-color: #c6ccd2;

                &:focus {
                    border-color: #bf2462;
                }
            }

            .form-check {
                padding-left: 0;

                label {
                    font-size: 1rem;
                    color: rgb(34, 34, 34) !important;
                }
            }

            .row {
                margin-left: -4px;
                margin-right: -4px;

                [class*='col'] {
                    padding: 0 4px;
                }
            }
        }
    }
}

.header-top-menu-mobile .rotated .arrow-icon,
.buildedPage-sidemenu .rotated .arrow-icon {
    transform: rotate(-90deg) !important;
}

@media screen and (min-width: 992px) {
    .buildedPage {
        &-sidemenu__item-chidren-title:hover {
            background: #6d769e1c;
        }

        &-type_5 {
            .card-item {
                .btn-link {
                    &:hover {
                        border-color: none !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {

    // .buildedPage-type_5__row.perRow-3 .card-item .card-item-content,
    // .buildedPage-type_5__row.perRow-3 .card-item img {
    //     height: 200px;
    // }

    .buildedPage {

        &-type_1 {
            // .position-bottom .image-container {
            //     &_item {
            //         min-width: 20%;
            //     }
            // }

            .image-container {
                &_item {
                    &.with-padding {
                        padding: 8px !important;
                    }
                }

            }

        }

        &-type_5 {

            &__row {
                &.perRow-3 {
                    margin-left: -10px;
                    margin-right: -10px;

                    .card-item {

                        width: calc(33.333333%);
                        padding-left: 10px;
                        padding-right: 10px;
                        // margin-bottom: 20px;
                        // margin: 0 15px 15px 0;
                        min-width: auto;

                        img {
                            height: 220px;
                        }

                        .card-item-content {
                            height: 250px;
                            padding: 12px 10px;

                            .title {
                                font-size: 18px;
                                min-height: 43px;
                                max-height: 43px;
                                // max-height: 45px;
                                // min-height: 45px;
                            }

                            .text {
                                // font-size: 14px;
                                // max-height: 130px;
                            }
                        }


                    }
                }

                &.perRow-2 {

                    margin-left: -10px;
                    margin-right: -10px;

                    .card-item {

                        padding-left: 10px;
                        padding-right: 10px;
                        // margin-bottom: 20px;

                        img {
                            height: 220px;
                        }

                        .card-item-content {
                            height: 250px;
                            padding: 12px 10px;

                            .title {
                                font-size: 18px;
                                max-height: 45px;
                                min-height: 45px;
                            }

                            .text {
                                // font-size: 14px;
                                // max-height: 130px;
                            }
                        }

                    }
                }

                &.perRow-1 {

                    .card-item {
                        // margin-bottom: 20px;

                        img {
                            height: 250px;
                        }

                        .card-item-content {
                            height: 250px;
                            padding: 16px 20px;

                            .title {
                                font-size: 18px;
                                max-height: 45px;
                                min-height: 45px;
                            }

                            .text {
                                // font-size: 14px;
                                // max-height: 130px;
                            }
                        }

                    }
                }

            }

        }

    }

}

@media screen and (max-width: 992px) {
    .buildedPage {

        &__skeleton {
            &-menu {
                border-right-color: #9ba2c21c !important;
                min-height: calc(100vh - 320px);

                hr,
                span,
                div {
                    display: none;
                }

                p:first-of-type {
                    width: 190px;
                    height: 35px;
                }

                p:nth-of-type(2) {
                    width: 50%;
                    margin-top: 20px;
                }

                p:nth-of-type(3) {
                    width: 30%;
                    margin-top: 40px;
                }



                p {
                    height: 30px;

                    &::after {
                        display: none;
                    }
                }
            }
        }

        &-sidemenu {
            margin-bottom: 20px;
            border: 0;

            &__item-chidren-title {
                border-radius: 6px;
            }
        }

        &-aside {
            position: fixed;
            left: -100%;
            width: 100%;
            max-width: 320px;
            top: 0;
            bottom: 0;
            overflow-y: auto;
            transition: .3s;
            background: #fff;
            z-index: 1003;
            padding: 1rem;

            &__btn {
                display: block !important;
                border: 1px solid $clr_primary;
                color: $clr_primary;

                &:active {
                    span {
                        background-color: #fff !important;
                    }
                }

                &:hover {
                    background-color: #fff !important;
                    color: $clr_primary !important;

                    .navbar-toggler-icon span {
                        background-color: $clr_primary !important;
                    }
                }

                .navbar-toggler-icon {
                    width: 1em;
                    height: 1em;
                    margin-right: 0.5em;

                    span {
                        height: 2px;
                        background-color: $clr_primary;
                        opacity: .7;
                        box-shadow: none;
                    }

                    span+span {
                        margin-top: 3px;
                    }
                }
            }

            &.open {
                left: 0;
            }

            &-wrapper {
                position: absolute;
                z-index: 1000;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(10, 26, 92, 0.6);
                transition: .3s;
                opacity: 0;
                pointer-events: none;


                &.open {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }

        h1 {
            font-size: 2rem;
            margin: 1.5rem 0;
        }

        &-container {
            flex-direction: column;
        }

        &-type_1 {
            .images-col {
                order: 4 !important;
            }

            .image-container {
                &_item.with-padding {
                    padding: 12px !important;
                }
            }
        }

        &-type_3 {
            .image-aside {
                display: flex;
                justify-content: center;
            }

            img {
                width: auto;
                max-width: 100%;
                max-height: 65vh;
                margin: 0 auto 20px auto;
            }
        }

        &-type_5 {
            .card-item .text {
                // font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .buildedPage {

        &-type_1,
        &-type_2,
        &-type_3,
        &-type_6,
        &-type_7,
        &-type_8 {
            margin-top: 30px;
            margin-bottom: 30px;
        }


        &-type_4 {
            margin-top: 30px;

            &__row {
                .card-item {
                    padding-left: 10px;
                    padding-right: 10px;
                    // padding-bottom: 20px;
                    min-width: 50%;

                    .title {
                        font-size: 20px;
                        margin-top: 12px;
                        margin-bottom: 8px;
                        max-height: 48px;
                    }
                }
            }
        }

        &-type_5 {
            &__row {
                .card-item .btn-link {
                    width: 100%;
                    text-align: center;

                    &:active {

                        div {
                            // color: inherit !important;
                        }
                    }
                }

                &.perRow-3 {
                    .card-item {
                        width: calc(50%);
                        // padding-left: 0 10px 10px 0;
                        min-width: auto;

                        img {
                            height: 180px;
                        }
                    }
                }

            }
        }

        &-type_8-form {

            .btn-download,
            .btn-add-file {
                // margin: 25px auto;
                text-align: center;
                justify-content: center;
            }

            .text_under {
                text-align: center;
            }
        }

    }
}

@media screen and (max-width: 576px) {
    .buildedPage {


        &-type_1 {

            .title,
            .text {
                margin: 10px 0;
            }

            .image-container {
                &_item {
                    &.with-padding {
                        padding: 6px !important;
                    }
                }

            }
        }

        &-type_5 {
            &__row {

                &.perRow-3 {
                    margin-left: -5px;
                    margin-right: -5px;

                    .card-item {

                        padding-left: 5px;
                        padding-right: 5px;
                        // padding-bottom: 10px;

                        img {
                            height: 150px;
                        }

                        .card-item-content {
                            // height: 200px;
                            padding-top: 10px;

                            .title {
                                font-size: 16px;
                                max-height: 42px;
                                min-height: 42px;
                                margin-bottom: 4px;
                            }

                            .text {
                                // font-size: 13px;
                                // max-height: 145px;
                            }

                            .btn-link {
                                width: 100%;
                                text-align: center;

                            }
                        }
                    }
                }

                &.perRow-2 {
                    margin-left: 0;
                    margin-right: 0;

                    .card-item {

                        width: 100%;

                        padding-left: 0;
                        padding-right: 0;
                        // padding-bottom: 20px;

                        // img {
                        //     height: 150px;
                        // }

                        .card-item-content {
                            // height: 200px;
                            padding-top: 10px;

                            // .title {
                            //     font-size: 16px;
                            //     // max-height: 42px;
                            //     // min-height: 42px;
                            //     margin-bottom: 4px;
                            // }

                            // .text {
                            //     font-size: 13px;
                            //     max-height: 140px;
                            // }

                            .btn-link {
                                width: 100%;
                                text-align: center;
                            }
                        }
                    }
                }

                &.perRow-1 {

                    .card-item {
                        // margin-bottom: 20px;

                        .card-item-content {
                            // height: 200px;
                            padding: 16px;

                            .btn-link {
                                width: 100%;
                                text-align: center;
                            }
                        }
                    }
                }

            }
        }

        &-type_6_row .type_6-col,
        &-type_6_row .block-link {
            min-width: 50%;
        }

        &-type_8-form {

            .btn-download,
            .btn-add-file {
                display: flex;
                margin: 0 0 25px 0;
                text-align: center;
                justify-content: center;
            }

            .text_under {
                text-align: center;
            }
        }
    }
}