@import './default';
@import './components/main/modal.scss';

// ? Ui
@import "./components/ui/block";
@import "./components/ui/border";
@import "./components/ui/display";
@import "./components/ui/flex";
@import "./components/ui/font-size";
@import "./components/ui/grid";
@import "./components/ui/margin";
@import "./components/ui/opacity";
@import "./components/ui/padding";
@import "./components/ui/text";
@import "./components/ui/size";
@import "./modal-tabs.scss";

html,
body {
  font-family: "Glober", sans-serif;
  font-weight: 400;
  font-size: 15px;
  position: relative;
  z-index: 0;
  background-color: #fff;
}

.container {
  @media screen and (min-width : 1626px) {
    max-width: 1600px !important;
  }
}

@media screen and (max-width : 991px) {

  .bfix,
  .bfix #root {
    position: fixed;
    height: 100%;
    width: 100%;

    .bfix #root {
      overflow: hidden;
    }

    #site {
      overflow-y: auto;
    }
  }

  .container {
    max-width: 100%;
  }
}

a {
  outline: none;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  //width: 100%;
}

.fg {
  flex-grow: 1;
}

.gap {
  gap: 1rem;
}

.ttu {
  text-transform: uppercase;
}

.ta {
  &c {
    text-align: center;
  }

  &l {
    text-align: left;
  }

  &r {
    text-align: right;
  }
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.img {
  width: 100%;
  object-fit: contain;
}

button {
  &.btn-clear {
    background: none;
    border: none;
    outline: none;
    box-shadow: unset;
    padding: 0;
    margin: 0;
    font-size: inherit;

    &:hover,
    &:focus,
    &:active {
      background: none;
      border: none;
      //outline: none;
      box-shadow: unset;
      padding: 0;
      margin: 0;
      font-size: inherit;
    }
  }

  &:focus {
    outline: none;
  }
}

#site {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  min-height: 100vh;

  &>.container {
    flex-grow: 1;

    &>main {

      //min-height: calc(100vh - 156px);
      &>.container {
        padding-bottom: 50px;
        background: #fff;
        padding-left: 0;
        padding-right: 0;
      }

      @media screen and (max-width : 991px) {
        min-height: calc(100vh - 60px);
      }
    }
  }

  &.main_layout {
    background: left top repeat;

    &>.bg-white {
      @media screen and (min-width : 992px) {
        z-index: 2;
      }
    }
  }
}

.svgsprite {
  height: 0;
  overflow: hidden;
}

.page-title {
  font-size: 1.75rem;
}

.page-subtitle {
  margin: 1.5rem 0 1.5rem;
  color: $clr_black;

  span {
    opacity: 0.4;
  }
}

.page-error {
  .container {
    min-height: calc(100vh - 156px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.breadcrumb {
  background: transparent;
  padding-left: 0;
  padding-right: 0;

  &-item {
    a {
      color: $clr_blueLighten;
      font-weight: 500;

      &:hover {
        color: $clr_primary;
      }
    }

    &:last-child {
      //display: none;
    }
  }
}

/* Carousel products */
.tns-nav {
  display: flex;
  position: absolute;
  width: 100%;
  width: calc(100% - 15px);
  bottom: 0px;

  button {
    padding: 1.5px;
    border: none;
    display: block;
    flex-grow: 1;
    background: transparentize($clr_gray, 0.7);

    &:focus,
    &:active:focus {
      outline: none;
    }
  }

  &-active {
    background: $clr_primary !important;
  }
}

.tns-controls {
  position: absolute;
  top: 45%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
  width: calc(100% - 15px);

  &:focus {
    outline: none;
  }

  button {
    border: none;
    background: transparentize($clr_blue, .5);
    color: $clr_white;
    overflow: hidden;
    height: 22px;
    width: 28px;
    padding-right: 23px;
    outline: none;
    display: block;
    white-space: nowrap;
    border-radius: 4px 4px 0 0;
    position: relative;

    &:before {
      content: '\e800';
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      display: inline;
      text-decoration: inherit;
      margin-right: .4em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      margin-left: .1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &:first-child {
      transform: rotate(90deg);
      left: -3px;
    }

    &:last-child {
      transform: rotate(-90deg);
      right: -3px;
    }

    &:disabled {
      opacity: .5;
    }
  }
}

.react-multiple-carousel__arrow--right {
  right: -webkit-calc(4% + 1px);
  right: -moz-calc(4% + 1px);
  right: calc(4% + 1px);
}

.react-multiple-carousel__arrow {
  border: none;
  background: transparentize($clr_blue, .5);
  color: $clr_white;
  color: #fff;
  overflow: hidden;
  height: 28px;
  width: 22px;
  outline: none;
  display: block;
  white-space: nowrap;
  min-width: 10px;
  min-height: auto;
  padding: 0;

  &:before {
    content: '\e800';
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    display: inline;
    text-decoration: inherit;
    margin-right: .4em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 15px;
    display: block;
    position: absolute;
    left: 4px;
    top: 7px;
    transform: rotate(-90deg);
    margin: 0;
  }

  &--right {
    right: 0;
    border-radius: 4px 0 0 4px;
  }

  &--left {
    left: 0;
    border-radius: 0 4px 4px 0;

    &:before {
      transform: rotate(90deg);
    }
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: transparentize($clr_blue, .3);
  }
}

.modal-scroll {
  margin: -1rem;
  padding: 1rem;
  overflow: auto;
  max-height: 400px;
}

.all-products {
  padding: 2rem 0;
}

.readmore {
  &__button {
    margin-top: 5px;
    height: 22px;
    margin-bottom: 2rem;
    color: $clr_blueLighten;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: $clr_primary;
    }
  }

  .overhang {
    z-index: 10;
    background-image: linear-gradient(rgba(255, 255, 255, 0) 10%, rgb(255, 255, 255) 100%);
  }
}

.select-custom {
  display: inline-block;
  font-size: 14px;
  color: #221f1f;
  padding-left: 12px;
  padding-right: 40px;
  width: 100%;
  max-width: 100%;
  height: 32px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: $clr_white url("../img/i-arrow-b.svg") no-repeat;
  background-size: 14px 9px;
  background-position: right 12px center;
}

@-webkit-keyframes FadeInBlock {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes FadeInBlock {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes FadeInBlock {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes FadeInBlock {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeInBlock {
  animation: .7s both FadeInBlock;
}

.mwlist {
  &__toolbar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    height: 35px;
    position: relative;

    .wlnew {
      position: absolute;
      top: 0;
      left: 0;
      display: none;

      &.show {
        display: block;
      }

      .form-group {
        margin: 0;
      }

      .form-field {
        display: flex;
        align-items: center;

        .btn {
          padding: 0 5px;
          margin: 0 5px;
          font-size: 15px;
          font-weight: 500;
          width: auto;
          display: flex;
          align-items: center;

          &_cancel {
            padding: 0;

            svg {
              transform: rotate(45deg);
            }
          }
        }
      }

      &__btn {
        font-size: 14px;
        width: auto;
        padding: 5px 0;
        color: $clr_primary;
        font-weight: 500;
        display: flex;
        align-items: center;

        &:before {
          content: "+";
          font-size: 28px;
          line-height: 17px;
          display: block;
          height: 20px;
          margin-right: 3px;
        }

        &:hover,
        &:focus {
          text-decoration: none;

          span {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__inner {
    padding: 10px 0;

    .checkbox-wrap {
      max-height: 140px;
      overflow-y: auto;
    }

    input[type="checkbox"]+label {
      font-size: 15px;
    }
  }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield !important;
}

// .css-2b097c-container {
//     margin-right: 40px;
//     min-width: 240px !important;
// }
.css-yk16xz-control {
  min-height: 20px !important;
  height: 36px !important;
}

// .page-profile .prof-content__body .fields-wrap .form-field input {
//     min-width: 240px !important;
// }
.page-checkout .checkout-delivery__dropdown {
  overflow: visible !important;
}

.product-main__left {
  position: relative !important;
  z-index: 999;
}

.price-unit {
  display: inline-flex;
  // color: #706d6d;
  color: #999;
  font-size: 26px;
  line-height: 1.2;
  font-weight: 500;

  span {
    display: inline-block;
    margin-left: .3rem;
  }
}

.in-cart-notification {
  display: none; //flex
  position: fixed;
  top: 180px;
  right: 30px;

  z-index: 9999;

  .content {
    font-family: "Glober";
    font-size: 18px;
    font-weight: bold;
    padding: 12px 18px;
    background-color: #282727e0;
    color: #dfdcdc;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    gap: 18px;
    margin-left: auto;
  }

  a {
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #faf8f9;
    border: none;
    transition: 300ms ease !important;

    &:hover {
      box-shadow: none !important;
      text-decoration: none;
    }
  }
}