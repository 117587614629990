@import '../../../default';


.popup-cart {
    position: absolute;
    right: 0;
    top: 100%;
    padding-top: 10px;
    transition: .3s;
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 1px;
        right: 10px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 9px solid #ffffff;
    }

    &__wrap {
        background: #FFFFFF;
        box-shadow: -2px 2px 30px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        width: 500px;
    }

    &__header {
        background: rgba(10, 26, 92, 0.1);
        padding: 20px;
        border-bottom: 1px solid rgba(10, 26, 92, 0.1);

        p {
            color: rgba(0, 0, 0, 0.5);
            //font-weight: 500;
            font-size: 15px;
            line-height: 130%;
            margin-bottom: 4px;

            span {
                color: $clr_black;
                font-size: 17px;
                //font-weight: 600;

                &:after {
                    font-size: 15px;
                    margin: 0;
                }
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 20px 0;

        .btn-primary {
            border: transparent;
            //text-transform: uppercase;
            //font-weight: 500;
            line-height: normal;
            line-height: initial;
            font-size: 17px;
            padding: 12px 20px;
            width: 100%;
        }

        .open-cart {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #3A50AE;
            padding: 10px 14px 14px;

            &:hover {
                color: $clr_orange
            }
        }
    }
}

.modal-dialog.modal-cart {
    max-width: 755px;

    .modal-header {
        justify-content: flex-start;
    }

    .modal-body {
        padding: 0;
    }
}


.cart-items {
    .price-unit {
        font-size: 15px !important;

        span {
            font-size: inherit;
        }

        p {
            margin: 0;
        }
    }

    &__wrap {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;

        .cart-item {
            display: flex;
            padding: 10px 16px;
            border-bottom: 1px solid rgba(10, 26, 92, 0.1);
            transition: 250ms ease;
            border-radius: 4px;

            &:hover {
                background: rgba(10, 26, 92, 0.041);
            }

            &:last-child {
                border-bottom: none;
            }

            .product-header__code {
                font-size: 13px;
                margin-bottom: 0;
                color: #b1b1b1;
            }

            .product-sale span {
                font-size: 16px !important;
            }

            .products {
                &-image-cart {
                    // overflow: hidden;
                    width: 40px;
                    height: 40px;

                    &__link {
                        display: block;

                        img {
                            width: 40px;
                            height: 40px;
                            min-height: 40px;
                            min-width: 40px;
                            object-fit: contain;
                        }
                    }
                }

                &-info {
                    flex-grow: 1;
                    margin-left: 15px;

                    &__top {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                    }

                    &__bottom {
                        display: grid;
                        grid-template-columns: 2fr 1fr 1fr;
                        justify-content: space-between;
                        align-items: flex-end;
                    }
                }

                &-title {
                    height: auto;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                }

                &-remove {
                    margin: -10px -10px 0 0;

                    &__btn {
                        padding: 5px 5px 8px;
                        font-size: 16px !important;
                    }

                    i {
                        color: transparentize($clr_blue, .7);
                        transition: .3s;
                    }

                    &:hover {
                        i {
                            color: $clr_red
                        }
                    }
                }

                &-price {
                    width: auto;

                    .price {
                        font-size: 18px;
                        line-height: 130%;
                        font-weight: 500;

                        &:after {
                            font-size: .8em;
                        }
                    }

                    &__old {
                        height: auto;

                        .price {
                            font-size: 13px;
                            line-height: 130%;
                        }
                    }
                }

                &-sum {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    &__total {
                        width: 100px;
                        text-align: right;

                        .price {
                            font-size: 21px;
                            line-height: 26px;
                            font-weight: 500;

                            &:after {
                                font-size: .7em;
                                line-height: 26px;
                                margin: 0;
                            }
                        }
                    }
                }

                &-quantity {
                    border: 1px solid rgba(10, 26, 92, 0.082);
                    box-sizing: border-box;
                    border-radius: 4px;
                    overflow: hidden;
                    position: relative;
                    margin: 0 .5rem;
                    transition: $transition;

                    &:focus,
                    &:hover,
                    &:active {
                        border: 1px solid #3A50AE;
                    }

                    .rc-input-number {
                        height: 33px;
                        width: 110px;
                        background: #FFFFFF;

                        &-input-wrap {
                            position: relative;
                            z-index: 1;
                            width: 44px;
                            margin: auto;

                            input {
                                width: 44px;
                                height: 33px;
                                padding: 0 5px;
                                box-sizing: border-box;
                                text-align: center;
                                font-size: 15px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border: none;
                                border-left: 1px solid rgba(10, 26, 92, 0.082);
                                border-right: 1px solid rgba(10, 26, 92, 0.082);

                                &:focus {
                                    border-color: rgba(10, 26, 92, 0.082);
                                    box-shadow: none;
                                    outline: none;
                                }
                            }
                        }

                        &-handler-wrap {
                            display: flex;
                            justify-content: space-between;
                            flex-direction: row-reverse;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }

                        &-handler-up {
                            &-inner {
                                display: flex;
                                width: 28px;
                                height: 33px;
                                position: relative;
                                transition: .3s;
                                cursor: pointer;

                                &:before {
                                    content: "";
                                    display: block;
                                    width: 12px;
                                    height: 2px;
                                    background: #3A50AE;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }

                                &:after {
                                    content: "";
                                    display: block;
                                    height: 12px;
                                    width: 2px;
                                    background: #3A50AE;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }

                                &:hover {
                                    background: rgba(58, 80, 174, 0.2);
                                }
                            }
                        }

                        &-handler-down {
                            &-inner {
                                display: flex;
                                width: 28px;
                                height: 33px;
                                position: relative;
                                transition: .3s;
                                cursor: pointer;

                                &:before {
                                    content: "";
                                    display: block;
                                    width: 12px;
                                    height: 2px;
                                    background: #3A50AE;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }

                                &:hover {
                                    background: rgba(58, 80, 174, 0.2);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.cart-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 30px;
    border-top: 1px solid rgba(10, 26, 92, 0.1);

    &__right {
        display: flex;
        align-items: center;

        .cart-total {
            margin: 13px 250px 13px 0;
            display: flex;
            align-items: flex-end;

            p {
                font-size: 15px;
                color: rgba(0, 0, 0, 0.6);
                margin: 0 8px;
                line-height: 33px;
            }

            span {
                font-weight: bold;
                font-size: 24px;
                margin: 0;

                &:after {
                    font-size: .8em;
                    margin: 0;
                }
            }
        }

        .btn {
            font-size: 17px;
            padding: 13px 50px;
        }
    }
}

.cart-close {
    position: absolute;
    bottom: 30px;
    left: 30px;
    color: #3A50AE;
    font-weight: 500;

    &:hover {
        color: $clr_orange
    }
}

.to-checkout {
    font-size: 17px;
    padding: 13px 50px;
    position: absolute;
    bottom: 27px;
    right: 30px;
}

.page-cart {
    h3 {
        margin: 30px 0;
    }

    &-container {
        // position: relative;
        // max-height: calc(100vh - 300px);
        // min-height: calc(100vh - 300px);
        // background: #000;
        overflow-y: auto;
        overflow-x: hidden;

        .cart-items__wrap {
            max-height: none;

            .cart-item {
                padding-top: 20px;
                padding-bottom: 20px;

                .products {
                    &-title {
                        font-size: 16px;
                    }

                    &-image-cart {
                        width: 80px;
                        height: 80px;

                        &__link {
                            img {
                                width: 80px;
                                height: 80px;
                                min-height: 80px;
                                min-width: 80px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-footer {
        position: sticky;
        z-index: 1;
        right: 0;
        bottom: 0;
        background: #fff;
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;
        // margin-top: 30px;
        border-top: 1px solid rgba(10, 26, 92, 0.1);

        box-shadow: 0px 2px 33px -1px #18181834;
        // &::after {
        //     position: absolute;
        //     content: '';
        //     width: 100%;
        // }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            min-width: 400px;

            &--price-row {
                display: flex;
                // display: grid;
                align-items: center;
                grid-template-columns: 1fr 2fr;

                p {
                    text-align: right;
                    min-width: 120px;
                }
            }

            &_btn {
                width: 100%;
                border-radius: 6px;
                padding: 30px 80px;
                background: rgb(255 79 150 / 15%);
            }

            .text-sale {
                color: #d60024;
            }

            .total-title {
                font-size: 14px;
                font-weight: 500;
            }

            p {
                font-size: 16px;
                font-weight: 500;

                span {
                    font-weight: 700;
                    font-size: 20px;

                    &::after {
                        font-size: 16px;
                    }
                }
            }

            a {
                width: 100%;
            }
        }
    }
}



@media screen and (max-width : 767px) {
    .page-cart-footer {
        flex-direction: column;
        align-items: center;
        text-align: center;

        &__content {
            // align-items: center;
            text-align: center;

            &_btn {
                width: 100%;
                padding-left: 30px;
                padding-right: 30px;
            }
        }

        a.to-back {
            display: none;
        }
    }

    .cart-items {
        &__wrap {
            .cart-item {
                position: relative;

                // padding-bottom: 60px;
                .products-title {
                    font-size: 14px !important;
                }

                .products {
                    &-info__bottom {
                        // display: flex;
                        // flex-direction: column;
                        grid-template-columns: 1fr 1fr;
                    }

                    &-price__wrap {
                        display: flex;
                        gap: 12px;
                    }

                    &-sum {
                        justify-content: flex-start;

                        // position: absolute;
                        // left: 0;
                        // right: 0;
                        // bottom: 1rem;
                        &__total {
                            text-align: left;
                        }
                    }

                    &-quantity {
                        margin: 0;
                    }
                }
            }
        }
    }

    .cart-footer {
        flex-direction: column;

        &__right {
            .cart-total {
                margin: 0px 0 105px;
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    .cart-close {
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
    }

    .to-checkout {
        left: 30px;
        bottom: 60px;
    }
}

@media screen and (max-width : 567px) {
    .page-cart {
        &-footer {
            &__content {
                min-width: 100%;

                &_btn {
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }
        }

        &-container {
            .cart-items__wrap {
                .cart-item {
                    padding-left: 8px;
                    padding-right: 8px;

                    .products {
                        &-title {
                            font-size: 14px;
                        }

                        &-info {
                            margin-left: 8px;
                        }

                        &-image-cart {
                            width: 40px;
                            height: 40px;

                            &__link {
                                img {
                                    width: 40px;
                                    height: 40px;
                                    min-height: 40px;
                                    min-width: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}