@import "../../default.scss";
// .header-desktop {
//     .catalog-menu {

//     }
// }
.header-catalog {
    @media screen and (max-width : 991px) {
        display: none;
    }
}

.catalog-menu {
    position: relative;
    z-index: 103;

    .header-catalog {
        /* @media screen and (max-width : 991px){
            left: -59px;
            top: -29px;
            width: calc(100vw - 2px);
            max-width: calc(100vw - 2px);
            height: calc(100vh - 2px);
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            overflow: hidden;
        } */
    }

    h1 {
        font-size: 1.75rem;
    }

    .menu-categories {
        position: relative;
        width: 300px;
        border-radius: 3px;
        padding-top: 15px;
        padding-bottom: 10px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: #fff;

        @media screen and (max-width : 991px) {
            width: 100%;
        }

        &__name {
            flex-grow: 1;
        }

        &__link {
            position: relative;
            z-index: 10;
            display: inline-block;
            vertical-align: bottom;
            width: 100%;
            padding: 3px 15px 3px 15px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            font-size: 14px;
            line-height: 31px;
            -webkit-transition: .3s;
            -o-transition: .3s;
            transition: all .3s ease;
            font-size: 15px;
            font-weight: 500;
            color: $clr_blue;
            transition: unset;
            display: flex;
            flex-grow: 1;
            width: 300px;
            text-decoration: none;

            @media screen and (max-width : 991px) {
                width: 100%;
                pointer-events: none;
            }

            &:after {
                font-family: "fontello";
                font-style: normal;
                font-weight: normal;
                speak: none;
                display: inline-block;
                text-decoration: inherit;
                width: 1em;
                margin-right: .2em;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                margin-left: .2em;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e800";
                margin-right: -5px;
                margin-bottom: -2px;
                transform: rotate(-90deg);
                font-size: 11px;
                text-decoration: none;
                max-width: 14px;
                color: $clr_black;
            }

            &.gc,
            &.deko {
                height: 50px;
                align-items: center;
                border-top: 1px solid #4e59883b;
                margin-top: 5px;

                @media screen and (max-width : 991px) {
                    border-top: none;
                }

                .menu-categories__icon {
                    width: 50px;
                    min-width: 50px;
                }

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

            &.gc {
                &:after {
                    display: none;
                }
            }

            &:hover {
                &::after {
                    color: #F26522;
                }
            }
        }

        &__item {

            &_state_hovered {

                .menu-categories__name,
                .menu-categories__link:after {
                    color: $clr_orange;
                }

                svg {
                    path {
                        fill: $clr_orange;
                    }
                }

                .menu__hidden-content,
                .menu__hidden-column {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &:first-child {

                //padding-bottom: 5px;
                a {

                    //&:after{
                    //    display: none;
                    //}
                    &:hover {
                        svg {
                            path {
                                fill: $clr_primary;
                            }
                        }
                    }
                }
            }

            &:hover {}
        }

        &__icon {
            position: relative;
            display: inline-block;
            margin-right: 8px;
            text-align: center;
            width: 30px;
            height: 35px;
            height: 30px;
            display: flex;

            img {
                max-width: 100%;
                max-height: 100%;
            }

            svg {
                vertical-align: bottom;
            }
        }
    }

    .menu__hidden-content {
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        display: flex;
        opacity: 0;
        visibility: hidden;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        //height: 100%;
        //min-height: 420px;
        min-height: 100%;
        // padding: 8px 8px 8px 300px;
        padding: 15px 12px 8px 300px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #fff;
        overflow: hidden;
        -webkit-box-shadow: 0 2px 20px rgba(0, 0, 0, .2);
        box-shadow: 0 2px 20px rgba(0, 0, 0, .2);
        width: 1296px;

        @media screen and (min-width : 1626px) {
            width: 1576px;
        }

        @media screen and (max-width : 1349px) {
            width: 1110px;

            .menu__hidden-column_color_gray {
                display: none;
            }

            .menu__main-cats {
                width: 100%;
            }
        }

        @media screen and (max-width : 1199px) {
            width: 930px;
        }

        @media screen and (max-width : 991px) {
            //padding: 8px;
            //display: none;
            background: #fff;
        }
    }

    .menu__hidden-column {
        //display: none;
        width: calc(100% / 4);
        padding-top: 8px;
        padding-bottom: 8px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        opacity: 0;
        visibility: hidden;

        &_color_gray {
            -ms-flex-negative: 0;
            flex-shrink: 0;
            width: 20%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background-color: #f8f8f8;
        }

        &_no_padding {
            position: relative;
            // right: -8px;
            top: -8px;
            padding: 0;
            margin-left: auto;
            text-align: right;

            @media screen and (max-width : 1350px) {
                display: none;
            }
        }
    }

    .menu__hidden-title {
        position: relative;
        display: block;
        margin-top: 4px;
        font-size: 14px;
        padding: 3px 12px;
        line-height: 18px;

        &:hover {
            color: $clr_orange;
        }
    }

    .menu__hidden-title_color_gray {
        color: #999;

        &:hover {
            color: #999;
        }
    }

    .menu__hidden-column_color_gray .menu__hidden-list {
        position: relative;
        //max-height: 520px;
        overflow: hidden;
    }

    .menu__link {
        position: relative;
        display: inline-block;
        vertical-align: bottom;
        width: 100%;
        padding: 2px 12px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        font-size: 13px;
        line-height: 15px;
        white-space: nowrap;
        color: #333;

        &:hover {
            color: $clr_orange;
        }

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 20px;
            height: 19px;
            background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, .4)), color-stop(55%, #fff));
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, .4) 0, #fff 55%);
            background: -o-linear-gradient(left, rgba(255, 255, 255, .4) 0, #fff 55%);
            background: linear-gradient(to right, rgba(255, 255, 255, .4) 0, #fff 55%);
            content: '';
        }
    }

    .menu__link_background_gray:after {
        background: -webkit-gradient(linear, left top, right top, from(rgba(248, 248, 248, .4)), color-stop(55%, #f8f8f8));
        background: -webkit-linear-gradient(left, rgba(248, 248, 248, .4) 0, #f8f8f8 55%);
        background: -o-linear-gradient(left, rgba(248, 248, 248, .4) 0, #f8f8f8 55%);
        background: linear-gradient(to right, rgba(248, 248, 248, .4) 0, #f8f8f8 55%);
    }

    .menu__hidden-column {
        //display: none;
        width: 220px;
        padding-top: 8px;
        padding-bottom: 8px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .menu__main-cats {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        min-height: 470px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;

        .menu__hidden-column {
            width: calc(100% / 4);

            @media screen and (max-width : 1199px) {
                width: calc(100% / 3);
            }
        }

        @media screen and (max-width : 990px) {
            height: calc(100vh - 55px);
            height: calc(100% - 55px);
            overflow: auto;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;

        &.menu__hidden-list {
            width: calc(75% - 10px);
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            @media screen and (max-width : 1350px) {
                width: 100%;
            }

            &>li {
                flex-basis: 25%;
                max-width: 25%;
            }
        }
    }

    .menu__hidden-title {
        position: relative;
        display: block;
        margin-bottom: 4px;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 16px;
        line-height: 18px;
    }

    .menu__main-cats-inner {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        width: 100%;
        //height: 520px;
        overflow: hidden;
        box-sizing: border-box;
    }

    .banner-col {
        a {
            width: 100%;
            font-size: 0;

            img {
                width: 100%;
                max-height: 720px;
                object-fit: cover;
            }
        }
    }
}

#overlay_main,
.overlay_main,
.overlay_main_m {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 103;
    background-color: rgba(0, 0, 0, .5);
    background: transparentize($clr_blue, .4);
    background: rgba(5, 13, 45, 0.65);
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: opacity .2s ease-in-out, -webkit-transform .1ms ease-in-out .2s;
    transition: opacity .2s ease-in-out, -webkit-transform .1ms ease-in-out .2s;
    -o-transition: transform .1ms ease-in-out .2s, opacity .2s ease-in-out;
    transition: transform .1ms ease-in-out .2s, opacity .2s ease-in-out;
    transition: transform .1ms ease-in-out .2s, opacity .2s ease-in-out, -webkit-transform .1ms ease-in-out .2s;

    &.open {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: opacity .2s ease-in-out;
        -o-transition: opacity .2s ease-in-out;
        transition: opacity .2s ease-in-out;
    }
}

.header-catalog {
    position: absolute;
    z-index: 999;
    // top: 147%;
    top: 160px;
    left: 0;
    // left: -292px;
    left: 0;
    //display: none;
    margin-top: -8px;
    border-radius: 5px;
    background-color: #fff;
    width: 300px;
    display: flex;
    transition: opacity .2s;
    opacity: 0;
    visibility: hidden;
    display: none;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: -8px;
        left: 288px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 10px solid #fff;
    }

    &.open {
        opacity: 1;
        visibility: visible;
        display: block;
    }

    @media screen and (max-width : 991px) {
        opacity: 0;
        visibility: hidden;
    }
}

.menu-categories {
    position: relative;
    width: 300px;
    border-radius: 3px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    background-color: #fff;

    &__name {
        flex-grow: 1;
    }

    &__link {
        position: relative;
        z-index: 10;
        display: inline-block;
        vertical-align: bottom;
        width: 100%;
        padding: 3px 15px 3px 15px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 31px;
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: all .3s ease;
        font-size: 15px;
        font-weight: 500;
        color: $clr_blue;
        transition: unset;
        display: flex;
        flex-grow: 1;
        width: 300px;
        text-decoration: none !important;

        &:hover {
            .menu-categories__name {
                color: $clr_orange;
                //text-decoration: underline;
            }

            &+.menu__hidden-content {
                opacity: 1;
                visibility: visible;

                .menu__hidden-column {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &:after {
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            width: 1em;
            margin-right: .2em;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            margin-left: .2em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e800";
            margin-right: -5px;
            margin-bottom: -2px;
            transform: rotate(-90deg);
            font-size: 11px;
            text-decoration: none;
            max-width: 14px;
            color: $clr_black;
        }

        &.gc,
        &.deko {
            height: 50px;
            align-items: center;
            border-top: 1px solid #4e59883b;
            margin-top: 5px;

            .menu-categories__icon {
                width: 50px;
            }

            img {
                width: 100%;
                object-fit: contain;
            }
        }
    }

    &__item {
        &_state_hovered {

            .menu__hidden-content,
            .menu__hidden-column {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__icon {
        position: relative;
        display: inline-block;
        margin-right: 8px;
        text-align: center;
        width: 30px;
        height: 30px;
        display: flex;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.with-top-banner .header-catalog__mobile {
    top: -85px;
}

.header-catalog__mobile {
    opacity: 0;
    visibility: hidden;
    left: 0;
    top: -59px;
    // top: -84px;
    // top: -85px;
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: .3s;

    &>.menu-hidden-content__header {
        position: absolute;
        width: 100%;
    }

    &.open {
        opacity: 1;
        visibility: visible;
    }

    .menu-categories {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
        transition: .3s;
        overflow-x: hidden;
        overflow-y: auto;
        // height: calc(100vh - 55px);
        // padding-bottom: 100px;
        height: calc(100% - 55px);

        &.move {
            transform: translateX(-100%);
            // height: auto;
            overflow-x: initial;
            overflow-y: initial;
        }

        &__link {
            padding: 10px 12px;
            border-bottom: 1px solid #ebebeb;
        }
    }

    .menu__main-cats {
        .menu__hidden-column {
            width: 100%;
            padding: 0;
        }
    }

    .menu__main-cats {
        overflow: hidden;

        &-inner {
            flex-direction: column;
            height: auto;
            overflow-y: auto;

            &>.menu__hidden-title_color_gray {
                padding: 30px 12px 10px 12px;
                margin: 0;
            }
        }
    }

    .menu__hidden-content {
        width: 200vw;
        box-sizing: border-box;
        margin-left: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
        transform: translateY(-54px);
        box-shadow: none;

        .menu-main__popular {
            .menu__link {
                color: $clr_blueLighten;
                font-size: 15px;
                font-weight: 500;
            }
        }

        .menu__hidden-title_color_gray {
            font-size: 13px;
            padding: 10px 12px;
            border-bottom: 1px solid #ebebeb;
        }

        .menu__hidden-list {
            padding-bottom: 1rem;

            .menu__hidden-title {
                font-size: 16px;
                padding: 13px 12px;
                border-bottom: 1px solid #ebebeb;
                color: $clr_blueLighten;
                font-size: 15px;
                margin: 0;
                padding: 15px 12px;
            }

            &>li {
                flex-basis: 100%;
                max-width: 100%;
                width: 100%;
            }
        }

        .menu__link {
            color: #333;
            color: $clr_blue;
            font-size: 14px;
            padding: 13px 12px;
            max-width: 100vw;
            border-bottom: 1px solid #ebebeb;

            &_background_gray:after {
                display: none;
            }
        }

        .menu__main-cats {
            height: auto;
            min-height: auto;

            &-inner {
                &>.menu__hidden-title_color_gray {
                    padding: 30px 12px 10px 12px;
                }
            }
        }

        &__header {
            padding: 5px 0;
            border-bottom: 1px solid #ebebeb;
            background: #fff;

            .btn {
                font-size: 20px;
                color: $clr_blue;
                font-weight: 500;

                i {
                    display: inline-block;
                    transform: rotate(90deg);
                    margin-left: -8px;
                }
            }
        }
    }

    .menu-categories__item_state_hovered {
        z-index: 1;
    }
}


.menu-categories__item.with-border {


    .menu-categories__link {
        margin-top: 5px;
        height: 50px;
        display: flex;
        align-items: center;
        border-top: 1px solid #4e59883b;
        border-bottom: 1px solid #4e59883b;

        @media screen and (max-width : 991px) {
            border-top: none;
        }

    }

    // padding-top: 6px;
    // padding-bottom: 6px;

    &:last-child .menu-categories__link {
        border-bottom: 0;
    }


    .menu-categories__icon {
        width: 50px;
        min-width: 50px;
    }

    img {
        width: 100%;
        object-fit: contain;
    }
}

.menu-categories__item.with-border+.menu-categories__item.with-border .menu-categories__link {
    border-top: 0;
}