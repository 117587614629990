@import "../../../default.scss";
.home {
  .products-dropdown {
    min-height: 411px;
  }
}
.homepage-catalog {
  padding-top: 11px;
  height: auto;
  // max-height: 780px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  &::after {
    display: block;
    display: none;
    content: "";
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 112px;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 1%,
      rgba(255, 255, 255, 1) 100%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  &.hide {
    display: none;
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
}
.catalog-banner {
  display: flex;
  justify-content: space-between;
  .homepage-catalog {
    min-width: 300px;
    .menu-categories {
      animation: 0.7s both FadeInBlock;
      &__icon {
        animation: 0.7s both FadeInBlock;
        img,
        svg {
        }
      }
      &__name {
        animation: 0.7s both FadeInBlock;
      }
    }
  }
  .banners {
    width: 100%;
    padding: 30px 0 0 30px;
    border-left: 1px solid #d6d9e3;
    border-radius: 8px;
    &-plash {
      margin-bottom: 1rem;
      &_item {
        gap: 10px;
        background: transparentize(#d6d9e3, 0.75);
        padding: 8px;
        width: 24%;
        margin: 0.3rem 0.5%;
        border-radius: 8px;
        overflow: hidden;
        color: inherit;
        text-decoration: none;
        transition: 0.3s;
        @media screen and (max-width: 1349px) {
          width: 49%;
        }
        @media screen and (max-width: 600px) {
          width: 99%;
        }
        img {
          width: 3rem;
          height: 3rem;
          min-width: 3rem;
          border-radius: 8px;
        }
        p {
          margin: 0 10px 0 0;
        }
        .leftime {
          background: #e5f3f0;
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: center;
          justify-content: center;
          padding: 0.3rem 0.5rem;
          font-weight: 500;
          font-size: 0.7rem;
          margin: -8px;
          .h3 {
            color: $clr_green;
            margin: 0;
          }
        }
        &:hover {
          background: transparentize(#d6d9e3, 0.45);
        }
      }
    }
  }
  .banners-bottom {
    display: flex;
    margin: 0 -5px;
    &__item {
      width: 50%;
      padding: 5px;
      box-sizing: border-box;
      img {
        width: 100%;
        // height: 175px;
        object-fit: cover;
      }
    }
    @media screen and (max-width: 991px) {
      //   display: none;
      display: block;
      &__item {
        margin-bottom: 0.4rem;
        display: block;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .banners {
      width: 100%;
      padding: 0;
      border: none;
      display: flex;
      flex-wrap: wrap;
      &-plash {
        order: 1;
      }
    }
  }
}
#sliderMain {
  padding-bottom: 50px;
  max-width: 1270px;
  width: 100%;
  // min-height: 420px;
  overflow: hidden;
  @media screen and (max-width: 991px) {
    // min-height: 100%;
    .carousel-indicators {
      margin: 0;
      justify-content: space-between;
      li {
        margin: 0 2px;
      }
    }
  }
  .carousel {
    &-item {
      height: 100%;
      overflow: hidden;
      img {
        width: 100%;
        // height: 420px;
        object-fit: cover;
        @media screen and (max-width: 991px) {
          height: auto;
        }
      }
    }
    &-indicators {
      bottom: 20px;
      margin-bottom: 0;
      justify-content: center;
    }
  }
}
