@import "../../../default.scss";


.product-main__gallery{
    z-index: 1;
    position: relative;
    .image-gallery-thumbnail .image-gallery-thumbnail-image{
        height: 58px;
        width: 58px;
        object-fit: contain;
    }
    .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image{
        
        height: 570px;   
        width: 100%;
        object-fit: contain;      
        @media screen and (max-width : 767px){
            height: 270px;
        }
    }
    .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, 
    .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail{
        background: #FFFFFF;
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 4px;
        &.active{
            border-color:#BF2362;
            
        }
    }
    .image-gallery-thumbnail,
    .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right{
        width: 65px;
        height: 65px;
        text-align: center;
    }
}