@use 'sass:math';
.w {
  @for $i from 1 through 99 {
    $w: math.div(1rem * $i, 10);
    &#{$i} {
      width: $w;
      min-width: $w;
    }
  }
  @for $i from 10 through 30 {
    $w: 1rem * $i;
    &#{$i*10} {
      width: $w;
      min-width: $w;
    }
  }
  &320 {
    max-width: 32rem;
    width: 100%;
  }
  &25p {
    width: 25%;
    max-width: initial;
  }
  &33p {
    width: 33.3333%;
    max-width: initial;
  }
  &50p {
    width: 50%;
    max-width: initial;
  }
  &100p {
    width: 100%;
    max-width: initial;
  }
}
.h {
  @for $i from 10 through 72 {
    $h: math.div(1rem * $i, 10);
    &#{$i} {
      height: $h;
    }
  }
}
.wh {
  @for $i from 1 through 99 {
    $w: math.div(1rem * $i, 10);
    &#{$i} {
      width: $w;
      min-width: $w;
      height: $w;
    }
  }
}
