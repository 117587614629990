@import '../../../default.scss';
@import './journal.scss';
.promo {
  &-newspaper-page {
    .react-transform-wrapper, .btn {
      box-shadow: 0 10px 20px #0000006e;
    }
  }
  &-inner {
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;
    .catalog-filter {
      align-self: flex-start;
    }
  }
  &-crumbs {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    &_link {
      margin-bottom: 0.4rem;
      color: $clr_black;
      .i-arrow {
        font-size: 0.8rem;
        margin-top: 0.1rem;
      }
      &:first-child {
        color: var(--primary);
        font-weight: 500;
      }
      &:hover {
        color: $clr_orange;
      }
    }
    &_last {
      background: $clr_blueLighten;
      background: transparentize($clr_primary, .4);
      background: transparentize($clr_blueLighten, .8);
      border-radius: .3rem;
      padding: 0.2rem .5rem;
      margin: .2rem 0;
      line-height: 150%;
      display: inline-block;
      // color: $clr_white;
    }
  }
  &-aside {
    max-width: 270px;
    width: 270px;
    margin-right: 1rem;
    h6 {
      font-weight: 600;
    }
    &--list {
      margin: 0 0 2rem 0;
      &_header {
        padding: 1rem;
        h6 {
          margin: 0;
        }
      }
      &_body {
        padding: 0 1rem;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 20rem;
      }
      ul {
        list-style: none;
        padding-left: 0;
        font-weight: 500;
        a {
          padding: 0.35rem 0;
          display: flex;
        }
      }
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -1rem;
      padding: .5rem 0;
      position: sticky;
      top: -1rem;
      background: #fff;
      z-index: 1;
      display: none;
      h4 {
        margin-bottom: 0;
      }
    }
  }
  &-block {
    position: relative;
    padding-bottom: 2.4rem;
    margin-bottom: 2.4rem;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 1rem;
      right: 1rem;
      height: 3px;
      background: rgb(13 29 101 / 18%);
      border-radius: 100%;
    }
  }
  &-categories {
    .form-control {
      margin: 1rem 0;
    }
  }
  &-content {
    flex-grow: 1;
    width: 100%;
    // overflow: hidden;
    &_header {
      padding: .5rem 2rem;
    }
  }
  &-newspapers {
    &_item {
      img {
        height: 280px;
        object-fit: cover;
      }
    }
  }
  &-whitelist {
    &_item {
      margin-bottom: 1rem;
      border-radius: .3rem;
      overflow: hidden;
      .flex {
        align-items: unset;
        min-height: 86px;
      }
      .info {
        font-size: 0.8rem;
        font-weight: 500;
        padding: 1rem;
        .date {
          margin-bottom: 4px;
        }
      }
      .leftime {
        background: transparentize($clr_green, .9);
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        padding: 0.5rem;
        font-weight: 500;
        font-size: 0.9rem;
        .h3 {
          color: $clr_green;
          margin: 0;
        }
      }
    }
  }
  .btn_promo-aside {
    display: none;
    width: 100%;
  }
  .catalog-filter {
    margin-right: 1rem;
    &__wrap {
      width: 270px !important;
      max-width: 270px !important;
    }
  }
}
.btn_close {
  span {
    font-size: 40px;
    line-height: 1;
  }
}
.menu-promo {
  width: 270px;
  margin-left: -20px;
  font-size: 0.9rem;
  .pr {
    padding-left: 0;
    width: 270px;
    height: 30px;
    &:after {
      display: none;
    }
  }
  .menu-categories__item {
    img {
      margin-right: 4px;
    }
    .list {
      overflow: hidden;
      max-height: 109px;
      transition: .3s;
    }
    &.open {
      .list {
        overflow: hidden;
        max-height: 700px;
      }
    }

    &:hover {
      &::after {
        color: #F26522;
      }
    }
  }
  .menu-categories__link {
    border-top: none;
    font-size: 0.9rem;
    .menu-categories__icon {
      width: 45px;
      margin-right: 4px;
      img {
        margin-left: auto;
      }
    }
  }
  &__sub {
    list-style: none;
    padding-left: 53px;
    &-link {
      color: #333;
      padding-left: 8px;
      padding-top: 4px;
      padding-bottom: 4px;
      display: block;
      &:hover {
        color: #F26522;
      }
    }
  }
  &.sub {
    width: 250px;
    margin-left: 0px;
    .menu-promo__sub>li {
      margin-bottom: 1rem;
    }
    .menu-promo__sub-link {
      font-weight: 600;
      font-size: 0.9rem;
      margin-bottom: 0.2rem;
      display: block;
    }
    .menu__link {
      color: black;
      &:hover {
        color: $clr_orange;
      }
    }
    .btn-link {
      font-size: inherit;
      padding: 0;
      margin: 0;
      width: 100%;
      text-align: left;
    }
  }
  .btn_more {
    padding-left: 30px;
    font-weight: 500;
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    box-shadow: none;
    position: relative;
    &:before{
      // content: "";
      // display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: -20px;
      height: 20px;
      background: linear-gradient(to top, white, transparent);
    }
  }
}
.timetable {
  h4 {
    margin-bottom: 1rem;
  }
  p {
    color: $clr_blueLighten;
    font-weight: 500;
    font-size: 1.3rem;
    margin: .5rem 0;
  }
  a {
    display: block;
    margin-top: 1.5rem;
    text-transform: initial;
  }
}
@media screen and (max-width: 991px) {
  .promo {
    &-aside {
      position: fixed;
      left: -100%;
      width: 100%;
      max-width: 100%;
      top: 0;
      bottom: 0;
      overflow-y: auto;
      transition: .3s;
      background: #fff;
      z-index: 1003;
      padding: 1rem;
      &.open {
        left: 0;
      }
      &_header {
        display: flex;
      }
    }
    &-content {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
      &_header {
        padding-left: 1rem;
        padding-right: 1rem;
        h3 {
          font-size: 1.5rem;
        }
        &-qty {
          display: block;
          margin: 1rem 0 0 !important;
        }
      }
    }
    &-product {
      padding-left: 0;
    }
    .btn_promo-aside {
      display: flex;
      justify-content: center;
      font-size: 1.1rem;
    }
    .catalog-filter {
      &__promo {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
      &__wrap {
        width: 290px !important;
        max-width: 290px !important;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .promo {
    &-whitelist {
      &_item {
        .flex {
          flex-wrap: wrap;
        }
        .leftime {
          width: 100%;
        }
      }
    }
  }
}
