/* autoprefixer grid: autoplace */
@import "../../../default.scss";

.page-category {
    h1 {
        font-size: 1.75rem;
    }
}

.categories {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;

    &__item {
        width: calc(16.6667% - 1px);

        @media screen and (max-width : 950px) {
            width: calc(25% - 1px);
        }

        @media screen and (max-width : 767px) {
            width: calc(33.3333% - 2px);
        }

        @media screen and (max-width : 500px) {
            width: calc(50% - 3px);
        }

        //height: 260px;
        height: 235px;
        position: relative;
        overflow: hidden;

        &-wrap {
            box-shadow: 0px 1px 3px rgba(10, 26, 92, 0.25);
            display: flex;
            flex-direction: column;
            align-items: center;
            background: $clr_white;
            //width: 174px;
            margin: 6px 6px;
            position: relative;

            //transition: $transition;
            &:before {
                position: absolute;
                left: -6px;
                top: -6px;
                //z-index: -1;
                //display: none;
                width: 100%;
                height: 100%;
                padding: 6px;
                -webkit-box-shadow: 0 2px 16px rgba(0, 0, 0, .24);
                box-shadow: 0 2px 16px rgba(0, 0, 0, .24);
                box-shadow: 0px 1px 13px rgba(10, 26, 92, 0.21);
                background-color: #fff;
                opacity: 0;
                content: "";
            }
        }

        .category-link {
            position: relative;
            z-index: 1;
            padding: 15px 15px 0;
            display: block;
            text-align: center;
            width: 100%;
            color: $clr_blueLighten;
            animation: .7s both FadeInBlock;

            &:hover {
                color: $clr_primary;
            }
        }

        .category-image {
            position: relative;
            z-index: 1;

            img {
                width: 100%;
                height: 150px;
                min-height: 150px;
                object-fit: contain;
            }
        }

        .category-name {
            position: relative;
            z-index: 1;
            margin: 10px 0 7px;
            font-weight: 500;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 40px;
            width: 100%;
        }

        .category-child {
            //position: relative;
            z-index: 1;
            display: none;
            padding-bottom: 15px;

            &__wrap {
                display: flex;
                flex-direction: column;
            }

            &__thumb {
                position: absolute;
                top: 1rem;
                left: 1rem;
                height: 160px;
                background-color: $clr_white;
                display: flex;
                align-items: center;
                display: none;

                img {
                    width: 100%;
                    object-fit: contain;
                }
            }

            &__item {
                font-size: 12px;
                color: $clr_blue;
                line-height: 13px;
                padding: 3px 15px;

                &:hover {
                    color: $clr_primary;

                    .category-child__thumb {
                        display: block;
                    }
                }
            }
        }

        &:hover {
            z-index: 20;
            overflow: visible;


            .categories__item-wrap {
                //width: 186px;
                margin: 6px 0;
                padding: 0 6px;
            }

            .category-child {
                display: block;
            }

            .categories__item-wrap:before {
                display: block;
                z-index: 1;
                opacity: 1;
                box-sizing: content-box;
            }
        }
    }
}

.catalog {



    &__wrap {
        display: flex;
        align-items: flex-start;
    }

    &-filter {
        width: 250px;
        display: inline-block;
        align-self: flex-end;
        transition: 300ms ease-in-out;
    }

    &-list {
        display: inline-block;
        // float: right;
        width: calc(100% - 250px);

        &__wrap {
            display: flex;
            flex-wrap: wrap;
        }

        &__empty {
            width: 100%;
            padding: 4rem 0.5rem 3rem;
            text-align: center;
            font-weight: 500;
            color: #0b1d64;
        }

        &__item {
            width: 20%;
            max-width: 100vh;
            // max-width: calc(100vh);
            // max-height: 352px;
            // height: 330px;
            // height: 340px;
            // height: 335px;
            height: 345px;
            // height: 305px;
            overflow: hidden;

            &.with-variants {
                // height: 428px;
                height: 387px;
                // height: 295px;
            }

            .logo-loader {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 80%;
                    margin: auto;
                }
            }

            @media screen and (max-width : 1199px) {
                width: 25%;
            }

            @media screen and (max-width : 767px) {
                width: 33.333%;
            }

            @media screen and (max-width : 550px) {
                width: 50%;
            }

            .products {
                &-image {
                    // min-height: 136px;
                    // min-height: 220px;
                    max-width: 100%;
                    // height: 174px;
                    // position: absolute;

                    // top: 0;
                    // left: -10px;
                    // right: -10px;

                    &.with-variants {
                        // min-height: 258px !important;
                        // min-height: 174px !important;

                        // img {
                        //     height: 100%;
                        //     min-height: 136px;
                        //     max-height: 170px;
                        // }
                    }

                    img {
                        // height: 178px;
                        // height: 174px;
                        // border: 1px solid #ddd;
                    }


                    .slick-dots {


                        bottom: -5px;
                        pointer-events: none;
                        cursor: default;

                        li {
                            &.slick-active button:before {
                                font-size: 8px !important;
                            }

                            margin: 0;
                            width: 14px;

                            &.slick-active button:before {
                                color: #bf2362;
                            }

                            button {
                                width: 10px;


                            }
                        }
                    }

                    .slick-arrow {
                        opacity: 0;
                    }

                    .slick-next:before,
                    .slick-prev:before {
                        color: #bf2362;
                        font-size: 20px
                    }

                    .slick-prev {
                        top: calc(50% + 1px);
                        left: -5px;
                        z-index: 10;
                    }

                    .slick-next {
                        right: -5px;
                        top: calc(50% + 1px);
                        z-index: 10;
                    }
                }

                &-variants-count {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: rgba(233, 233, 233, 0.871);
                    padding: 4px 8px;
                    border-radius: 4px;
                    color: #3b3b3b;
                    font-weight: 500;
                    font-size: 10px;
                    text-align: center;

                    transition: 250ms ease;

                    cursor: default;

                    &:hover {
                        background: rgb(210, 210, 210);
                    }

                }

                &-image,
                &-colors,
                &-title,
                &-reviews,
                &-price,
                &-stock,
                &__footer {
                    position: relative;
                    z-index: 1;
                }

                &__wrap {
                    background: $clr_white;

                    &:before {
                        position: absolute;
                        left: -10px;
                        top: -10px;
                        width: calc(100% + 20px);
                        height: calc(100% + 20px);
                        padding: 10px;
                        -webkit-box-shadow: 0 2px 16px rgba(0, 0, 0, .24);
                        box-shadow: 0 2px 16px rgba(0, 0, 0, .24);
                        box-shadow: 0px 1px 13px rgba(10, 26, 92, 0.21);
                        background-color: #fff;
                        opacity: 0;
                        content: "";
                    }
                }

                &-cart-sm {
                    .add-to-cart {
                        color: $clr_primary;
                        position: relative;
                        font-size: 19px;
                        padding: 1px 5px 1px 1px;

                        &:hover {
                            background: rgba(191, 35, 98, 0.1);
                        }

                        &.added {
                            &:after {
                                content: "\e804";
                                font-family: "fontello";
                                font-style: normal;
                                font-weight: normal;
                                speak: none;
                                display: inline;
                                text-decoration: inherit;
                                width: 1em;
                                margin-right: .4em;
                                text-align: center;
                                -webkit-font-feature-settings: normal;
                                -moz-font-feature-settings: normal;
                                font-feature-settings: normal;
                                font-variant: normal;
                                text-transform: none;
                                line-height: 1em;
                                margin-left: .1em;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                position: absolute;
                                top: 4px;
                                left: 12px;
                                font-size: 10px;
                            }
                        }
                    }
                }

                &__footer {
                    // display: none;
                    opacity: 0;

                    .cart-btn {
                        padding: 1rem 0;
                        width: 100%;

                        .btn {
                            width: 100%;
                            font-weight: 500;

                            &.added {
                                background: rgba(0, 135, 104, 0.1);
                                border: 1px solid #008768;
                                color: #008768;
                                font-weight: 500;

                                i {
                                    font-size: 18px;
                                    margin-right: 3px;
                                }

                                &:focus {
                                    box-shadow: 0 0 0 0.1rem rgba(0, 133, 102, 0.4);
                                }
                            }
                        }
                    }

                    .short-bages {
                        &__wrap {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 0;
                            list-style: none;

                            li {
                                margin-left: 10px;

                                img {
                                    height: 40px;
                                }
                            }
                        }
                    }

                    .short-desc {
                        font-size: 10px;

                        p {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            // &.hover {
            //     &:hover {

            //         overflow: hidden !important;

            //         .products__wrap {
            //             height: 100%;

            //             &:before {
            //                 opacity: 0;
            //             }
            //         }
            //     }
            // }

        }

        &__navigation {
            .loadMore {
                button {
                    width: 100%;
                    border-radius: 0;
                    background: #e7e8ef;
                    border-color: transparent;
                    color: black;
                    font-weight: 500;
                    font-size: 16px;
                    padding: 1.1rem;
                    transition: $transition;
                    outline: none;

                    &:hover {
                        background: transparentize($clr_blue, 0.85);
                    }
                }
            }

            .pagination {
                padding: 1rem;
                border-bottom: 1px solid transparentize($clr_blue, .9);

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }

                &__wrap {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }

                &__item {
                    padding: 1rem;
                    white-space: nowrap;

                    a {
                        color: rgba(0, 0, 0, 0.4);
                        text-decoration: none;

                        &.disabled {
                            opacity: .25;
                            pointer-events: none;
                        }
                    }

                    .link {
                        &:hover {
                            color: transparentize($clr_blue, 0.2)
                        }
                    }

                    i.prev {
                        display: block;
                        transform: rotate(90deg);
                    }

                    i.next {
                        display: block;
                        transform: rotate(-90deg);
                    }

                    &.active {
                        a {
                            color: $clr_primary;
                        }
                    }
                }

                .pag_arrows {
                    font-size: 13px;
                }

                @media screen and (max-width: 767px) {
                    &__wrap {
                        flex-wrap: wrap;
                    }

                    .pag_list {
                        width: 100%;
                    }

                    .pag_arrows {
                        order: 1;
                    }

                    &__item {
                        &.pag_arrows {
                            order: 1;
                            // width: 25%;
                        }
                    }
                }
            }
        }
    }

    &__settings {
        margin-bottom: 1.5rem;

        .filter-mobile {
            margin: 5px 1rem 5px 0;
            display: none;



            @media screen and (max-width : 991px) {
                display: block;
            }

            .btn-primary {
                font-size: 13px;

                &:disabled {
                    border: none;
                }
            }

            @media screen and (max-width : 500px) {
                margin-right: 0;
                width: 100%;

                .btn-primary {
                    width: 100%;
                }
            }
        }

        .filter-selection {
            display: flex;
            align-items: flex-start;
            animation: .7s both FadeInBlock;

            @media screen and (max-width : 991px) {
                align-items: center;
            }

            @media screen and (max-width : 500px) {
                width: 49%;
            }

            p {
                font-size: 14px;
                margin: 0;
                padding: 10px 0;
                white-space: nowrap;

                @media screen and (max-width : 600px) {
                    display: none;
                }
            }

            &__list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin: 0 1rem;

                @media (max-width: 991px) {
                    display: none;
                }
            }

            &__item {
                margin: 5px 0;
            }

            &__btn {
                font-size: 13px;
                padding: 5px 15px;
                margin: 0 .5rem;
                border: 1px solid transparentize($clr_red, .3);
                border-radius: 100px;
                color: $clr_black;
                background: transparent;
                transition: $transition;

                &:hover {
                    background: transparentize($clr_red, 0.5);
                }
            }

            &__link {
                font-size: 13px;
                padding: 5px 10px 5px 15px;
                margin: 0 .5rem;
                border: 1px solid transparentize($clr_blue, 0.6);
                border-radius: 100px;
                color: $clr_black;
                text-decoration: none;

                &:after {
                    content: "\e805";
                    font-family: "fontello";
                    font-style: normal;
                    font-weight: normal;
                    display: inline-block;
                    text-decoration: inherit;
                    width: 1em;
                    font-size: 12px;
                    color: $clr_red;
                    margin-right: .2em;
                    text-align: center;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1em;
                    margin-left: 8px;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }

                &:hover {
                    background: transparentize($clr_blue, 0.9);
                }
            }
        }
    }

    &-view {
        @media screen and (max-width : 500px) {
            width: 49%;
        }
    }
}

.cat-desc {
    font-size: 15px;
    line-height: 130%;
    padding: 3rem 0 0;

    &__wrap {
        iframe {
            max-width: 100%;
        }
    }
}

.promo .cat-desc {
    padding-left: 10px;
}

.char-list {

    position: relative;
    z-index: 3;

    &.product {
        .char-list-container {
            padding: 14px 8px 8px 8px;
            border-radius: 4px;
            border: 1px solid #ebe9e987;
            box-shadow: 0 0 24px 10px #fbfbfb;
            margin-bottom: 1rem;
        }
    }


    .badge {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        // top: -5px;
        right: -4px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.catalog .slick-slide {
        padding: 0 2px;
    }

    .slick-list {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .slick-track {
        margin-right: 0;
        margin-left: 0;
    }


    .slick-next,
    .slick-prev {
        // display: none !important;
        opacity: 0;
    }

    .slick-next:before,
    .slick-prev:before {
        color: #bf2362;
        font-size: 15px
    }

    .slick-prev {
        top: calc(50% + 1px);
        left: -15px;
    }

    .slick-next {
        right: -15px;
        top: calc(50% + 1px);
    }

    .modal-link {
        display: inline-block;
        margin-top: 12px;

        color: #3658f0;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;

        transition: 250ms ease;

        &:hover {
            color: #bf2362;
        }

        * {
            margin: 0;
        }
    }

    &__blocks {

        // mobile
        // .type-image-col {
        //     width: calc((100% - 12px) / 4) !important;
        // }

        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 4px;
        // height: auto;
        // max-height: 53px;
        overflow: hidden;

        padding: 5px 1px 1px;

        // &.overflow-visible {
        //     overflow: visible !important;
        // }

        &.unshow {
            // max-height: none !important;
            // overflow: visible;

            // display: inline-block;

            .char-list__blocks__item {
                // margin: 0 3px 3px 0;
            }

            .char-list__blocks__item.is_show_more {
                display: none;
            }
        }

        &.show {
            // justify-content: space-between;
            max-height: none !important;
            overflow: visible;

            .char-list__blocks__item {
                display: flex !important;
            }
        }

        &.grow-item {
            justify-content: space-between;

            .char-list__blocks__item {
                flex-grow: 1;
            }
        }

        &.linear-items {
            justify-content: flex-start;

            .char-list__blocks__item {
                flex-grow: 0;
            }
        }

        // .is_show_more_mode_true {

        //     .char-list__blocks__item {
        //         display: flex !important;
        //     }
        // }

        &__item {
            // display: none;

            // flex-grow: 1;
            position: relative;
            align-items: center;
            justify-content: center;
            // width: 30px;
            // height: 30px;
            border-radius: 4px;
            border: 1px solid #acacac;
            text-align: center;
            padding: 2px 6px;
            font-size: 12px;
            font-weight: 500;

            transition: 250ms ease all;

            &.show_more_btn {
                max-width: 33% !important;
                // flex-grow: 0 !important;
                color: #bf2362;
                border: 1px solid #bf2362 !important;
                // max-width: 160px !important;
                margin-left: auto;
                white-space: nowrap;

            }

            &.is_show_more {
                display: flex;
                flex-grow: 1;
                // background: #000;
            }

            &:focus {
                box-shadow: none;
            }

            &:hover:not(.show_more_btn):not(.unavailable) {
                border: 1px solid #bf2362 !important;
                // background: rgba(191, 35, 97, 0.063);
            }


            &:active {
                border: 1px solid #bf2362 !important;
            }

            &:disabled {
                opacity: 1 !important;
            }

            &.selected:not(.show_more_btn) {
                border: 1px solid #bf2362 !important;
                background: rgba(191, 35, 97, 0.063);
            }

            &.default {
                color: #1030af;
            }

            &.unavailable:not(.show_more_btn) {
                color: #acacac;
                background-color: transparent;
                border-color: #acacac;
                position: relative;

                background-image: linear-gradient(to top right, transparent 45%, #a1a1a1, transparent 48%);
                background-repeat: no-repeat;

                &:hover {
                    border-color: #434343 !important;
                    // background-image: linear-gradient(to top right, transparent 45%, #434343, transparent 48%);
                }
            }
        }


        &-btn-color {

            &.type-color-col {
                width: auto !important;
                padding-left: 4px;
                padding-right: 4px;
            }

            &.show {
                position: relative !important;
                bottom: auto;
                right: auto;
                order: 10000000 !important;

                &::before {
                    display: none;
                }
            }

            display: flex;
            align-items: center;
            justify-content: center;
            // flex-grow: 1;
            // max-width: 90px;
            // min-height: 1.7em;  

            font-weight: 500;
            font-size: 12px;
            text-align: center;
            line-height: 0;

            background: #fff !important;
            border-radius: 4px;
            border: 1px solid #bf2362;
            color: #bf2362;

            // border: 1px solid #acacac;
            transition: 250ms ease color;

            cursor: pointer;

            &:hover {
                color: #681236;
            }

            // &::before {
            //     position: absolute;
            //     top: 0;
            //     left: -11px;
            //     content: '';
            //     width: 10px;
            //     height: 100%;
            //     // backdrop-filter: blur(1px);
            //     background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.556) 30%, rgb(255, 255, 255));
            // }

        }

    }

    &__blocksBtn {


        display: flex;
        align-items: center;
        justify-content: center;
        color: #bf2362;
        border: 1px solid #bf2362 !important;
        border-radius: 4px;
        max-width: 90px;
        min-height: 24px;
        margin-left: auto;
        margin-top: 4px;
        font-weight: 500;
        font-size: 12px;
        text-align: center;

        transition: 250ms ease all;

        cursor: pointer;

        &:hover {
            color: #681236;
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 30px;
        // height: 30px;
        border-radius: 4px;
        border: 1px solid #acacac;
        text-align: center;
        padding: 2px 6px;
        font-size: 12px;
        font-weight: 500;

        transition: 250ms ease;

        &:disabled {
            opacity: 1 !important;
        }

        &:focus {
            box-shadow: none;
        }

        &.selected {
            border: 1px solid #bf2362 !important;
            background: rgba(191, 35, 97, 0.063);
        }

        &.default {
            color: #1030af;
        }

        &.unavailable {
            color: #acacac;
            background-color: transparent;
            border-color: #acacac;
            position: relative;

            background-image: linear-gradient(to top right, transparent 45%, #a1a1a1, transparent 48%);
            background-repeat: no-repeat;

            &:hover {
                border-color: #434343 !important;
                // background-image: linear-gradient(to top right, transparent 45%, #434343, transparent 48%);
            }
        }

    }

    &__item-color {
        position: relative;

        transition: 250ms ease;

        &:disabled {
            opacity: 1 !important;
        }

        &:hover:not(.show_more_btn):not(.unavailable):not(.selected) {
            border: 1px solid #bf2362 !important;
        }


        &.unavailable:hover {
            border-color: #434343 !important;
            // &::before { 
            //     background-image: linear-gradient(to top right, transparent 48%, #434343, transparent 51%);
            // }
        }

        &.unavailable::before {
            content: '';
            position: absolute;

            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;

            z-index: 2;


            background-image: linear-gradient(to top right, transparent 47%, #a1a1a1, transparent 52%);
            background-repeat: no-repeat;
        }

        &.type-color.selected {
            // position: relative;
            // z-index: 1;
            // transform: translateZ(-1px);
            // perspective: 10px;
            transform: scale(0.75);

            .badge {
                transform: translate(5px, -5px) scale(1.25);
            }

            &::before {
                content: '';
                z-index: -1;
                position: absolute;
                top: -20%;
                left: -20%;
                width: 140%;
                height: 140%;
                border-radius: 6px;
                border: 1px solid #bf2362;
                // background: #ffbfd8;
                box-shadow: inset 0px 0px 4px 4px #ffd7d042;
            }

            // &::after {
            //     content: '';
            //     z-index: 1;
            //     position: absolute;
            //     top: 10%;
            //     left: 10%;
            //     width: 80%;
            //     height: 80%;
            //     border-radius: 6px; 
            //     background: inherit 
            // }
        }

        &.type-image.selected {
            border: 1px solid #bf2362 !important;
            // background-size: 80% !important;
        }
    }

    &__title {
        // display: flex;
        // align-items: flex-start;

        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin-bottom: 4px;
        color: #5c5c5c;

        img,
        .color-preview {
            display: inline-block;
            width: 1.2em;
            height: 1.2em;
            margin: 0 2px 0 5px;
        }

        .color-preview {
            border-radius: 2px;
            margin: 0 2px -3px 5px;
        }

        span {
            color: #4b4b4b;
            // font-weight:600;
            // font-family: 'Arial';
            font-weight: 600;
        }

        .modal-btn {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
            padding: 4px;
            width: 16px;
            height: 16px;
            border-radius: 20px;
            background: #bf2362;
            color: #fff;
            font-size: 10px;
            font-weight: 700;
            cursor: pointer;
            transition: 300ms ease;

            &:hover {
                transform: scale(1.2);
            }
        }
    }
}

.char-list.catalog-desktop {

    .char-list__blocks,
    .char-list__title {
        margin-left: -2px;
        margin-right: -2px;
    }

    .char-list__title {
        margin-bottom: 0;
    }

    .char-list-container {
        margin-bottom: 12px;
    }

    .slick-slide {
        padding: 0 2px;
    }

    .char-list__blocks__item.show_more_btn {
        max-width: 85px !important;
    }
}

.no-variant-but-height {
    min-height: 42px;
}

.product-main__info {
    .char-list {
        margin-bottom: 2rem;


        &__title {
            font-size: 14px;
            // margin-bottom: 8px;
        }

        .type-image {}

        &__item {
            font-size: 16px;
            // margin-top: 5px;
        }

        &__item-color {
            // margin-top: 5px;
        }

        &__blocks {
            // max-height: 68px;

            &-btn {
                // font-size: 16px;
            }
        }

    }
}

.skeleton-container {
    position: relative;

    &.min-h {
        min-height: 1200px;
    }
}

.skeleton {
    &-card {
        position: relative;
        width: 20%;
        float: left;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        overflow: hidden;

        padding: 1px;

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            // background: rgb(163, 163, 163);


            // animation: skeletonBlur 2000ms cubic-bezier(.6, .22, .26, .93) alternate infinite;
            // animation-fill-mode: backwards;

        }
    }

    &__wrap {
        position: absolute;
        top: 65px;
        bottom: 0;
        left: 262px;
        right: 0;
        z-index: 1000;

        .catalog__wrap {
            height: 100%;
            min-height: 100vh;
            overflow: hidden;
        }

        .catalog {

            &-filter__wrap {
                min-width: 262px;
                // backdrop-filter: blur(4px);
                user-select: none;


                // border-radius: 4px;
                // backdrop-filter: blur(10px);
                // background: rgba(70, 70, 70, 0.109);

            }

            &-list {
                width: 100%;
            }
        }
    }
}

.tile-filter {
    display: flex;
    flex-wrap: wrap;
    padding: 1px 0;

    input {
        opacity: 0.5;

        &:checked+label {
            transform: scale(0.85);
            // border: 1px solid #fff !important;
            border-color: #fff !important;
            outline: 1px solid #bf2362 !important;
            outline-offset: 2px;

            // &::after {
            //     content: '✔';
            //     color: #fff;
            // }
        }
    }

    &__label {

        &::before {
            display: none !important;
        }

        margin: 1px 4px 4px 0 !important;

        display: flex;
        align-items: center !important;
        justify-content: center !important;
        min-width: 20px;
        height: 20px;
        // max-height: 20px;
        border-radius: 4px;
        border: 2px solid #acacac;
        text-align: center;
        padding: 4px !important;
        font-size: 12px;
        font-weight: 500;

        &.selected {
            transform: scale(0.85);
            border-color: #fff !important;
            outline: 1px solid #bf2362 !important;
            outline-offset: 2px;

            // &::after {
            //     content: '✔';
            //     color: #fff;
            // }
        }

    }
}

.btn-to-card {
    border: 1px solid #bf2362 !important;
    color: #bf2362;
    display: flex;
    align-items: center;
    line-height: 0;

    min-height: 35px;

    &:hover {
        background-color: #bf2362;
        color: rgb(237, 234, 234);
    }
}

.btn-in-card {
    border: 1px solid #28a745 !important;
    color: #28a745;

    display: flex;
    align-items: center;
    line-height: 0;

    min-height: 35px;

    &:hover {
        background-color: #28a745;
        color: rgb(237, 234, 234);
    }
}

.mobile-char {

    .char-list {
        &__title {
            margin-bottom: 0;
        }

        &__item {
            font-size: 11px;
        }


        &-container {
            margin-bottom: 16px;
        }
    }

    &-btn-close {
        padding: 6px 10px;
        text-align: end;
        font-size: 25px;
        line-height: 1;
    }

    &-btn-open {

        text-align: center;
        font-size: 10px;
        line-height: 18px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;

        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        box-shadow: 0 0 8px 6px #19191912;

        margin-top: 8px;
        margin-left: -10px;
        margin-right: -10px;

        padding: 7px 10px;

        .selected-text {
            font-weight: 600;
            color: #bf2362;
        }


        .arrow &:active {
            background: #bf2362;
            color: #fff;
        }
    }

    &.open {
        z-index: 100;
        pointer-events: all;

        .mobile-char-container {
            transform: translateY(0);
        }

        background: rgba(0, 0, 0, 0.458);

    }

    pointer-events: none;
    z-index: -1;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: 250ms ease all;

    &-wrap {
        height: 20%;
    }

    &-container {
        transition: 250ms ease;
        transform: translateY(200px);
        background: #fff;
        height: 80%;
        margin-top: auto;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        max-height: 80%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 6px 12px 20px 12px;

        .character-text {
            font-size: 12px;
            margin-top: 10px;

            p {
                font-size: 14px;
                font-weight: 500;
                color: #5c5c5c;
                margin-bottom: 4px;
            }
        }
    }

    &-open_btn {
        font-weight: 500;
        font-size: 14px;
        color: #0b1d64;
    }
}

.z2 {
    z-index: 1002;
    position: relative;
}

.promo-content .catalog-list {
    width: 100%;
}

.promo-text-dark .text-white-50 {
    color: #3b3b3b !important;
}

@media screen and (max-width: 1199px) {
    .skeleton-card {
        width: 25%;
    }
}

.body__animation-layer {
    position: absolute;
    z-index: 0;
    top: 280px;
    left: 0;
    right: 0;

    min-height: 80vh;
    max-height: 80vh;
    display: flex;
    align-items: center;

    // background: #000;
}


@media screen and (max-width: 991px) {

    .products-dropdown__item {

        .slick-next,
        .slick-prev {
            opacity: 1 !important;
            z-index: 1;
        }

    }

    .catalog-list {
        width: 100%;

        .char-list .slick-list {
            margin-left: 6px;
            margin-right: 6px;
        }

        .slick-next,
        .slick-prev {
            opacity: 1 !important;
            z-index: 1;
        }

        .slick-next {
            right: -10px;

        }

        .slick-prev {
            left: -10px;
        }

        &__item {
            // height: 407px;

            .products__footer {
                visibility: hidden;
            }

            // .products-image {
            //     min-height: 192px;
            //     max-height: 192px;
            //     height: 192px;

            //     img {
            //         min-height: 192px;
            //         max-height: 192px;
            //         height: 192px;
            //     }
            // }
        }
    }

    .tile-filter__label {
        margin: 1px 6px 6px 0 !important;
        min-width: 35px;
        height: 35px;

    }


    .skeleton {
        &__wrap {
            left: 0;
        }

        &-card {
            width: 20%;
        }
    }


}

@media screen and (max-width : 767px) {
    .skeleton {
        &-card {
            width: calc(33.3333% - 2px);
        }
    }
}

@media screen and (max-width : 500px) {
    .skeleton {
        &-card {
            width: calc(50% - 3px);
        }
    }
}


@media screen and (min-width: 992px) {
    .catalog-filter {
        position: sticky;
        bottom: 0
    }
}

@media screen and (min-width: 992px) {

    .products-dropdown__item {
        .slick-prev {
            left: -12px;
        }

        .slick-next {
            right: -12px;
        }

        &:hover {

            .char-list .slick-next,
            .char-list .slick-prev {
                opacity: 1;
            }
        }
    }

    .catalog-list__item:hover {
        z-index: 20;
        overflow: visible;

        .products-image .slick-arrow,
        .char-list .slick-next,
        .char-list .slick-prev {
            opacity: 1;
        }

        .products__wrap {
            height: auto;
        }

        // .char-list .slick-next,
        // .char-list .slick-prev {
        //     display: block !important;
        // }

        .products__footer {
            display: block;
            background: #fff;
            opacity: 1;
            transition: 250ms ease-in;
        }

        .products__wrap:before {
            // height: calc(100% + 20px);
            display: block;
            z-index: 1;
            opacity: 1;
            // box-sizing: content-box;
        }
    }
}


.modal-dialog.modal-xl {
    max-width: 1100px;
}

.modal-title p {
    margin: 0 !important;
}

// @keyframes skeletonBlur {
//     0% {
//         backdrop-filter: blur(0px);
//     }

//     100% {
//         backdrop-filter: blur(2px);
//     }
// }

// ADAPTIV