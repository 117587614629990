.rot {
  &_l {
    transform: rotate(-90deg)
  }
  &_r {
    transform: rotate(90deg)
  }
  &_180 {
    transform: rotate(180deg)
  }
}
.ov {
  &h {
    overflow: hidden;
  }
  &ya {
    overflow-y: auto;
  }
  &yh {
    overflow-y: hidden;
  }
  &xa {
    overflow-x: auto;
  }
  &xh {
    overflow-x: gidden;
  }
}
