@import "../../../default.scss";

.carousel{
    &-control-next,
    &-control-prev{
        max-width: 100px;
        .arrow-icon{
            display: block;
            width: 33px;
            height: 19px;
            background: url("/img/i-arrow.svg") center center no-repeat;
            background-size: contain;
            transform: rotate(90deg);
            &.next{
                transform: rotate(-90deg);
            }
        }
    }
    &-indicators li {
        height: 13px;
        width: 13px;
        //box-shadow: 0 0 5px rgba(0,0,0,0.2);
        box-sizing: border-box;
        border: 0;
        margin: 0 5px;
        background: transparentize($clr_blue, 0.7);
        &:hover{
            background: transparentize($clr_blue, 0.4);
        }
        &.active{
            background-color: $clr_primary;
        }
    }
}
#sliderCategory{
    padding-bottom: 50px;
    // height: 400px;
    @media screen and (max-width : 991px){
        // height: 260px;
    } 
    .carousel{
        &-item{
            width: 100%;
            height: 100%;
            overflow: hidden;
            // height: 350px;
            @media screen and (max-width : 991px){
                // height: 210px;
            } 
            img{
                // height: 350px;
                width: 100%;
                width: 100%;
                object-fit: contain;
                @media screen and (max-width : 991px){
                    // height: 210px;
                }                
            }
        }
        &-indicators{
            bottom: 20px;
            margin-bottom: 0;
        }
    }

}
#mainSlider{
    .carousel{
        &-item{
            height: 100%;
            overflow: hidden;
            img{
                width: 100%;
                @media screen and (min-width : 1199px){
                    // height: 450px;
                }
                object-fit: cover;                
            }
        }
        &-indicators li{
            display: none;
        }
    }
}
#SliderSecond{
    @media screen and (min-width : 1199px){
        margin-right: -1rem;
    }
    .carousel{
        &-item{
            height: 100%;
            overflow: hidden;
            img{
                width: 100%;
                @media screen and (min-width : 1199px){
                    height: 500px;
                }
                object-fit: cover;                
            }
        }
        &-indicators li{
            display: none;
        }
    }
}
#sliderLast{
    .carousel{
        &-item{
            height: 100%;
            overflow: hidden;
            img{
                width: 100%;
                height: 350px;
                object-fit: cover;                
            }
        }
        &-control-next,
        &-control-prev{
            display: none;
        }
    }
}