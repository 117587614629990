// ? display grid
@use 'sass:math';
.g {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1.6rem;
  @for $i from 1 through 12 {
    &#{$i} {
      grid-template-columns: repeat($i, 1fr);
    }
  }
  @for $i from 1 through 10 {
    $g: 5rem * $i;
    &#{$i*50} {
      grid-template-columns: repeat(auto-fill, minmax($g, 1fr));
    }
  }
  @for $i from 1 through 30 {
    $g: math.div(1rem * $i, 10);
    &ap#{$i} {
      grid-gap: $g;
      gap: $g;
    }
  }
  &-span-6 {
    grid-column: span 6 / span 6
  }
  &-span-2 {
    grid-column: span 2 / span 6
  }
  &-span-3 {
    grid-column: span 3 / span 6
  }
  &-span-4 {
    grid-column: 4 / 6
  }
}
