// ? flex
.f {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &i {
    display: inline-flex;
  }
  &c {
    justify-content: center;
  }
  &s {
    justify-content: flex-start;
  }
  &e {
    justify-content: flex-end;
  }
  &t {
    align-items: flex-start;
  }
  &b {
    align-items: flex-end;
  }
  &w {
    flex-wrap: wrap;
  }
  &g {
    flex-grow: 1;
  }
  &col {
    flex-direction: column;
  }
  .left-side, .right-side {
    position: relative;
  }
}
