.slider-page-container .products-slider {
  margin-bottom: 35px;
}

.blocks-wrapper {
  position: relative;
  margin: 1rem auto;
  /* padding: 0 1rem 1.5rem; */
}
.blocks-wrapper .products-dropdown__btn {
  display: block;
}
.blocks-wrapper .products-dropdown__btn > div {
  background: rgb(255, 255, 255);
}

.blocks-wrapper.without-bg .products-slider__title,
.blocks-wrapper.without-bg .blocks-slider__content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.blocks-wrapper .products__wrap {
  z-index: 3;
}

.blocks-wrapper .products-dropdown__item {
  position: relative;
  background: #fff;
}

.blocks-wrapper__bg-layer,
.blocks-wrapper__animation-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}


.blocks-wrapper .products-slider__title {
  position: relative;
  z-index: 10;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0;
  margin-bottom: 0;
  line-height: 1;
}

.blocks-slider__content {
  position: relative;
  display: flex;
  z-index: 2;
  padding: 1rem 1.5rem;
}

.slick-wrapper {
  position: relative;
  width: 75%;
}

.blocks-slider__content .slick-custom-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: black;
  z-index: 100;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  cursor: pointer;
  transition: 250ms ease background;
}

.blocks-slider__content .slick-custom-btn:hover {
  background: #e8e8e8;
}

.blocks-slider__content .slick-custom-btn.slick-custom-btn__prev {
  left: 10px;
}

.blocks-slider__content .slick-custom-btn.slick-custom-btn__next {
  right: 14px;
}

.blocks-slider__countdown,
.blocks-slider__info {
  position: relative;
  flex-grow: 1;
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
  background-color: rgba(255, 255, 255, 0.5);
  margin-left: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.blocks-slider__info {
  padding-top: 12px;
  padding-bottom: 12px;
}

.blocks-slider-gif {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blocks__countdown-content {
  height: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.blocks__countdown-content .timer {
  display: flex;
  gap: 4px;
  font-size: 20px;
  color: #0a1a5c;
  font-weight: 500;
}

.blocks__countdown-content .timer-block {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
}

.blocks__countdown-content .timer-block>.timer-num {
  font-size: 32px;
  line-height: 1em;
  font-weight: 500;
}

.blocks__countdown-content .timer-block>.timer-text {
  line-height: 1em;
  font-weight: 400;
  font-size: 14px;
}

.blocks__countdown-dates {
  font-weight: 500;
  font-size: 18px;
  color: #0a1a5c;
}

.block-btn {
  cursor: pointer;
}

@media screen and (max-width: 1300px) {
  .slick-wrapper {
    width: 66%;
  }
}

@media screen and (max-width: 800px) {
  .slick-wrapper {
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .slick-wrapper {
    width: 50%;
  }
}

@media screen and (max-width: 576px) {
  .bg-picture-right-mobile {
    background-position: right top;
    background-size: auto !important;
  }
  .slick-wrapper {
    /* display: none; */
    width: 100%;
    margin-top: 8px;
  }

  .blocks-slider__countdown,
  .blocks-slider__info {
    margin-left: 0;
    /* margin: 12px; */
  }

  .product-slider-mobile .products-dropdown__item {
    width: 50% !important;
    /* display: none; */
  }

  .product-slider-mobile .show-all .products-dropdown__item {
    display: block !important;
  }

  .product-slider-mobile .products-dropdown__item:nth-child(1),
  .product-slider-mobile .products-dropdown__item:nth-child(2) {
    display: block;
  }

  .blocks-slider__content {
    flex-direction: column-reverse;
    /* padding-left: 0;
    padding-right: 0; */
  }

  .mobile-products {
    display: flex;
    flex-wrap: wrap;
  }
}