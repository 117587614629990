@import "../../../default.scss";

@media screen and (max-width: 992px) {
    .mt-sm-80 {
        margin-top: 80px !important;
    }
}

.page-product {
    z-index: 2;

    .lightbox-container {
        z-index: 10000;

        >div {
            z-index: 1;
        }
    }

    .product-main__gallery {
        .mobile-gallery {

            .products-labels {
                .label {
                    font-size: 14px;

                    img {
                        height: 30px;
                    }

                    span {
                        padding: 4px 8px;
                    }
                }
            }

            .product-main__gallery-slide {

                overflow: hidden;

                img {

                    width: auto;
                    height: 300px !important;
                    object-fit: contain;
                    margin: 0 auto;
                }

                video {
                    width: 100%;
                    height: 300px !important;
                    margin: 0 auto;
                }
            }

            .slick-prev {
                left: 0;
                z-index: 1;
            }

            .slick-next {
                right: 12px;
                z-index: 1;
            }

            .slick-prev:before,
            .slick-next:before {
                color: #898989;
                font-size: 35px;
            }
        }

        .desktop-gallery {
            .products-labels {
                z-index: 10000;

                .label {
                    font-size: 14px;

                    img {
                        height: 40px;
                    }

                    span {
                        padding: 6px 14px;
                    }
                }
            }

            .desktop-dots-custom {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 8px 0;
                padding: 0;

                list-style: none;

                li {
                    height: 4px;
                    flex-grow: 1;
                    background: #e0e0e0;

                    &.slick-active {
                        background: #a3a3a3;
                    }

                    pointer-events: none;

                    button {
                        display: none;
                    }
                }
            }

            .slick-prev {
                top: 40px;
                left: -35px;
            }

            .slick-next {
                top: 40px;
                right: -20px;
            }

            .slick-slide {
                padding-left: 1px;
                padding-right: 7px;
            }

            .slick-prev:before,
            .slick-next:before {
                color: #898989;
                border-radius: 101%;
                font-size: 30px;
            }

            .thumb {

                &:hover>div {
                    border-color: #bf2362 !important;
                }

                cursor: pointer;

                >div {
                    max-height: 70px;
                    overflow: hidden;
                }

                height: 100%;

                video {
                    width: 100%;
                }

                img {
                    object-fit: contain;
                }

                img,
                video {
                    height: 100%;
                    min-height: 70px;
                }
            }
        }


        &-slide {
            display: flex !important;
            justify-content: center;

            >div {
                width: 100%;
                height: inherit !important;

                >img {
                    height: 100% !important;
                    object-fit: contain;

                }
            }

            video {
                // max-width: 100%;
                // width: auto;
                // height: 100% !important;
                // margin: 0 auto;

                width: 100%;
            }

        }

        // для мобилки
        .slick-thumb {
            position: static !important;
            top: 300px;
            min-height: 100px;
            padding-bottom: 20px;

            display: flex !important;
            flex-wrap: nowrap;
            overflow-x: scroll;

            li {
                // width: calc(10% - 4px);
                min-width: 60px;
                width: 60px;
                height: 60px;
                margin: 10px 2px;

                &.slick-active {
                    border: 1px solid #bf2362 !important;

                    img {
                        transform: scale(0.85);
                    }
                }

                a {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }

                div {}

                video {
                    height: 40px;
                }

                img {
                    width: auto;
                    height: 40px !important;
                    object-fit: contain;
                }
            }
        }

        // для превью видео иконки
        .thumb-video {
            position: relative;

            background: rgba(191, 35, 97, 0.16);

            &::before {
                content: '';
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                background: rgba(26, 25, 25, 0.62);
                border-radius: 50px;
                width: 45px;
                height: 45px;
            }

            &::after {
                content: '';
                position: absolute;
                z-index: 2;
                top: calc(50% - 10px);
                left: calc(50% - 8px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 0 10px 20px;
                border-color: transparent transparent transparent #eeeeee;

            }

            video {
                width: 100%;
                height: 100%;

            }
        }
    }

    h1 {
        font-size: 1.75rem;
    }

    .products-stock .products-labels {
        position: relative !important;
        z-index: 2;
        top: 0 !important;
        display: flex;
        flex-direction: row !important;
        align-items: flex-start;

        .label {
            color: white;
            font-size: 11px;

            span {
                width: auto;
                text-align: center;
                padding: 2px 5px;
                border-radius: 2px;
                display: block;
                margin: 2px 0;
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    background: #fff;
                    border-radius: 2px;
                    position: absolute;
                    top: 0;
                    opacity: .2;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            img {
                height: 20px;
            }
        }
    }

    .products-reviews {
        padding: .5rem 0 1.5rem;

        i {
            margin-bottom: 2px;
            font-size: 16px;
        }

        &__link {
            font-size: 16px;
            margin-bottom: 3px;
        }
    }

    .block-title {
        font-size: 24px;
        padding-top: 1rem;

        span {
            margin-left: .8rem;
            opacity: 0.5;
        }
    }

    .more-info {
        .btn {
            font-size: 14px;
            padding: 0;
            font-weight: 500;
            color: transparentize($clr_blue, 0.3);

            //color: rgba(10, 26, 92, 0.7);
            &:after {
                font-family: "fontello";
                font-style: normal;
                font-weight: normal;
                speak: none;
                display: inline-block;
                text-decoration: inherit;
                width: 1em;
                margin-right: .2em;
                text-align: center;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                margin-left: 8px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e800";
                transform: rotate(-90deg);
                font-size: 11px;
                text-decoration: none;
                max-width: 13px;
            }
        }
    }

    .product {
        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__code {
                color: transparentize($clr_black, 0.5);
                font-weight: 500;

                span {
                    color: $clr_black;
                }
            }
        }

        &-main {
            display: flex;
            margin: 0 -1rem;
            padding-bottom: 3rem;
            position: relative;
            z-index: 1000;

            @media screen and (max-width : 767px) {
                flex-wrap: wrap;
            }

            &>div {
                width: 100%;
                min-width: 50%;
                padding: 0 1rem;
            }

            &__info {
                @media screen and (max-width : 767px) {
                    margin-top: 4rem;
                }
            }

            &__info {
                @media screen and (max-width : 576px) {
                    margin-top: 2rem;
                }
            }

            &__dop {
                padding-bottom: 3rem;

                .nav-tabs .nav-link {
                    font-size: 15px;
                    padding: 0 10px;
                }
            }

            &__productSlider {
                margin-top: 1rem;
                border-top: 2px solid transparentize($clr_blue, 0.85);
                padding-top: 0;
            }

            &__description {
                margin-top: 1rem;
                border-top: 2px solid transparentize($clr_blue, 0.85);
                padding-top: 1rem;
                display: flex;
                margin: 0 -1rem;

                @media screen and (max-width : 767px) {
                    flex-wrap: wrap;
                }

                &>div {
                    width: 100%;
                    min-width: 50%;
                    padding: 0 1rem;
                }
            }

            &__video {
                margin-top: 2rem;
                border-top: 2px solid transparentize($clr_blue, 0.85);
                padding-top: 1rem;
            }

            &__tags {
                display: flex;
                align-items: center;
                padding: 3rem 0;

                p {
                    margin: 0;
                    margin-right: 1rem;
                    font-size: 18px;
                    font-weight: 500;
                }

                .tags-wrap {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }

                .tag-item {
                    display: block;
                    padding: 5px 15px;
                    margin: 5px;
                    border-radius: 50px;
                    font-size: 13px;
                    font-weight: 500;
                    color: $clr_blue;
                    border: 1px solid transparentize($clr_red, 0.5);
                }
            }

            &__footer {
                font-size: 14px;
                line-height: 130%;
                color: transparentize($clr_blue, 0.4);
                margin-top: 3rem;
            }
        }

        &-shortdesc {
            font-size: 15px;
            line-height: 130%;
            padding: 2rem 0 1rem;
        }

        &-subscribe {
            font-size: 14px;
            // font-weight: 600;

            p {
                margin-bottom: 0.5rem;
            }

            .subscribe {
                &__item {
                    margin-right: 10px;
                    opacity: .9;
                    cursor: pointer;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            button {
                transition: .3s;
                margin-right: 10px;
                opacity: .9;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }
            }
        }

        &-promo {
            display: flex;
            align-items: center;
            border: 1px solid $clr_red;
            box-sizing: border-box;
            border-radius: 8px;
            padding: .8rem 1.1rem;

            @media screen and (max-width : 767px) {
                flex-direction: column;
                text-align: center;
            }

            &__thumb {
                width: 70px;
                height: 70px;

                img {
                    width: 70px;
                    height: 70px;
                    border-radius: 100px;
                    object-fit: cover;
                }
            }

            &__content {
                margin-left: 2rem;

                @media screen and (max-width : 767px) {
                    margin: 1rem 0;
                }

                h5 {
                    color: $clr_red;
                    font-size: 16px;
                    margin-bottom: 5px;
                }

                p {
                    margin: 0;
                    color: transparentize($clr_blue, 0.5);
                    font-weight: 500;

                    span {
                        color: $clr_black;
                    }
                }
            }

            &__detail {
                flex-grow: 1;
                text-align: right;

                @media screen and (max-width : 767px) {
                    text-align: center;
                }

                .btn {
                    background: $clr_red;
                    border-color: $clr_red;
                    font-weight: 500;
                }
            }
        }

        &-variations {
            margin-top: 1rem;
            margin-bottom: -1rem;
            border-top: 2px solid transparentize($clr_blue, 0.85);
            padding-top: 1rem;

            .variation {
                padding-bottom: .5rem;

                &__title {
                    font-size: 14px;
                    line-height: 130%;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.6);
                    margin: 0;
                }

                .products-colors__wrap {
                    padding: 0;
                }

                .products-colors__item {
                    text-decoration: none;
                    color: $clr_black;
                    padding: 4px;
                    width: auto;
                    height: auto;

                    span {
                        width: 20px;
                        height: 20px;
                    }

                    p {
                        display: none;
                    }

                    &.active {
                        width: auto;

                        p {
                            display: block;
                            padding: 0 .7rem;
                        }
                    }
                }

                &__inner {
                    display: flex;
                    align-items: center;
                    padding: .5rem 0;

                    p {
                        margin: 0;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }

                &__item {
                    border: 1px solid transparentize($clr_blue, 0.85);
                    box-sizing: border-box;
                    border-radius: 4px;
                    padding: 5px 10px;
                    color: $clr_black;
                    margin-right: 10px;
                    text-decoration: none;

                    &.active {
                        border-color: $clr_blue
                    }

                    &:hover {
                        color: $clr_primary;
                    }
                }
            }
        }

        &-trade {
            margin-top: 1rem;
            border-top: 2px solid transparentize($clr_blue, 0.85);
            padding-top: 1rem;

            .products-price {
                padding-top: .5rem;

                &__wrap {
                    display: flex;
                    align-items: flex-end;
                }

                &__old {
                    order: 1;
                    margin-left: 1rem;
                    margin-bottom: 2px;

                    .price {
                        font-size: 26px;
                        font-weight: 500;
                        color: transparentize($clr_blue, 0.6);

                        &:before {
                            height: 2px;
                        }

                        &:after {
                            font-size: 20px;
                        }
                    }
                }

                &__main {
                    .price {
                        font-size: 50px;
                        font-weight: 500;

                        &:after {
                            font-size: 35px;
                            margin: 0;
                        }
                    }
                }
            }

            .products-stock {
                margin-top: 2rem;
                margin-bottom: 2rem;

                p {
                    font-size: 15px;
                }

                &__item {
                    display: grid;
                    grid-template-columns: 2fr 1fr;
                    align-items: center;
                    // display: flex;
                    // justify-content: space-between;
                    border-bottom: 1px solid transparentize($clr_gray, .7);
                    padding: 3px 0 5px;
                    // align-items: flex-end;

                    b {
                        font-weight: 500;

                        span {
                            font-weight: 500;
                            opacity: .6;
                            margin-left: 10px;
                            font-size: 13px;
                        }
                    }
                }
            }

            .cart-btn {
                width: 55%;

                @media screen and (max-width : 991px) {
                    width: 100%;
                }

                .btn {
                    width: 100%;
                    padding: 10px;
                    font-weight: 500;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        margin-right: 1rem;
                        font-size: 20px;
                        width: 28px;
                        height: 28px;
                        display: inline-block;

                        @keyframes aPreloader {
                            0% {
                                top: 46px;
                                left: 46px;
                                width: 0;
                                height: 0;
                                opacity: 1;
                            }

                            100% {
                                top: 11px;
                                left: 11px;
                                width: 70px;
                                height: 70px;
                                opacity: 0;
                            }
                        }

                        &.i-preloader {}

                        .preloader {
                            width: 28px;
                            height: 28px;
                            display: inline-block;
                            overflow: hidden;
                            background: none;

                            &__wrap {
                                width: 100%;
                                height: 100%;
                                position: relative;
                                transform: translateZ(0) scale(0.28);
                                backface-visibility: hidden;
                                transform-origin: 0 0;

                                div {
                                    box-sizing: content-box;
                                    position: absolute;
                                    border-width: 4px;
                                    border-style: solid;
                                    opacity: 1;
                                    border-radius: 50%;
                                    animation: aPreloader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

                                    &:nth-child(1) {
                                        border-color: #ffffff
                                    }

                                    &:nth-child(2) {
                                        border-color: #ffffff;
                                        animation-delay: -0.5s;
                                    }
                                }
                            }
                        }
                    }

                    &.adding {
                        opacity: .8;

                        i {
                            margin: 0 .6rem 0 .4rem;
                        }
                    }

                    &.added {
                        background: rgba(0, 135, 104, 0.1);
                        border: 1px solid #008768;
                        color: #008768;
                        font-weight: 500;

                        i {
                            font-size: 18px;
                            margin-right: 3px;
                        }

                        &:focus {
                            box-shadow: 0 0 0 0.1rem rgba(0, 133, 102, 0.4);
                        }
                    }
                }
            }

            .cart-block {
                display: flex;
                align-items: center;
                width: 100%;

                .btns-dop {
                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        opacity: .7;
                        outline: none;
                        box-shadow: none;
                        margin: 10px;

                        svg {
                            margin-right: 8px;
                            width: 24px;
                            height: 24px;
                            opacity: .7;

                            &.i-wishlist {
                                fill: $clr_blue
                            }
                        }

                        span {
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 130%;
                            color: rgb(10, 26, 92);
                        }

                        &:hover {
                            opacity: 1;
                        }

                        &.active {
                            opacity: 1;

                            svg {
                                opacity: 1;
                                fill: #FB3F4C;
                            }

                            &:hover {
                                opacity: .7;
                            }
                        }
                    }
                }

                @media screen and(max-width: 990px) {
                    flex-direction: column;
                }
            }
        }

        &-bages {
            margin-top: 1rem;
            border-top: 2px solid transparentize($clr_blue, 0.85);
            padding-top: 1rem;

            .short-bages {
                &__wrap {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0;
                    list-style: none;

                    li {
                        margin-left: 10px;

                        img {
                            height: 40px;
                        }
                    }
                }
            }
        }

        &-services {
            margin-top: 1rem;
            border-top: 2px solid transparentize($clr_blue, 0.85);
            padding-top: 1rem;

            &__wrap {
                display: flex;
                flex-direction: column;

                .service__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 10px;

                    .checkbox-filter__item {
                        width: 290px;

                        label {
                            align-items: flex-start;
                            line-height: 16px;
                            font-weight: 500;
                            font-size: 13px;
                        }
                    }

                    .service-select {
                        width: 225px;
                    }

                    .i-rub {
                        width: 70px;
                        font-weight: 500;
                        text-align: right;

                        &:after {
                            font-size: 13px;
                            margin: 0;
                        }
                    }
                }
            }

            .more-info {
                margin-left: 25px;

                .btn {
                    &:after {
                        transform: rotate(0deg);
                    }
                }
            }
        }

        &-delivery {
            margin-top: 1rem;
            border-top: 2px solid transparentize($clr_blue, 0.85);
            padding-top: 1rem;

            &__title {
                display: flex;
                align-items: flex-end;
                font-weight: 500;
                font-size: 16px;
                padding-bottom: 1rem;

                p {
                    margin: 0;
                }

                .select-custom {
                    padding: 0 10px;
                    font-weight: 500;
                    font-size: 16px;
                    width: auto;
                    border: none;
                    height: auto;
                    color: $clr_blueLighten;
                    background-size: 10px 9px;
                }
            }

            &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: .2rem 0;
                font-size: 14px;

                p {
                    margin: 0;
                    width: 300px;
                }

                b {
                    width: 190px;
                    padding: 0 1rem;

                    &.price {
                        padding: 0;
                        width: 80px;
                        text-align: right;
                    }
                }
            }
        }

        &-specs {
            .character_by_groups {
                margin-bottom: 24px;

                .character_by_groups__title {
                    font-weight: 500;
                    font-size: 18px;
                    margin-bottom: 12px;
                }
            }

            &__item {
                display: flex;
                justify-content: space-between;

                p {
                    margin: 0;
                }

                .dotted {
                    flex-grow: 1;
                    opacity: .4;
                    border-bottom: 1px dotted;
                    margin: 5px;
                }
            }

            &.tab {
                .block-title {
                    padding-bottom: 2rem;
                }

                .product-specs {
                    &__wrap {
                        width: calc(100% - 475px);
                        padding-right: 48px;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;

                        @media screen and (max-width : 1200px) {
                            width: 100%;
                            padding-right: 0;
                        }
                    }

                    &__item {
                        padding: 5px 0;
                    }

                    &__section {
                        padding-bottom: 2rem;

                        .title {
                            font-size: 21px;
                            margin-bottom: .5rem;
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        &-photos {
            &__wrap {
                width: calc(100% - 475px);
                padding-right: 48px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;

                @media screen and (max-width : 1200px) {
                    width: 100%;
                    padding-right: 0;
                }

                img {
                    padding: 1rem;
                    width: 100%;
                }
            }
        }

        &-video {
            padding: 1rem 0;

            iframe {
                width: 100%;
                height: 600px;
            }

            &__footer {
                display: flex;
                align-items: center;
                padding-bottom: 1rem;

                p {
                    margin: 0;
                    font-weight: 500;
                    font-size: 14px;
                }
            }

            &__subscribe {
                display: flex;
                border: 1px solid black;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                text-decoration: none;
                margin-left: 1rem;

                &-left {
                    display: block;
                    background: $clr_red-darken;
                    color: $clr_white;
                    display: flex;
                    align-items: center;
                    padding: 5px 8px;

                    img {
                        margin-right: 10px;
                        width: 20px;
                        background-size: contain;
                    }
                }

                &-right {
                    color: $clr_black;
                    padding: 0 8px;
                }
            }
        }
    }

    &-gift {
        position: absolute;
        z-index: 1001;
        width: 100px;
        height: 100px;
        bottom: 110px;
        right: 1rem;
        border-radius: 8px;

        background: url("/img/i-gift.svg") center center no-repeat;
        background-size: contain;

        &.contents {
            height: 110px;
            background: #fff;
            text-align: center;
            border: 1px solid $clr_primary;
            border-radius: .2rem;
            padding: 1px;

            img {
                width: 80px;
                height: 80px;
                object-fit: cover;
            }
        }
    }

}

.nav-tabs {
    border: none;
    border-bottom: 2px solid transparentize($clr_blue, .85);
    margin-bottom: 2rem;

    .nav-link {
        color: $clr_black;
        font-size: 18px;
        font-weight: 500;
        border: none;
        border-bottom: 3px solid transparent;

        &.active {
            border-color: $clr_primary;
            color: $clr_primary;
        }
    }
}



// .image-magnify {
//     img {
//         width: auto !important;
//         height: 450px !important;
//         margin: 0 auto;
//     }
// }

// @media screen and (max-width: 1626px) {
//     .page-product .product-main__gallery .desktop-gallery .thumb img,
//     .page-product .product-main__gallery .desktop-gallery .thumb video {
//         min-height: 60px;
//     }
//     .page-product .product-main__gallery .desktop-gallery .thumb > div {
//         max-height: 60px;
//     }
// }
@media screen and (max-width: 1350px) {
    .page-product .product-main__gallery .desktop-gallery .thumb>div {
        max-height: 77px;
    }
}

@media screen and (max-width: 992px) {
    .page-product-gift {
        position: static;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px auto;
    }
}

// @media screen and (max-width: 576px) {
//     .page-product {
//         .breadcrumb {
//             flex-wrap: nowrap;
//             // text-wrap: nowrap;
//             white-space: nowrap;
//             overflow-x: scroll;
//             padding-top: 20px;
//             // padding-bottom: 5px;
//             .breadcrumb-item {

//                 padding-bottom: 5px;
//                 // &:first-child {
//                 //     min-width: 15px;
//                 // }

//                 // .i-home {
//                 //     position: fixed;
//                 //     background: #fff;
//                 // }
//             }

//         }

//         .breadcrumb-item+.breadcrumb-item { 
//             margin: 0 20px 0 0;
//         }
//     }

// }