@import "../../scss/default.scss";

.loading-vacancy {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    border-radius: 8px;
    margin-top: 30px;
    background: #dce1e53b;

    .spinner {
        color: #bf2462;
    }
}

.with-top-banner+.container {
    .vacancy-page__banner {
        top: 180px;
    }
}

.vacancy-page {

    .withBanner {
        .vacancy-page__row-aside {
            margin-top: 400px;
        }

        .vacancy-page__row-content {
            margin-top: 280px;
        }
    }

    // position: relative;
    padding-bottom: 50px;

    .onceVacancy-content {

        margin-top: 60px;

        .vacancy-info {
            margin-top: 30px;
            margin-bottom: 50px;

            &_important {
                display: flex;
                align-items: baseline;
                color: #bf2462;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5;
                margin-bottom: 20px;

                img {
                    width: 30px;
                    height: 30px;
                    margin-right: 8px;
                }
            }

            &_city {
                font-style: italic;
                font-weight: 400;
                color: rgb(68, 68, 68);
            }

            h1 {
                font-size: 30px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            &_text {
                margin: 30px 0;
            }
        }

    }

    &__banner {
        position: absolute;
        top: 145px;
        left: 0;
        right: 0;
        height: 400px;
        background: #9cacec59;
        background-position-x: center;
        background-position-y: top;
        background-repeat: repeat-x;
        background-size: cover;

    }

    &__row {
        display: flex;

        &-aside {

            width: 350px;
        }

        &-content {
            position: relative;
            z-index: 1;
            flex-grow: 1;



            &--header {
                background: #DCE1E5;
                box-shadow: 0 5px 9px 0px #0000005e;
                padding: 30px;
                border-radius: 8px;

                h1 {
                    font-size: 28px;
                    font-weight: 700;
                    text-align: center;
                    margin-bottom: 30px;
                }

                .row {
                    margin-left: -8px;
                    margin-right: -8px;
                }

                .col-6 {
                    padding: 0 8px;
                }

                .search {
                    display: flex;
                    margin-bottom: 16px;

                    .search-btn {
                        display: flex;
                        align-items: center;
                        margin-left: 8px;
                        background: #a8b2b9;
                        border: none;
                        padding-left: 16px;
                        padding-right: 16px;
                        min-width: 50px;

                        &:active,
                        &:focus {
                            box-shadow: none;
                            border-color: none;
                        }

                        svg path {
                            fill: #eff2f5;
                        }
                    }

                    .search-input {
                        flex-grow: 1;
                        position: relative;

                        input {
                            padding-right: 35px;
                        }

                        .clear-btn {
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            transform: translateY(-50%);

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            width: 25px;
                            height: 25px;
                            background: #dce1e5;
                            border-radius: 100%;
                            border: none;
                            transition: 250ms ease;

                            span {
                                transform: translateY(-1px);
                            }

                            &:disabled {
                                opacity: 0.5;
                            }

                            &:not(:disabled) {

                                &:hover {
                                    background: #c6cbcf;
                                }

                                &:active,
                                &:focus {
                                    background: #f54e91;
                                }
                            }

                            &:active,
                            &:focus {
                                box-shadow: none;
                                border-color: none;
                            }



                        }
                    }
                }

                .link-to-back {
                    display: inline-flex;
                    align-items: center;
                    color: #bf2462;
                    font-size: 1.1rem;
                    font-weight: 500;
                    margin-top: 30px;
                    text-decoration: none;
                    transition: 250ms ease;

                    i {

                        transition: 250ms ease;
                    }

                    &:hover {
                        color: #861341;

                        i {
                            transform: translateX(-2px);
                        }
                    }
                }
            }

            &--list {
                margin-top: 30px;
                margin-bottom: 50px;

                .skeleton {
                    span {

                        display: block;
                        height: 80px;

                        background: #f0f5f6af;

                        &:nth-child(2n) {
                            background: #faffffa1;
                        }

                        &:first-child {

                            border-radius: 8px 8px 0 0;
                        }

                        &:last-child {

                            border-radius: 0 0 8px 8px;
                        }
                    }
                }

                .empty-search-text {
                    text-align: center;

                    font-weight: 500;
                    font-size: 30px;
                    padding-top: 60px;
                    padding-bottom: 120px;

                    border-bottom: 1px solid #eee;
                }

                .vacancy-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 32px 16px;

                    background: #FAFFFF;

                    text-decoration: none;
                    transition: 250ms ease;



                    &:first-child {

                        border-radius: 8px 8px 0 0;
                    }

                    &:last-child {

                        border-radius: 0 0 8px 8px;
                    }

                    &:nth-child(2n) {
                        background: #F0F5F6;
                    }

                    &.important {
                        background: #F9EDF5;
                    }

                    img {
                        margin-right: 16px;
                        width: 30px;
                        height: 30px;
                    }

                    .text-content {

                        display: flex;
                        align-items: center;
                        flex-grow: 1;
                        font-size: 14px;
                        line-height: 1.2;
                        color: rgb(34, 34, 34);

                        .left-block {
                            width: 50%;
                            display: flex;
                            align-items: center;

                            .left-block-text {
                                display: flex;
                                flex-direction: column;

                                span {
                                    margin: 4px 0;
                                }

                                .address {
                                    font-size: 1.1em;
                                }
                            }
                        }

                        .name-block {
                            width: 50%;
                            font-size: 1.2em;

                        }
                    }

                    .text-link {
                        font-size: 0.9rem;
                        font-weight: 500;
                        transition: 250ms ease;
                    }

                }
            }
        }
    }
}



@media screen and (min-width: 992px) {
    .vacancy-page__row-content--list .vacancy-item {
        &:hover {
            background: rgb(255, 255, 255);

            &:nth-child(2n) {
                background: #f5fafa;
            }

            &.important {
                background: rgb(255, 239, 249);
            }

            .text-link {
                color: $clr_orange;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .with-top-banner+.container {
        .vacancy-page__banner {
            top: 85px;
        }
    }

    .vacancy-page__row-content--list .vacancy-item {
        &:active {
            background: rgb(255, 255, 255);

            &:nth-child(2n) {
                background: #f5fafa;
            }

            &.important {
                background: rgb(255, 239, 249);
            }

            .text-link {
                color: $clr_orange;
            }
        }
    }

    .vacancy-page .onceVacancy-content {
        margin-top: 0;
    }


    .vacancy-page {
        .withBanner {
            .vacancy-page__row-aside {
                margin-top: 210px;
            }

            .vacancy-page__row-content {
                margin-top: 0;
            }
        }

        &__banner {
            top: 60px;
            // top: 85px;
            height: 200px;
        }

        &__row {
            &-aside {
                width: 100%;
                margin-bottom: 30px;
            }

            &-content {

                &--list {
                    margin-top: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .vacancy-page {
        &__banner {
            height: 100px;
        }

        .withBanner .vacancy-page__row-aside {
            margin-top: 110px;
        }

        .vacancy-page__row-aside {
            margin-bottom: 0px;
        }

        &__row-content {
            &--header {
                padding-left: 16px;
                padding-right: 16px;

                h1 {
                    font-size: 22px;
                    margin-bottom: 16px;
                }

                input {
                    font-size: 13px;
                }
                select {
                    font-size: 12px;
                }
                .search {
                    margin-bottom: 10px;

                    .search-input .clear-btn {
                        width: 22px;
                        height: 22px;
                        min-width: 22px;
                        min-height: 22px;

                        span {
                            font-size: 0.9em;
                        }
                    }
                }

                .link-to-back {
                    display: flex;
                }
            }

            &--list {
                .vacancy-item {
                    // align-items: flex-start;
                    padding: 22px 16px;

                    img {
                        margin-right: 16px;
                    }

                    .text-content {
                        flex-direction: column-reverse;
                        align-items: flex-start;

                        .name-block {
                            width: 100%;
                            font-size: 1.5em;
                            margin-bottom: 8px;
                        }

                        .left-block {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}