@import "../../../default.scss";
.page-content {
    .breadcrumb-item {
        &:last-child {
            color: #3A50AE;
            font-weight: 500;
        }
    }
    &__wrap {
        display: flex;
        align-items: flex-start;
    }
    &__inner {
        width: 100%;
        h1 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        h3 {
            font-size: 16px;
            font-weight: 600;
            margin-top: 30px;
        }
        blockquote {
            margin-left: 20px;
            box-sizing: border-box;
            padding-left: 20px;
            border-left: 1px solid #efefef;
        }
        .features {
            padding: 10px 0;
            box-sizing: border-box;
            display: grid;
            grid-gap: 15px;
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            &__item{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                padding: 10px;
                box-sizing: border-box;
                img{
                    width: 80px;
                    height: 90px;
                    object-fit: contain;
                }
                .title{
                    color: $clr_primary;
                    font-weight: 500;
                    margin: 10px 0;
                }
                .desc{
                    font-size: 13px;
                }
            }
        }
        .numbers{
            padding-bottom: 15px;
            display: grid;
            grid-gap: 15px;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            @media screen and (max-width : 770px) {
                grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
            }
            &__item{
                padding: 5px 5px;
                box-sizing: border-box;
                text-align: center;
                h4{
                    color: $clr_green;
                    font-size: 48px;
                    font-weight: 500;
                    white-space: nowrap;
                    margin-bottom: 10px;
                }
                span{
                    display: block;
                    font-size: 13px;
                    line-height: 120%;
                }
            }
        }
        .requiz{
            font-size: 14px;
            padding-bottom: 40px;
            box-sizing: border-box;
            .line{
                display: flex;
                align-items: center;
                padding-bottom: 3px;
                p{
                    margin: 0;
                    font-weight: 500;
                    width: 300px;
                    min-width: 300px;
                    padding-right: 4px;
                    box-sizing: border-box;
                }
                @media screen and (max-width : 700px) {
                    flex-wrap: wrap;
                    padding-bottom: 6px;
                    p{
                        width: 100%;
                        min-width: auto;
                    }
                }
            }
        }
    }
    .sidenav {
        width: 270px;
        min-width: 270px;
        @media screen and(max-width: 990px) {
            display: none;
        }
        &__list {
            text-decoration: none;
            list-style: none;
            padding: 10px 0;
            margin: 0;
            &:first-child {
                padding-top: 0;
            }
            a {
                font-size: 15px;
                font-weight: 500;
                color: #000;
                padding: .1rem 0.5rem;
                &:hover {
                    color: #bf2362;
                    //color: #007bff;
                }
                &.active {
                    color: #bf2362;
                }
            }
        }
    }
    .contact__gallery {
        .react-photo-gallery--gallery {
            &>div>img {
                width: 130px;
                height: 130px;
                @media screen and (max-width : 1350px) {
                    width: calc(12.5% - 10px);
                    height: 130px;
                }
                @media screen and (max-width : 1200px) {
                    width: calc(16.666% - 10px);
                    height: 130px;
                }
                @media screen and (max-width : 768px) {
                    width: calc(20% - 10px);
                    height: 100px;
                }
                @media screen and (max-width : 500px) {
                    width: calc(33.3333% - 10px);
                    height: 100px;
                }
                object-fit: cover;
                margin: 5px !important;
                transition: .3s;
                &:hover {
                    box-shadow: 0 3px 10px rgba($clr_blue, .5);
                }
            }
        }
    }
}
