@import "../../default.scss";

.products {
    &__wrap {
        border: 1px solid transparentize($clr_gray, 0.7);
        position: relative;
        padding: 10px;
        height: 100%;

        .btn-toolbar {
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 10;
            flex-direction: column;

            .btn-simple {
                padding: 0;
                margin-bottom: 5px;
                opacity: .4;
                line-height: 1;
                transition: $transition;
                outline: none;
                box-shadow: none;

                &:hover {
                    opacity: .9;
                }

                svg {
                    fill: $clr_blueLighten;
                }

                &.btn-delete {
                    svg {
                        transform: rotate(45deg);
                    }

                    &:hover {
                        svg {
                            fill: $clr_red;
                        }
                    }
                }

                &.btn-wishlist {
                    span {
                        display: none;
                    }

                    &:hover {
                        svg {
                            fill: $clr_primary;
                        }
                    }

                    &.active {
                        opacity: .9;

                        svg {
                            fill: $clr_primary;
                        }
                    }
                }
            }
        }
    }

    &-image {
        // display: flex;
        // justify-content: center;
        width: 100%;
        // height: 136px;
        height: 220px;

        img {
            width: 100%;
            height: 220px;
            min-height: 220px;
            max-height: 220px;
            // height: 136px;
            // min-height: 136px;
            // max-height: 136px;
            object-fit: contain;
            // object-fit: cover;
            animation: .7s both FadeInBlock;

            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 8px;
            line-height: 9px;
        }

        video {
            margin: 0 auto;
            // height: 136px;
            height: 176px;
            width: 100%;
        }
    }

    &-colors {
        height: 35px;
        overflow: hidden;

        &__wrap {
            display: flex;
            padding: .5rem 0;

            &.none {
                display: none;
            }
        }

        &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            width: 20px;
            height: 20px;
            margin-right: 3px;
            border-radius: 20px;
            border: 1px solid transparent;
            box-sizing: border-box;
            transition: $transition;

            span {
                display: block;
                width: 14px;
                height: 14px;
                border-radius: 14px;
                background-color: #c4c4c4;
            }

            &:hover {
                border-color: transparentize($clr_primary, .5);
            }

            &.active {
                border-color: $clr_primary;
            }
        }
    }

    &-title {
        display: block;
        font-size: 13px;
        line-height: 130%;
        color: $clr_black;
        overflow: hidden;
        height: 34px;
        margin-bottom: 1px;

        &:hover {
            color: $clr_orange;
        }

        &:visited {
            //color: $clr_primary;
        }
    }

    &-labels {
        position: absolute;
        z-index: 2;
        top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .label {
            color: white;
            font-size: 11px;

            span {
                width: auto;
                text-align: center;
                padding: 2px 5px;
                border-radius: 2px;
                display: block;
                margin: 2px 0;
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    background: #fff;
                    border-radius: 2px;
                    position: absolute;
                    top: 0;
                    opacity: .2;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }

            img {
                height: 20px;
            }
        }
    }

    &-reviews {
        display: flex;
        align-items: center;
        color: $clr_black;
        height: 22px;

        @media screen and (max-width : 370px) {
            flex-wrap: wrap;
        }

        .star-ratings {
            margin-right: 5px;

            .star-container {
                height: 15px;
                vertical-align: baseline !important;

                svg {
                    vertical-align: baseline;
                }
            }
        }

        i {
            display: none;
            color: transparentize($clr_blue, 0.6);
        }

        p {
            margin: 0;
            font-size: 13px;
            opacity: .6;
        }

        &:hover {
            color: $clr_blueLighten;
            color: $clr_orange;

            p {
                opacity: 1;
            }
        }

        &.no-rating {
            .star-ratings {
                display: none !important;
            }

            i {
                display: block;
                color: $clr_orange;
            }
        }

        &.no-reviews {
            .star-ratings {
                display: none !important;
            }

            i {
                display: block;
            }
        }
    }

    &-price {
        display: flex;
        align-items: flex-end;
        width: 100%;
        justify-content: space-between;

        //font-weight: 500;
        .price {
            font-size: 24px;
            line-height: 18px;

            &:after {
                font-size: 17px;
                transform: translateY(-1px);
                margin-left: .1em;
                margin-right: .1em;
            }
        }

        &__old {
            height: 22px;
            margin-top: -2px;

            .price {
                display: none;
                color: $clr_gray;
                font-size: 14px;
                position: relative;
                z-index: -1;

                &:after {
                    font-size: 13px;
                }

                &:before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    top: 50%;
                    left: -3px;
                    transform: translateY(-10%);
                    background: $clr_red;
                    z-index: 1;
                }
            }
        }

        .products-cart-sm {
            a {
                font-size: 22px;
                color: $clr_primary;

                &:hover {
                    color: lighten($clr_primary, 10%);
                }
            }
        }

        &.product-sale {
            .products-price__old {
                .price {
                    display: inline;
                    z-index: 1;
                }
            }

            .products-price__main {
                .price {
                    color: $clr_red;
                }
            }
        }

        &.stock-out {
            .price {
                color: $clr_gray;
            }

            .products-cart-sm a {
                display: none;
            }
        }
    }

    &-stock {
        p {
            font-size: 12px;
            font-weight: 500;
            display: flex;
            align-items: center;
            margin-top: 3px;
            margin-bottom: 0;
        }

        p:before {
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline;
            text-decoration: inherit;
            width: 1em;
            margin-right: .4em;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            line-height: 1em;
            margin-left: .1em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .stock-in {
            color: $clr_primary;

            &:before {
                content: "\e804";
            }
        }

        .stock-end {
            color: $clr_red-darken;

            &:before {
                content: "\e802";
                margin-bottom: 2px;
            }
        }

        .stock-out {
            color: $clr_gray;

            &:before {
                content: "\e80d";
                margin-bottom: 1px;
            }
        }
    }

    &-gift {
        position: absolute;
        z-index: 2;
        width: 48px;
        height: 48px;
        // top: 136px;
        top: 176px;
        right: 13px;
        border-radius: 8px;
        background: url("../../../img/i-gift.svg") center center no-repeat;
        background-size: contain;

        &.contents {
            height: auto;
            background: none;
            text-align: center;
            border: 1px solid $clr_primary;
            border-radius: .2rem;
            padding: 1px;
            top: 156px;

            img {
                width: 40px;
                height: 40px;
                object-fit: cover;
            }
        }
    }

    &-slider {
        position: relative;
        animation: .7s both FadeInBlock;

        &__wrap {
            position: relative;
            animation: .7s both FadeInBlock;
            min-height: 292px;

            @media screen and (max-width: 1200px) {
                max-width: calc(100% - 1px);
            }
        }

        .tns-controls {
            width: 100%;
        }

        .tns-nav {
            width: 100%;
        }

        &__title {
            padding: 2rem 0 .5rem;
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            color: $clr_blue;
            display: flex;
            align-items: center;

            a {
                color: inherit;
                text-decoration: none;
                display: flex;
                align-items: center;

                &:hover {
                    color: $clr_orange;
                }
            }

            img {
                width: 32px;
                min-width: 32px;
                height: 32px;
                object-fit: contain;
                margin-right: 1rem;
            }
        }

        /* .products-image{
            height: 175px;
            img{
                height: 175px;
            }
        } */
    }

    &-footer {
        display: none;
    }

    &-cart-sm {
        .add-to-cart {
            color: $clr_primary;
            position: relative;
            font-size: 19px;
            padding: 1px 5px 1px 1px;

            &:hover {
                background: rgba(191, 35, 98, 0.1);
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba(191, 35, 98, 0.16);
            }

            &.added {
                &:after {
                    content: "\e804";
                    font-family: "fontello";
                    font-style: normal;
                    font-weight: normal;
                    speak: none;
                    display: inline;
                    text-decoration: inherit;
                    width: 1em;
                    margin-right: .4em;
                    text-align: center;
                    -webkit-font-feature-settings: normal;
                    -moz-font-feature-settings: normal;
                    font-feature-settings: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1em;
                    margin-left: .1em;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    top: 4px;
                    left: 12px;
                    font-size: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .products-image {
        min-height: 192px;
        max-height: 192px;
        height: 192px;

        img {
            min-height: 192px;
            max-height: 192px;
            height: 192px;
        }
    }
}

@media screen and (max-width: 576px) {

    // картинки для мобилки
    .products-image img {
        // object-fit: cover;
    }
}