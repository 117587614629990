@import "../../../default.scss";

.promo{
    
    @media screen and (max-width : 1200px){
        display: flex;
    }
    @media screen and (max-width : 500px){
        flex-direction: column;
    }
    &__item{
        padding: 0;
        display: block;
        img{
            width: 100%;
            object-fit: cover;
            @media screen and (min-width : 750px){
                height: 250px;
            }
        }
    }
    &-rools{
        font-size: 14px;
        p{
            margin-bottom: 3px;
        }
        &__modal{
            //text-align: center;
        }
        &__btn{
            padding: 12px 50px;
            margin-top: 2rem;
            font-size: 16px;
            //float: right;
            
            @media screen and (max-width : 1200px){
                margin-bottom: 3rem;
            }
            @media screen and (max-width : 600px){
                width: 100%;
            }
        }
        &__wrap{}
    }
    &-product{
        padding-right: 0;
    }
    

    @media screen and (min-width : 1200px){
        .col-xl-8{
            padding-right: 0;
        }
        .col-xl-4{
            padding-left: 0;
        }
    }
}

.carousel{
    &-control-next,
    &-control-prev{
        max-width: 100px;
        .arrow-icon{
            display: block;
            width: 33px;
            height: 19px;
            background: url("/img/i-arrow.svg") center center no-repeat;
            background-size: contain;
            transform: rotate(90deg);
            &.next{
                transform: rotate(-90deg);
            }
        }
    }
    &-indicators li {
        height: 12px;
        width: 13px;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        box-sizing: border-box;
        border: 0;
        &.active{
            background-color: $clr_primary;
        }
    }
}

.onemore-carousel{
    padding-top: 1rem;
}