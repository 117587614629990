@use 'sass:math';
.p {
  @for $i from 0 through 100 {
    $m: math.div(1rem * $i, 10);
    &#{$i} {
      padding: $m;
    }
    &l#{$i} {
      padding-left: $m;
    }
    &r#{$i} {
      padding-right: $m;
    }
    &b#{$i} {
      padding-bottom: $m;
    }
    &t#{$i} {
      padding-top: $m;
    }
    &tb#{$i} {
      padding-top: $m;
      padding-bottom: $m;
    }
    &lr#{$i} {
      padding-left: $m;
      padding-right: $m;
    }
  }
  &0 {
    padding: 0;
  }
  &b0 {
    padding-bottom: 0;
  }
  &t0 {
    padding-top: 0;
  }
  &l0 {
    padding-left: 0;
  }
  &r0 {
    padding-right: 0;
  }
}
