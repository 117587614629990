.galaxy-tab-wrap label::before {
    display: none !important;
}

.galaxy-tab-wrap label::before {
    display: none !important;
}

.galaxy-tab-wrap {
    // transition: 0.3s box-shadow ease;
    // border-radius: 6px;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    list-style: none;
    // background-color: #fff;
    margin: 40px 0;
    // border: 1px solid #f5f5f5;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}

// .galaxy-tab-wrap:hover {
//     box-shadow: 0 12px 23px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.05);
// }
.galaxy-tab-wrap .table-hover tbody tr:hover {

    background-color: rgba(0, 0, 0, .075) !important;
}

.galaxy-tab {
    display: none;
}

.galaxy-tab:checked:nth-of-type(1)~.galaxy-tab__content:nth-of-type(1),
.galaxy-tab:checked:nth-of-type(2)~.galaxy-tab__content:nth-of-type(2),
.galaxy-tab:checked:nth-of-type(3)~.galaxy-tab__content:nth-of-type(3),
.galaxy-tab:checked:nth-of-type(4)~.galaxy-tab__content:nth-of-type(4),
.galaxy-tab:checked:nth-of-type(5)~.galaxy-tab__content:nth-of-type(5),
.galaxy-tab:checked:nth-of-type(6)~.galaxy-tab__content:nth-of-type(6),
.galaxy-tab:checked:nth-of-type(7)~.galaxy-tab__content:nth-of-type(7),
.galaxy-tab:checked:nth-of-type(8)~.galaxy-tab__content:nth-of-type(8),
.galaxy-tab:checked:nth-of-type(9)~.galaxy-tab__content:nth-of-type(9),
.galaxy-tab:checked:nth-of-type(10)~.galaxy-tab__content:nth-of-type(10),
.galaxy-tab:checked:nth-of-type(11)~.galaxy-tab__content:nth-of-type(11),
.galaxy-tab:checked:nth-of-type(12)~.galaxy-tab__content:nth-of-type(12) {
    opacity: 1;
    transition: 0.5s opacity ease-in, 0.8s transform ease;
    position: relative;
    top: 0;
    z-index: 100;
    transform: translateY(0px);
    text-shadow: 0 0 0;
}

// .galaxy-tab:checked:nth-of-type(2)~.galaxy-tab__content:nth-of-type(2) {
//     opacity: 1;
//     transition: 0.5s opacity ease-in, 0.8s transform ease;
//     position: relative;
//     top: 0;
//     z-index: 100;
//     transform: translateY(0px);
//     text-shadow: 0 0 0;
// }

// .galaxy-tab:checked:nth-of-type(3)~.galaxy-tab__content:nth-of-type(3) {
//     opacity: 1;
//     transition: 0.5s opacity ease-in, 0.8s transform ease;
//     position: relative;
//     top: 0;
//     z-index: 100;
//     transform: translateY(0px);
//     text-shadow: 0 0 0;
// }

// .galaxy-tab:checked:nth-of-type(4)~.galaxy-tab__content:nth-of-type(4) {
//     opacity: 1;
//     transition: 0.5s opacity ease-in, 0.8s transform ease;
//     position: relative;
//     top: 0;
//     z-index: 100;
//     transform: translateY(0px);
//     text-shadow: 0 0 0;
// }

// .galaxy-tab:checked:nth-of-type(5)~.galaxy-tab__content:nth-of-type(5) {
// opacity: 1;
// transition: 0.5s opacity ease-in,
// 0.8s transform ease;
// position: relative;
// top: 0;
// z-index: 100;
// transform: translateY(0px);
// text-shadow: 0 0 0;
// }

// .galaxy-tab:first-of-type:not(:last-of-type)+label {
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
// }

// .galaxy-tab:not(:first-of-type):not(:last-of-type)+label {
//     border-radius: 0;
// }

// .galaxy-tab:last-of-type:not(:first-of-type)+label {
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
// }

.galaxy-tab:checked+label {
    background-color: #fff;
    box-shadow: 0 -1px 0 #fff inset;
    cursor: default;
}

.galaxy-tab:checked+label:hover {
    box-shadow: 0 -1px 0 #fff inset;
    background-color: #fff;
}

.galaxy-tab+label {
    // box-shadow: 0 -1px 0 #eee inset;
    border-radius: 6px;
    cursor: pointer;
    display: block;
    // width: 100px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    color: #333;
    flex-grow: 3;
    background-color: #f0f0f0;
    border: 1px solid #c9c7c7;
    user-select: none;
    transition: 0.3s background-color ease, 0.3s box-shadow ease;
    height: 60px;
    box-sizing: border-box;
    padding: 8px 12px !important;
    margin: 4px !important;
}

.galaxy-tab+label {
    text-align: center;
    display: block !important;
}

.galaxy-tab+label:hover {
    background-color: #f9f9f9;
    box-shadow: 0 1px 0 #f4f4f4 inset;
}

.galaxy-tab__content {
    padding: 16px 4px;
    background-color: transparent;
    position: absolute;
    width: 100%;
    z-index: -1;
    opacity: 0;
    left: 0;
    transform: translateY(-3px);
    border-radius: 6px;
}