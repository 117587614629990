// ? display none
.h {
  &d {
    @media screen and (min-width: 990px) {
      display: none !important;
    }
  }
  &t {
    @media screen and (min-width: 768px) and (max-width: 989px) {
      display: none !important;
    }
  }
  &m {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }
  &sm {
    @media screen and (max-width: 360px) {
      display: none !important;
    }
  }
}
