@import "../../../default.scss";
.upload-avatar {
    margin-bottom: 0;
    display: block !important;
    width: 130px;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    span {
        display: block;
    }
    input {
        display: none;
    }
}
.upload-avatar-btn {
    display: block !important;
}
.page-profile {
    background: #ffffff;
    padding-top: 20px;
    .container {
        display: flex;
    }
    .breadcrumbs {
        display: flex;
        align-items: flex-end;
        padding: 10px 0;
        h1 {
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            color: #000000;
        }
        h2 {
            font-weight: 500;
            font-size: 18px;
            line-height: 19px;
            color: #000000;
            display: flex;
            align-items: flex-end;
            &:before {
                content: '\e800';
                font-family: "fontello";
                font-style: normal;
                font-weight: normal;
                speak: none;
                display: inline-block;
                text-decoration: inherit;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 10px;
                margin: 0 10px;
                transform: rotate(-90deg);
            }
        }
    }
    .prof-sidebar {
        width: 270px;
        min-width: 270px;
        border-right: 1px solid transparentize($clr_blue, .85);
        @media screen and(max-width: 990px) {
            display: none;
        }
        &__header {
            border-bottom: 1px solid transparentize($clr_blue, .85);
            margin-bottom: 20px;
            min-height: 190px;
            .avatar {
                background: transparentize($clr_blue, .9);
                padding: 14px;
                width: 128px;
                height: 128px;
                border-radius: 128px;
                margin: auto;
                box-sizing: border-box;
                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    border-radius: 100px;
                }
            }
            h5 {
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                margin: 0;
                padding: 20px 0;
            }
        }
        .nav-list {
            list-style: none;
            padding: 0 20px 0 0;
            &__link {
                display: flex;
                align-items: center;
                height: 44px;
                border-radius: 5px;
                text-decoration: none;
                transition: $transition;
                width: 100%;
                padding: 0;
                svg {
                    width: 24px;
                    height: 24px;
                    margin: 0 15px;
                    fill: #6C769D;
                }
                p {
                    margin: 0;
                    font-weight: 500;
                    font-size: 16px;
                    color: #0A1A5C;
                }
                &:hover {
                    background: rgba(10, 26, 92, 0.06);
                }
                &.active {
                    background: rgba(10, 26, 92, 0.12);
                }
            }
        }
    }
    .prof-main {
        padding: 0 0 30px 30px;
        width: 100%;
        //overflow: hidden;
    }
    .prof-content {
        &__header {
            padding: 10px 0;
            width: 100%;
            .btn {
                padding: .5rem 1.75rem;
                font-weight: 500;
                white-space: nowrap;
            }
            p {
                font-weight: 500;
                margin: 0;
                font-size: 18px;
                span {
                    font-size: 21px;
                }
            }
            &.flex {
                width: 100%;
            }
            .btn-burger {
                display: flex;
                align-items: center;
                justify-content: center;
                border-width: 2px;
                padding: 4px 1rem;
                &:hover {
                    .burger {
                        background: #fff;
                    }
                }
            }
            .burger {
                transition: $transition;
                background: $clr_primary;
                width: 15px;
                height: 2px;
                border-radius: 3px;
                margin-right: 10px;
                display: flex;
                flex-direction: column;
                position: relative;
                &:before {
                    content: "";
                    background: inherit;
                    width: 15px;
                    height: 2px;
                    border-radius: 3px;
                    position: absolute;
                    top: -5px;
                    left: 0;
                }
                &:after {
                    content: "";
                    background: inherit;
                    width: 15px;
                    height: 2px;
                    border-radius: 3px;
                    position: absolute;
                    bottom: -5px;
                    left: 0;
                }
            }
        }
        &__body {
            padding-bottom: 20px;
            .fields-wrap {
                padding: 20px 0 10px;
                h5 {
                    font-size: 20px;
                    line-height: 25px;
                    font-weight: 500;
                    color: rgba(10, 26, 92, 0.5);
                    margin-bottom: 30px;
                }
                .form-group {
                    padding-left: 30px;
                    margin: 0;
                    .form-group {
                        padding-left: 0;
                        margin: 0;
                        width: auto;
                    }
                    .form-check {
                        padding: 3.5px 1rem 3.5px 0;
                        label {
                            width: auto;
                        }
                    }
                }
                .form-field {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 10px;
                    &.without-label {
                        padding-left: 150px;
                        .mb-2 {
                            flex-wrap: nowrap;
                        }
                    }
                    label {
                        margin: 0;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 130%;
                        color: #000000;
                        width: 140px;
                        min-width: 140px;
                        margin-right: 10px;
                        &.title {
                            min-height: 35.75px;
                            display: flex;
                            align-items: center;
                        }
                        &.required {
                            &:after {
                                content: "*";
                                color: #f84147;
                                margin: 3px;
                                line-height: 0;
                                font-size: 20px;
                            }
                        }
                    }
                    p {
                        height: 35.75px;
                        margin: 0;
                        display: flex;
                        align-items: center;
                    }
                    .field-s4,
                    .css-2b097c-container {
                        width: 240px;
                        margin-right: 10px;
                        .css-g1d714-ValueContainer {
                            position: relative;
                        }
                        .css-yk16xz-control,
                        .css-1pahdxg-control {
                            padding-left: 15px;
                        }
                        .css-g1d714-ValueContainer {
                            padding-left: 0;
                        }
                        .css-b8ldur-Input {
                            input {
                                margin-bottom: 0;
                            }
                        }
                    }
                    input {
                        margin-right: 10px;
                        min-width: 300px;
                        // transform: translateX(-12px);
                        color: #000000;
                        text-transform: capitalize;
                        &[type="radio"],
                        &[type="checkbox"] {
                            min-width: auto;
                        }
                        &.m {
                            min-width: 230px;
                        }
                        &.field-city {
                            min-width: 130px;
                        }
                        &.field-kv {
                            min-width: 60px;
                            width: 60px;
                        }
                        &[type="password"],
                        &[type="email"] {
                            text-transform: none;
                        }
                    }
                    .input-field {
                        display: flex;
                        .checkbox-wrap {
                            padding-top: 5px;
                            &__item {
                                position: relative;
                            }
                        }
                    }
                    .btn {
                        padding: 0;
                        opacity: .4;
                        // line-height: 1;
                        // transition: $transition;
                        // transform: translateX(-10px);
                        // border-radius: 20px;
                        // align-items: center;
                        // justify-content: center;
                        // display: flex;
                        // transform: translate(-5px, 3px);
                        height: 20px;
                        width: 20px;
                        line-height: 20px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        transform: translate(-5px, 8px);
                        margin: 0 4px;
                        padding: 0;
                        display: inline-block;
                        line-height: 0;
                        background: none;
                        border: none;
                        fill: $clr_blueLighten;
                        &.btn-danger {
                            fill: #dc3545;
                            transform: translate(-5px, 8px) rotate(45deg);
                        }
                        &:hover {
                            opacity: .9;
                        }
                    }
                    // .btn {
                    //     padding: 0;
                    //     opacity: .4;
                    //     height: 20px;
                    //     width: 20px;
                    //     line-height: 20px;
                    //     align-items: center;
                    //     justify-content: center;
                    //     display: flex;
                    //     transform: translate(-5px, 8px);
                    //     padding: 0;
                    //     display: inline-block;
                    //     line-height: 0;
                    //     background: none;
                    //     border: none;
                    //     fill: $clr_blueLighten;
                    //     fill: #dc3545;
                    //     transform: translate(-5px, 8px) rotate(45deg);
                    //     &:hover {
                    //         opacity: .9;
                    //     }
                    // }
                }
            }
            .catalog-list__item {
                width: 25%;
            }
            .orders {
                padding-top: 10px;
            }
            .order {
                border: 1px solid transparentize($clr_blue, .85);
                box-sizing: border-box;
                border-radius: 5px;
                overflow: hidden;
                margin: 10px 0;
                &.open {
                    border: 1px solid transparentize($clr_blue, .8);
                    .order-header {
                        background: transparentize($clr_blue, .9);
                        .order-btn {
                            i {
                                transform: rotate(180deg)
                            }
                        }
                        &__main {
                            opacity: 0;
                            visibility: hidden;
                        }
                        &__print {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
                &-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    min-width: 200px;
                    font-size: 500px;
                    font-size: 17px;
                    font-weight: 500;
                    box-shadow: none;
                    i {
                        font-size: 12px;
                        margin: 2px 5px 0 0;
                        transition: transform $transition;
                    }
                    &:hover {
                        color: $clr_orange;
                    }
                }
                &-header {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    border: none;
                    background: none;
                    &__wrap {
                        box-sizing: border-box;
                        width: 100%;
                        position: relative;
                        height: 53px;
                        transition: $transition;
                        &.open {
                            border-color: transparentize($clr_blue, .9);
                            background: transparentize($clr_blue, .9);
                            .order-btn {
                                i {
                                    transform: rotate(180deg)
                                }
                            }
                            .order-header__main {
                                opacity: 0;
                                visibility: hidden;
                            }
                            .order-header__print {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                    &__main {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        overflow: hidden;
                        transition: $transition;
                    }
                    &__info {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        white-space: nowrap;
                    }
                    &__items {
                        display: flex;
                        align-items: center;
                        flex-grow: 1;
                        overflow: hidden;
                        max-width: 260px;
                        position: relative;
                        @media screen and (max-width : 1349px) {
                            max-width: 70px;
                        }
                        .item {
                            margin-right: 15px;
                            img {
                                height: 34px;
                                max-width: 34px;
                                object-fit: contain;
                            }
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: block;
                            width: 25px;
                            height: 41px;
                            background: linear-gradient(to right, rgba(255, 255, 255, 0.0) 0, #fff 55%);
                        }
                    }
                    &__total {
                        display: flex;
                        align-items: center;
                        flex-grow: 1;
                        justify-content: flex-end;
                        p {
                            margin: 0;
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 130%;
                            text-align: right;
                            color: rgba(0, 0, 0, 0.6);
                        }
                        b {
                            font-size: 18px;
                            margin: 0 8px;
                        }
                    }
                    &__date {
                        font-size: 15px;
                        line-height: 18px;
                        display: flex;
                        align-items: center;
                        text-align: center;
                        color: rgba(0, 0, 0, 0.5);
                        margin: 0 10px;
                    }
                    &__status {
                        padding-left: 10px;
                        .status {
                            background: $clr_blue;
                            height: 41px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 15px;
                            color: $clr_white;
                            font-weight: 500;
                            min-width: 170px;
                            width: 170px;
                            padding: 20px;
                            border-radius: 25px 0 0 25px;
                            opacity: .8;
                            &.new {
                                background: $clr_blue;
                            }
                            &.processed {
                                background: $clr_blueLighten;
                            }
                            &.taken {
                                background: $clr_orange;
                            }
                            &.complete {
                                background: $clr_green;
                            }
                            &.fail {
                                background: $clr_red;
                            }
                        }
                    }
                    &__print {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                        svg {
                            width: 21px;
                            height: 21px;
                            margin-bottom: 2px;
                        }
                    }
                }
                &-body {
                    &__wrap {
                        display: flex;
                        padding: 0;
                    }
                }
                &-main {
                    min-width: 350px;
                    border-right: 1px solid transparentize($clr_blue, .9);
                    &__status {
                        height: 120px;
                        padding: 20px;
                        box-sizing: border-box;
                        p {
                            margin-bottom: 5px;
                            font-weight: 500;
                            color: $clr_black !important;
                        }
                        h3 {
                            font-size: 24px;
                            line-height: 30px;
                            font-weight: 500;
                        }
                        .status {
                            &.new {
                                color: $clr_blue;
                            }
                            &.processed {
                                color: $clr_blueLighten;
                            }
                            &.taken {
                                color: $clr_orange;
                            }
                            &.complete {
                                color: $clr_green;
                            }
                            &.fail {
                                color: $clr_red;
                            }
                        }
                    }
                }
                &-info {
                    border-top: 1px solid transparentize($clr_blue, .9);
                    transform: translateY(1px);
                    .card {
                        border: none;
                        border-bottom: 1px solid transparentize($clr_blue, .9);
                        border-radius: 0;
                        &-header {
                            background: none;
                            padding: 0;
                            border: none;
                            .order-header__wrap {
                                height: auto;
                            }
                            .order-btn {
                                width: 100%;
                                display: flex;
                                padding: 10px 20px;
                                border-radius: 0;
                                &:hover {
                                    color: $clr_blueLighten;
                                    background: transparentize($clr_blue, .95);
                                }
                            }
                            span {
                                color: $clr_blueLighten;
                                font-size: 15px;
                                line-height: 18px;
                                width: 100%;
                                text-align: left;
                            }
                            i {
                                margin: 0;
                            }
                        }
                    }
                    .info-flex {
                        padding: 20px;
                        .line {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 13px;
                            margin-bottom: 10px;
                            b {
                                font-weight: 500;
                            }
                            p {
                                text-align: right;
                                margin: 0;
                            }
                        }
                    }
                    .history-list {
                        text-decoration: none;
                        padding: 0;
                        list-style: none;
                        font-size: 14px;
                        line-height: 17px;
                        ul {
                            list-style: none;
                            padding: 0 0 0 15px;
                            border-left: 1px solid rgba(10, 26, 92, 0.5);
                            margin: 3px 0;
                            li {
                                display: flex;
                                align-items: center;
                                height: 22px;
                                .time {
                                    color: rgba(0, 0, 0, 0.5);
                                    font-weight: 600;
                                    margin-right: 15px;
                                }
                                .status {
                                    margin-bottom: 2px;
                                    font-weight: 500;
                                    &.new {
                                        color: $clr_blue;
                                    }
                                    &.processed {
                                        color: $clr_blueLighten;
                                    }
                                    &.taken {
                                        color: $clr_orange;
                                    }
                                    &.complete {
                                        color: $clr_green;
                                    }
                                    &.fail {
                                        color: $clr_red;
                                    }
                                }
                            }
                        }
                    }
                }
                &-products {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    .cart-items {
                        &__wrap {
                            padding: 0;
                            .cart-item {
                                padding: 20px;
                                .products {
                                    &-info {
                                        &__bottom {
                                            align-items: flex-end;
                                        }
                                    }
                                    &-qty {
                                        width: 100px;
                                    }
                                }
                            }
                        }
                    }
                    &__wrap {
                        flex-grow: 1;
                    }
                    &__footer {
                        border-top: 1px solid transparentize($clr_blue, .9);
                        box-sizing: border-box;
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-end;
                        height: 40px;
                        padding-bottom: 7px;
                        padding-right: 10px;
                        p {
                            margin: 0 15px 0 0;
                        }
                        span {
                            font-size: 24px;
                            line-height: 120%;
                            font-weight: 500;
                        }
                    }
                }
            }
            .wishlist {
                .rename {
                    z-index: 2;
                    display: none;
                    &.active {
                        display: block;
                    }
                    .form-group {
                        background: #f5f6f9;
                        display: flex;
                        align-items: center;
                        position: absolute;
                        left: 8px;
                        top: 8px;
                        right: 8px;
                        .input-field {
                            width: 100%;
                            max-width: inherit;
                        }
                        input {
                            width: 100%;
                            max-width: inherit;
                        }
                        .btn {
                            &-primary {
                                margin: 0 10px;
                                min-width: 40px;
                            }
                        }
                    }
                }
                &__item {
                    background: #FFFFFF;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 4px;
                    overflow: hidden;
                    margin: 30px 0;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &-header {
                    background: rgba(10, 26, 92, 0.04);
                    &__top {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 8px 20px;
                        border-bottom: 1px solid transparentize($clr_blue, .9);
                        position: relative;
                        .title {
                            font-size: 16px;
                            font-weight: 500;
                        }
                        .btn-toolbar {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            flex-grow: 1;
                            padding-left: 10px;
                            .btn {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                box-shadow: none;
                                span {
                                    margin-left: 10px;
                                }
                                svg {
                                    transition: $transition;
                                    opacity: .5;
                                }
                                &:hover {
                                    span {
                                        text-decoration: underline;
                                    }
                                    svg {
                                        opacity: 1;
                                    }
                                }
                                &-copy {
                                    margin-left: auto;
                                    background: #F26522;
                                    opacity: .7;
                                    color: $clr_white;
                                    font-size: 13px;
                                    font-weight: 500;
                                    svg {
                                        margin-left: 10px;
                                    }
                                    &:hover {
                                        span {
                                            text-decoration: none;
                                        }
                                    }
                                }
                                &.check {
                                    svg {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                    &__bottom {
                        padding: 10px 20px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .left-side {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            p {
                                margin: 10px 15px 10px 0;
                                span {
                                    font-size: 21px;
                                    font-weight: 500;
                                    color: #2B2B2B;
                                    margin-left: 5px;
                                }
                            }
                            .btn {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 40px;
                                padding: .375rem 1.75rem;
                                svg {
                                    fill: #fff;
                                    margin-right: 10px;
                                }
                            }
                        }
                        .right-side {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            span {
                                margin-right: 10px;
                            }
                        }
                    }
                }
                &-body {
                    padding: 20px;
                    .products-dropdown {
                        &__wrap {
                            display: grid;
                            grid-gap: 0;
                            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
                        }
                        &__item {
                            width: 100%;
                        }
                    }
                }
                .products-dropdown__item {
                    .products-labels {
                        top: 40px;
                    }
                }
            }
        }
        &__footer {
            .btn {
                padding: .5rem 1.75rem;
                font-weight: 500;
            }
        }
    }
    .pp-waitinglist {
        .catalog-list {
            &__wrap {
                display: grid;
                grid-gap: 0;
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            }
            &__item {
                width: 100%;
            }
        }
    }
    .pp-orders {
        width: 100%;
        overflow: hidden;
    }
}
svg {
    &.w16 {
        width: 16px;
        height: 16px;
    }
    &.w20 {
        width: 20px;
        height: 20px;
    }
    &.w22 {
        width: 22px;
        height: 22px;
    }
}
@media screen and (max-width : 1350px) {
    .page-profile {
        .prof {
            &-content__body {
                .fields-wrap .form-field {
                    margin: 0;
                    .input-field {
                        flex-wrap: wrap;
                        &.mt-2 {
                            flex-wrap: nowrap;
                        }
                    }
                    input, select {
                        max-width: 300px;
                        //min-width: auto;
                        margin: 0 10px 10px 0;
                    }
                    .field-s4,
                    .css-2b097c-container {
                        height: 36px;
                        margin-bottom: 10px;
                        .css-b8ldur-Input {
                            input {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width : 1200px) {
    .page-profile {
        .container {
            padding: 0;
        }
        .prof {
            &-sidebar {
                width: 240px;
                min-width: 240px;
                .nav-list {
                    &__link {
                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
            &-main {
                padding-left: 15px;
            }
            &-content__body {
                .order {
                    &-header {
                        &__main {
                            justify-content: flex-end;
                        }
                        &__items {
                            display: none;
                        }
                        &__total {
                            p {
                                display: none;
                            }
                        }
                    }
                    &-main {
                        min-width: 290px;
                    }
                    &-info {
                        .info-flex {
                            padding: 20px 10px;
                        }
                    }
                    &-products {
                        &__wrap {
                            .cart-items__wrap .cart-item .products-image {
                                width: 40px;
                                height: 40px;
                                img {
                                    width: 40px;
                                    height: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width : 990px) {
    .page-profile {
        .prof {
            &-main {
                padding-left: 0;
            }
            &-content {
                &__body .order-products .cart-items__wrap .cart-item .products-qty {
                    width: 60px;
                }
            }
        }
    }
}
@media screen and (max-width : 767px) {
    .p-3 {
        position: absolute !important;
        top: 90px !important;
        right: 10px !important;
        left: 10px !important;
        font-size: 14px !important;
    }
    .page-profile {
        .breadcrumbs {
            padding: 0 0 20px;
            display: inline-block;
            &>div {
                display: inline;
            }
            h1 {
                display: inherit;
                font-size: 18px;
                line-height: 1;
            }
            h2 {
                display: inherit;
                font-size: 14px;
                line-height: 20px;
                &:before {
                    transform: rotate(-90deg) translateX(2px);
                }
            }
        }
        .prof {
            &-main {
                padding-left: 0;
            }
            &-content {
                &__header {
                    display: flex;
                    justify-content: space-between;
                    .btn {
                        padding-left: 5px;
                        padding-right: 5px;
                        min-width: 47%;
                        font-size: 14px;
                    }
                }
                &__body {
                    .fields-wrap {
                        padding: 10px 0;
                        h5 {
                            margin-bottom: 10px;
                            font-size: 18px;
                        }
                        .form-group {
                            padding-left: 5px;
                            position: relative;
                            .address-fields {
                                .btn-simple.rounded-circle {
                                    position: absolute;
                                    bottom: 0;
                                }
                            }
                        }
                        .form-field {
                            flex-direction: column;
                            min-height: inherit !important;
                            width: 100%;
                            label {
                                font-size: 14px;
                            }
                            label.title {
                                width: 100%;
                                font-size: 13px;
                                min-height: 22px;
                            }
                            p {
                                height: auto;
                                margin-bottom: 10px;
                                font-size: 14px;
                            }
                            .input-field {
                                width: 100%;
                                input {
                                    font-size: 13px;
                                    width: auto;
                                    max-width: inherit;
                                    min-width: 80%;
                                    flex-grow: 1;
                                    &.field-kv {
                                        flex-grow: unset;
                                        width: 60px;
                                        min-width: 60px;
                                    }
                                }
                            }
                            .field-s4,
                            .css-2b097c-container {
                                font-size: 13px;
                                width: 100%;
                                padding-right: 30px;
                            }
                            &.without-label {
                                padding: 0;
                                // margin-top: -1rem;
                            }
                        }
                        .address-fields {
                            margin-top: 1rem;
                            .input-field {
                                padding-bottom: 10px;
                            }
                            .btn.btn-danger {
                                position: absolute;
                                top: 22px;
                                right: 0;
                                margin: 0;
                            }
                        }
                    }
                    .order {
                        &-header {
                            &__wrap {
                                height: 50px;
                                &.open {
                                    .order-header {
                                        &__main {
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                        &__date {
                                            display: block;
                                        }
                                    }
                                }
                            }
                            &__total {
                                b {
                                    display: none;
                                }
                            }
                            &__date {
                                display: none;
                                position: absolute;
                                top: 60px;
                                right: 0px;
                                display: block;
                            }
                            &__status {
                                .status {
                                    min-width: 130px;
                                    width: auto;
                                    font-size: 13px;
                                }
                            }
                        }
                        &-btn {
                            min-width: auto;
                            p {
                                font-size: 0;
                                span {
                                    font-size: 13px;
                                }
                            }
                        }
                        &-main {
                            min-width: 100%;
                            &__status {
                                padding: 10px;
                                height: auto;
                            }
                        }
                        &-body {
                            &__wrap {
                                flex-direction: column;
                            }
                        }
                        &-products {
                            .cart-items {
                                &__wrap {
                                    .cart-item {
                                        padding: 10px;
                                        .products-title {
                                            font-size: 13px;
                                        }
                                        .products-sum {
                                            position: unset;
                                            width: 100%;
                                        }
                                        .products-info__bottom {
                                            flex-direction: column;
                                            align-items: flex-start;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .wishlist {
                        &-header {
                            &__top {
                                // flex-direction: column;
                                // align-items: flex-start;
                                width: 100%;
                                justify-content: space-between;
                                height: 54px;
                                .title {
                                    //padding: 8px 0 2px;
                                    font-size: 1rem;
                                }
                                .btn-toolbar {
                                    // padding: 10px 0 0;
                                    // margin-left: -10px;
                                    flex-grow: inherit;
                                    padding: 0;
                                    margin: 0 -10px 0 0;
                                    .btn {
                                        padding: 3px 5px;
                                        span {
                                            font-size: 12px;
                                            display: none;
                                        }
                                    }
                                }
                            }
                            &__bottom {
                                .left-side {
                                    p {
                                        margin-top: 0;
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .pp-waitinglist .catalog-list__wrap {
            grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        }
    }
}
button.waiting:disabled {
    opacity: .2 !important;
}
