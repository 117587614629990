.with-top-banner .center-select__modalSmall {
    // top: 40px;
}


.center-select {
    display: flex;
    gap: 20px;
    align-items: center;

    position: relative;

    &__title {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #C6CDE7;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: 0.2s ease all;

        &:hover {
            color: #fff;
        }
    }

    &__modalSmall {
        display: none;
        position: fixed;
        z-index: 100000000;
        // left: 10px;
        top: 5px;

        align-items: center;
        padding: 18px 26px;
        border-radius: 5px;
        background: #c6cde7d7;
        backdrop-filter: blur(18px);

        &.open {
            display: flex;
        }

        &-text {
            margin-right: 30px;
            color: #0A1A5C;

            h5 {
                white-space: nowrap;
                font-size: 18px;
                font-weight: 500;
            }

            p {
                margin-bottom: 0;
            }
        }

        &-buttons {
            display: flex;
            gap: 12px;



            .confirm-btn {
                min-width: 100px;

                border: none;
                background: #fff;
                color: #0A1A5C;
                font-weight: 500;

                &:hover,
                &:focus,
                &:active {
                    // text-decoration: none;
                    box-shadow: none !important;
                    border: none;
                    outline: none;
                    color: #fff;
                    background: #33489d !important;
                }

                transition: 0.2s ease all;
            }

            .change-btn {
                min-width: 100px;
                border-color: #33489d !important;
                border-width: 2px;
                color: #0A1A5C;
                font-weight: 500;

                transition: 0.2s ease all;


                &:hover,
                &:focus,
                &:active {

                    box-shadow: none !important;
                    background-color: #33489d !important;
                }
            }

        }
    }

    &__modalMain {
        &-header {
            justify-content: flex-start;

            p {
                margin-bottom: 0;
                color: #999;
            }
        }

        &--select {
            h6 {
                font-size: 18px;
                font-weight: 500;
                color: #7D7D7D;
                margin-bottom: 16px;
                padding: 0 30px;
            }

            &-city {
                border-right: 2px solid #D8E1FE;
            }

            small {
                padding: 6px 30px;
            }
            .item-city,
            .item-address {
                display: flex;
                align-items: center;

                padding: 6px 30px;
                font-size: 14px;
                font-weight: 500;
                color: #3a3939;

                transition: 200ms ease background;
                cursor: pointer;

                i {
                    margin-right: 10px;
                }

                &:hover {
                    background: #D8E1FE;
                    color: #0A1A5C;
                }

                &.active {
                    border-Left: 3px solid #0A1A5C;

                    background: #D8E1FE;
                    color: #0A1A5C;
                    cursor: default;
                }
            }

            .item-city.active {
                padding-left: 27px;
            }

            .item-address.active {
                padding-left: 10px;
            }

        }
    }
}

@media screen and (max-width: 992px) {
    .center-select {
        justify-content: center;

        &__title {
            margin-top: 20px;
            justify-content: center;
            text-align: center;

            i {
                display: none;
            }
        }

        &__modalSmall {
            top: auto;
            left: 0px;
            right: 0px;
            bottom: 0px;

            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            padding-top: 30px;


            &-text {
                text-align: center;
                margin-right: 0;
                margin-bottom: 30px;

                h5 {
                    white-space: normal;
                }
            }

            &-buttons {
                flex-direction: column;
                max-width: 400px;
                width: 100%;

                button {
                    width: 100%;
                    padding-top: 16px;
                    padding-bottom: 16px;
                }
            }
        }

        &__modalMain {
            // margin-left: 0;
            // margin-right: 0;
            // margin-bottom: 0;

            &-header {
                display: none;
            }
            .modal-header {
                border: none;
                padding-bottom: 30px;
            }
            .modal-content {
                // margin-top: auto;
                margin-bottom: 0px;
            }

            &--select {

                h6 {
                    padding-left: 16px;
                    padding-right: 12px;
                }
                small,
                .item-address,
                .item-city {
                    padding-top: 12px;
                    padding-bottom: 12px;
                    padding-left: 16px;
                    padding-right: 12px;
                }

                .item-city {
                    font-size: 16px;
                    &.active {
                        padding-left: 13px;
                    }
                }

                .item-address {
                    font-size: 14px;
                }
            }
        }

    }

}