@import "../../default.scss";

.subscribe {
    line-height: 130%;
    // color: $clr_blue;
    // color: $clr_white;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    width: 100%;

    @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
    }

    b {
        font-size: 24px;
        width: 240px;
        font-weight: 500;
        line-height: 28px;

        @media (max-width: 991px) {
            width: 100%;
            font-size: 18px;
            padding-bottom: 5px;
        }
    }

    p {
        opacity: .5;
        font-weight: 500;
        font-size: 13px;
    }

    &__form {
        background: $clr_white;
        border: 1px solid #ced4da;
        border-radius: 4px;
        padding: 0 2px;
        display: flex;
        align-items: center;
        width: 100%;

        @media (max-width: 991px) {
            width: 100%;
            margin-top: 0;
        }

        .form-control {
            border-color: transparent;
            padding: 21px 15px;
            font-size: 13px;
        }

        .btn-primary {
            font-weight: 500;
            padding: 8px 12px;
            min-height: 40px;
            min-width: 120px;
            text-align: center;
        }
    }
}

.social {

    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 30px;


    p {
        font-weight: 500;
        font-size: 13px;
        margin-top: 20px;
        color: #000;
        opacity: 1;
        margin: 10px 0 10px;

        @media (max-width: 991px) {
            margin-top: 0px;
            width: 100%;
        }
    }

    &-list {
        display: flex;
        align-items: flex-start;


        &__item {
            margin-right: 25px;

            @media (max-width: 991px) {
                margin: 0 15px 0 0;
            }

            img {
                width: 40px;
                height: 40px;
            }

            .nav-link {
                padding: 0;
                transition: 250ms ease;

                &:hover {
                    opacity: .8;
                }
            }
        }
    }
}

.footer {
    position: relative;
    z-index: 1;
    background: $clr_blue;
    color: #fff;
    padding: 2.5rem 0;
    margin-top: 50px;


    .social {

        padding: 0;

        @media (max-width: 991px) {
            width: 100%;
            text-align: center;
            margin-top: 20px;
        }

        p {
            color: #fff
        }

        &-list {

            @media (max-width: 991px) {
                flex-grow: 1;
                justify-content: center;
            }

            &__item {

                .nav-link {
                    opacity: .4;

                    &:hover {
                        opacity: .6;
                    }

                    img {
                        filter: brightness(30);
                    }
                }
            }
        }
    }



    .menu-item {
        margin-bottom: 20px;

        &__btn {
            display: flex;
            align-items: center;

            margin-bottom: 8px;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 500;
            color: #cad0e9;

            a {
                padding: 0;
                color: #cad0e9;
                text-decoration: none;
                transition: 250ms ease;

                font {
                    transition: 250ms ease;
                }

                &:hover {
                    color: $clr_orange;
                    ;

                    font {
                        color: $clr_orange;
                    }
                }
            }

            svg {
                width: 10px;
                height: 8px;
                margin-left: 5px;
                fill: #8892bb;
                transition: .2s;
            }

        }

        &__images {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            padding-top: 6px;

            &.big-images {
                padding: 12px 0;

                .img-item {
                    margin-right: 16px;
                    margin-bottom: 8px;
                    margin-top: 8px;

                }

                img {
                    height: 80px;
                    margin: 0;
                }
            }

            a {
                text-decoration: none;
            }

            img {
                height: 60px;
                margin-right: 12px;
                margin-bottom: 6px;
                margin-top: 6px;
            }
        }

        &__inner {
            a {
                padding: 5px 0;
                color: rgb(242, 242, 242);
                display: flex;
                align-items: center;
                text-decoration: none;
                transition: 250ms ease;
                font-size: 14px;

                font {
                    transition: 250ms ease;
                }

                &:hover {
                    color: $clr_orange;

                    font {
                        color: $clr_orange;
                    }
                }

                img.header-top-menu__icon {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }
        }
    }

    .menu-contact-item {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 1.1em;
    }

    &__bottom {
        padding-top: 20px;
        margin-top: 20px;

        display: flex;
        align-items: center;
        border-top: 2px solid #2f386f;

        &-main {
            display: flex;
            align-items: flex-end;
            flex-grow: 1;

            .copyright {
                margin: 0;
                flex-grow: 1;
                color: #fff;
                font-size: 13px;
                line-height: 15px;
                padding: 10px 0 10px 30px;

                @media (max-width: 1100px) {
                    padding: 20px 0 0;
                    width: auto;
                    text-align: center;
                    margin: 0;
                    font-size: 12px;
                }
            }

        }

        &-blocks {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .menu-item {
                margin-bottom: 0;

                &.big-images .img-item {
                    margin-right: 16px;
                    margin-bottom: 8px;
                    margin-top: 8px;
                }

            }
        }
    }

}

@media screen and(max-width: 992px) {
    .subscribe {
        margin-top: 30px;
    }

    .footer__bottom {
        flex-direction: column-reverse;
        align-items: center;

        &-main {
            flex-direction: column;
            align-items: center;
        }
    }

}

@media screen and(max-width: 768px) {

    .subscribe {
        margin-top: 0;
        padding-bottom: 30px;
        border-bottom: 1px solid #27356cd1;

        label {
            font-size: 12px;
            font-weight: 500;
            display: block;
            // text-align: center;
        }
    }

    .footer-mobile {
        user-select: none;

        .pt-items {
            .menu-item {
                margin-top: 20px;
            }
        }

        .menu-item {
            // padding: 10px 0;
            margin-bottom: 0;
            border-bottom: 1px solid #27356cd1;

            .pt {
                padding: 20px 0;
            }

            &__btn {
                justify-content: space-between;

                margin-bottom: 0;
                width: 100%;


                a {
                    flex-grow: 1;
                    padding: 20px 0;
                    color: #f2f5ff;

                    &:active {
                        color: $clr_orange;

                        font {
                            color: $clr_orange;
                        }
                    }
                }

            }

            &__images {
                padding-bottom: 15px;

                &.big-images {
                    padding: 12px 0;

                    img {
                        height: 60px;
                    }
                }

                img {
                    height: 40px;
                }
            }

            &__inner {
                padding-bottom: 8px;

                a {
                    padding: 12px 0;
                }
            }
        }
    }

    .footer {
        padding-top: 30px;

        &__bottom {
            flex-direction: column-reverse;
            border-top: 0;

            &-main {
                flex-direction: column;
                align-items: center;
                // flex-grow: 1;



            }

            .menu-item__images.big-images .img-item {
                padding: 12px;
                margin: 0;
            }

            &-blocks {
                justify-content: center;

                .big-images .img-item {
                    max-width: 50% !important;
                }
            }
        }
    }

}

@media screen and(max-width: 576px) {
    .footer__bottom-blocks .big-images {
        >* {
            // width: 50%;
        }

        .img-item {
            width: 50%;

            img {
                display: block;
                margin: 0 auto;
            }

            // width: 100%;
            // height: auto !important;
            // margin: 0;
        }
    }
}