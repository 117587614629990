.modal {
    &-open .modal {

        @media screen and (max-width : 768px) {
            padding: 0 !important;
        }
    }

    &-dialog {
        max-width: 550px;
    }

    &-content {
        box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.5), 0px 3px 7px rgba(0, 0, 0, 0.1);
        border-color: transparent;
        border-radius: 8px;
        border: none;
    }

    &-header {
        justify-content: center;
        padding: 1rem 2rem;

        .title {
            flex-grow: 1;
            text-align: center;
        }

        .close {
            /*position: absolute;
            top: 15px;
            background: #bd2361;
            opacity: 1;
            border-radius: 0 4px 0 8px;
            right: 15px;
            color: #fff;
            padding: .5rem .8rem .8rem; */
            position: absolute;
            right: 15px;

            span {
                text-shadow: none;
                font-size: 35px;
                line-height: 33px;
                font-weight: 100;
                padding-right: .5rem;

            }
        }
    }

    &-body {
        padding: 1rem 2rem 2rem;

        form {
            .btn {
                width: 100%;
                padding: .7rem;
                margin-top: 1rem;
                font-size: 16px;
            }

            .form-control:focus {
                box-shadow: none;
                border-color: #2f4ed5;
            }
        }

        .text-center {
            padding: .5rem 0 1rem;
            width: 100%;

            p {
                margin-bottom: .5rem;
            }
        }

        &__simple {
            text-align: right;
        }

        .content-text {
            padding: 1.5rem;
            max-height: 65vh;
            overflow: auto;
            font-size: 14px;

            ol,
            ul {
                counter-reset: item;
                padding-left: 10px;
            }

            li {
                display: block;

                &:before {
                    font-weight: 600;
                }
            }

            ol li:before {
                content: counters(item, ".") ". ";
                counter-increment: item
            }
        }
    }

    &-sm{
        max-width: 470px;
        text-align: center;
        .modal-body{
            padding-bottom: 10px;
        }
        .modal-footer{
            justify-content: center;
        }
        .center-block{
            padding: 0 30px;
            b{
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 5px;
            }
        }
        .btn{
            width: calc(50% - .5rem);
            font-size: 17px;
            line-height: 22px;
            padding: 12px;
            &-link{
                font-weight: 500;
                color: $clr_blue;
            }
        }
    }

    &-login {
        max-width: 470px;

        .modal-header {
            border-bottom: 0;
            padding: 0;

            .tabs {
                display: flex;
                align-items: center;
                width: 100%;

                .tab {
                    width: 50%;
                    font-weight: 500;
                    font-size: 21px;
                    text-align: center;
                    height: 53px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:last-child {
                        border-radius: 0px 8px 0px 0px;
                        background: #BF2362;
                        color: #fff;
                        box-shadow: inset 3px 0px 4px rgba(0, 0, 0, 0.25);

                        &.active {}
                    }

                    &:first-child {
                        border-radius: 8px 0px 0px 0px;
                        background: #BF2362;
                        color: #fff;
                        box-shadow: inset -3px 0px 4px rgba(0, 0, 0, 0.25);
                    }

                    &.active {
                        background: #fff;
                        color: $clr_black;
                        box-shadow: none;
                    }
                }
            }

            .close {
                position: absolute;
                right: -35px;
                top: -18px;
                opacity: 1;

                span {
                    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                    font-size: 40px;
                    font-weight: 300;
                    color: white;
                }

            }
        }

        h5 {
            text-align: center;
            font-size: 15px;
            line-height: 130%;
            margin: 0 0 20px;
        }

        .ordel {
            width: calc(100% + 100px);
            margin: 0 -50px;
            font-size: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            color: transparentize($clr_blue, .6);
            padding: 10px 0 20px;

            span {
                display: block;
                padding: 0 10px 2px;
            }

            &:before,
            &:after {
                content: "";
                display: block;
                flex-grow: 1;
                height: 1px;
                background: transparentize($clr_blue, .8);
            }
        }

        .form-group {
            position: relative;
            margin: 0 0 10px 0;

            .form-field {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .tip {
                font-size: 12px;
                line-height: 100%;
                display: block;
                padding-left: 80px;
                padding-bottom: 5px;
                color: $clr_primary;
            }

            &:first-child {}

            label {
                min-width: 80px;
                width: 80px;
                margin: 0;
                font-weight: 500;

                &:after {
                    content: "*";
                    color: #f84147;
                    margin: 3px;
                }
            }
            .inputregpass{
                padding-right: 71px;
            }
            .check-remember {
                padding-left: 81px;

                label {
                    padding: 0;
                    width: auto;

                    &:after {
                        content: ""
                    }
                }
            }

            .forgetPass {
                line-height: 1;

                button {
                    margin: 0;
                    font-size: 13px;
                    padding: 0;
                }
            }

            .btn-getpass {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 13px;
                font-weight: 500;
                width: calc(100% - 80px);
                background: #E7E8EF;
                color: $clr_blue;
                border: 1px solid rgba(10, 26, 92, 0.2);

                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .btn-toolbar{
            position: absolute;
            right: 0;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            padding-right: 7px;
            button{
                padding: 5px;
                margin: 0;
                i{
                    display: flex;
                }
                &:hover{
                    svg{
                        opacity: 1;
                        fill: $clr_blueLighten;
                    }
                }
            }
            svg{
                fill: $clr_blue;
                width: 20px;
                height: 20px;
                opacity: .4;
                transition: $transition;
            }

        }
        .btn.btn-primary {
            margin: 0 0 20px;
        }

        .login-soc {
            margin: -20px 0;

            &__wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 10px;
            }

            &__item {
                padding: 3px;

                button {
                    padding: 7px;
                    border-radius: 50px;
                    margin: 0;

                    &:hover {
                        background: #eee;
                    }
                }

                svg {
                    width: 50px;
                    height: 50px;
                }
            }
        }
        .privacy{
            margin-bottom: -10px;
            p{
                font-size: 12px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                line-height: 16px;
                width: 100%;
                color: transparentize($color: #000000, $amount: 0.4);
            }
            button{
                display: inline;
                padding: 0;
                margin: 2px;
                width: auto;
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    &-footer{
        
    }.btn-secondary{
        background: transparent;
        border: none;
        &:hover{
            background: transparentize($clr_blue, .9);
            text-decoration: none;
        }
    }
}

.loginForm {
    padding: 20px;
    box-sizing: border-box;
}

.dn {
    display: none;
}