@use 'sass:math';
$border: 1px solid #e9e9e9;
.r {
  @for $i from 2 through 72 {
    $h: math.div(1rem * $i, 10);
    &#{$i} {
      border-radius: $h;
    }
  }
}
.round {
  border-radius: 100rem;
}
.b {
  &order {
    border: $border;
  }
  &t {
    border-top: $border;
  }
  &l {
    border-left: $border;
  }
  &b {
    border-bottom: $border;
  }
  &r {
    border-right: $border;
  }
}
