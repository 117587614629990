@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?wr93fi');
  src:  url('fonts/icomoon.eot?wr93fi#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?wr93fi') format('truetype'),
    url('fonts/icomoon.woff?wr93fi') format('woff'),
    url('fonts/icomoon.svg?wr93fi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-book:before {
  content: "\e91f";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-enlarge:before {
  content: "\e989";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
