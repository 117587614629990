@use 'sass:math';
.op {
  &0 {
    opacity: 0;
  }
  @for $i from 1 through 9 {
    $opacity: math.div($i, 10);
    &0#{$i} {
      opacity: $opacity;
    }
  }
}
