@use 'sass:math';
.m {
  @for $i from 0 through 80 {
    $m: math.div(1rem * $i, 10);
    &#{$i} {
      margin: $m;
    }
    &l#{$i} {
      margin-left: $m;
    }
    &r#{$i} {
      margin-right: $m;
    }
    &b#{$i} {
      margin-bottom: $m;
    }
    &t#{$i} {
      margin-top: $m;
    }
    &tb#{$i} {
      margin-top: $m;
      margin-bottom: $m;
    }
    &lr#{$i} {
      margin-left: $m;
      margin-right: $m;
    }
  }
  &0 {
    margin: 0 !important;
  }
  &b0 {
    margin-bottom: 0 !important;
  }
  &t0 {
    margin-top: 0 !important;
  }
  &l0 {
    margin-left: 0 !important;
  }
  &r0 {
    margin-right: 0 !important;
  }
  &a {
    margin: auto;
  }
  &ba {
    margin-bottom: auto;
  }
  &ta {
    margin-top: auto;
  }
  &la {
    margin-left: auto;
  }
  &ra {
    margin-right: auto;
  }
}
