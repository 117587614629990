@import '../../../default.scss';

.countdown{
    padding: 1rem 1rem 1rem 2rem;
    color: $clr_blue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    &__header{
        font-weight: 600;
        font-size: 36px;
        margin-bottom: 2rem;
        margin-top: 0;
        display: flex;
    }
    &__title{
        margin: 0 0 5px;
        font-weight: 500;
        opacity: .7;
    }
    &__timer{
        font-size: 36px;
        color: $clr_blue;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: sans-serif;
        b{
            width: 50px;
            text-align: center;
            line-height: 48px;
            display: block;
            //text-align: center;
            letter-spacing: -1px;
            //width: 58px;
            font-weight: 500;
            font-size: 48px;
            margin: 0 5px;
            &:first-child{
                margin-left: 0;
            }
        }
    }
    &__days{
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            width: 51px;
            margin: 0px 9.5px 10px;
            font-size: 12px;
            text-align: center;
            line-height: 10px;
            &:first-child{
                margin-left: 5px;
            }
        }
    }
    &__footer{
        font-weight: 500;
        opacity: .7;
        margin-top: 20px;
    }
    &__end{
        font-weight: 600;
        font-size: 17px;
    }
}